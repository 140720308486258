import React, { Component } from 'react';
import {connect} from "react-redux";
import {callingSearch, callingSearchPlaces, roamingSearch, roamingSearchPlaces} from "../../actions/roaming";
import PropTypes from "prop-types";
import Select from "../common/Select";
import InputSearchResult from "../common/InputSearchResult";
import InfoBlock from "../common/InfoBlock";


class RatesRoamingSearch extends Component {

    constructor(props) {

        super(props);

        let tariffsForSelect = [];

        if (props.withTariff && props.tariffs.length > 0) {
            tariffsForSelect = props.tariffs.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            });
        }


        this.state = {
            search: props.defaultValue || "",
            callbackFunc: this.props.setInSearch,
            opened: false,
            activeUlChild: -1,
            fields: {
                tariff: {
                    type: "tariff_id",
                    label: "Мой тариф",
                    value: "",
                    options: tariffsForSelect,
                    placeholder: "Мой тариф",
                    hasError: false
                },
            }
        };


        this.flagArrowsInput = false;



        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.closeSearchItems = this.closeSearchItems.bind(this);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    componentDidMount(){
        this.getSearchParamsRegion();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.activeTab !== this.props.activeTab){
            this.getSearchParamsRegion();
        }
        if (this.props.withTariff){
            if (this.state.fields.tariff.options.length === 0 && this.props.tariffs && this.props.tariffs.length > 0) {
                let tariffsForSelect = this.props.tariffs.map((item) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                });
                this.setState({
                    fields: {
                        tariff: {options: tariffsForSelect}
                    }
                })
            }
        }
        if (prevProps.defaultValue !== this.props.defaultValue) {
            this.setSearchVal(this.props.defaultValue)();
        }
        if((prevProps.roaming.roamingSearchList !== this.props.roaming.roamingSearchList) && this.props.roaming.roamingSearchList.length > 0 && this.props.roaming.isOk) {
            const url = new URL(window.location.href);
            let token, hash;

            if(url.hash){
                const [hashReq, tokenReq] = url.hash.split('&');
                if(hashReq) hash =hashReq;
                if(tokenReq) token =tokenReq;
            }

            const urlSearch = new URLSearchParams(window.location.search)
            const params = url.searchParams;


            if(!this.props.activeTab){
                params.set('city', this.state.search.replaceAll(" ", "_"));
            }else{
                params.set('country', this.state.search.replaceAll(" ", "_"));
            }

            if(urlSearch.get('access_token')){
                params.set('access_token', urlSearch.get('access_token'));
            }

            history.pushState({}, "", `${url.pathname}?${params.toString()}${token?`&${token}`:''}${hash?`${hash}`:''}`);
            // history.pushState({}, "", '#' + this.state.search.replaceAll(" ", "_"))
        }
    }

    getSearchParamsRegion(){
        if(window.location.search) {
            const searchUrl = new URLSearchParams(window.location.search);
            const country = searchUrl.get('country');
            const city = searchUrl.get('city');
            if(country && country.length>0){
                this.setState({search: country}, () => {
                    this.props.searchMethod(this.state.search, this.context.selectedRegion.id);
                    this.state.callbackFunc();
                })
            }else if(city && city.length>0){
                this.setState({search: city}, () => {
                    this.props.searchMethod(this.state.search, this.context.selectedRegion.id);
                    this.state.callbackFunc();
                })
            }

        }
    }

    closeSearchItems () {
        this.setState({
            opened: false
        });
    }

    handleSearchChange (event) {
        let value = event.target.value;
        this.setState({
            search: value,
            opened: true
        });
        if (value.length > 3) {
            if (this.props.withTariff) {
                if (this.checkTariffChosen()) {
                    this.props.searchItemsMethod(this.context.selectedRegion.id, this.state.fields.tariff.value, value);
                }
            } else {
                this.props.searchItemsMethod(value, this.context.selectedRegion.id);
            }
        }
    }

    setSearchVal = (value) => {

        return (e) => {
            if (e) {
                e.preventDefault();
            }

            this.setState(() => {
                if (this.props.withTariff) {
                    if (this.checkTariffChosen()) {
                        this.props.searchMethod(this.context.selectedRegion.id, this.state.fields.tariff.value, value);
                    }
                } else {
                    this.props.searchMethod(value, this.context.selectedRegion.id);
                }
                this.state.callbackFunc();
                return {
                    search: value,
                    opened: false
                }
            })
        }
    };
    setSearchVal2 = (value) => {
          this.setState(() => {
                if (this.props.withTariff) {
                    if (this.checkTariffChosen()) {
                        this.props.searchMethod(this.context.selectedRegion.id, this.state.fields.tariff.value, value);
                    }
                } else {
                    this.props.searchMethod(value, this.context.selectedRegion.id);
                }
                this.state.callbackFunc();
                return {
                    search: value,
                    opened: false
                }
            })

    };

    checkTariffChosen = () => {
        let fields = {...this.state.fields};
        if (fields.tariff.value === '') {
            fields.tariff.hasError = true;
            this.setState({fields});
            return false;
        }
        return true;
    };

    onSelected (attr, val) {
        let fields = {...this.state.fields};
        fields[attr].value = val;
        fields[attr].hasError = false;
        this.setState({fields});
        if (this.state.search.length > 3) {
            this.props.searchMethod(this.context.selectedRegion.id, val, this.state.search);
        }
    }

    onClickSearchButton = (e) => {
        e.preventDefault();
        if (this.state.search.length > 3) {
            this.setState(() => {
                if (this.props.withTariff) {
                    if (this.checkTariffChosen()) {
                        this.props.searchMethod(this.context.selectedRegion.id, this.state.fields.tariff.value, this.state.search);
                        this.state.callbackFunc();
                    }
                } else {
                    this.props.searchMethod(this.state.search, this.context.selectedRegion.id);
                    this.state.callbackFunc();
                }
                return {
                    opened: false
                }
            })
        }
    };

    addBold = (items) => {
        let len = this.state.search.length;
        if (len > 3 && items.length > 0) {
            if (items[0].title.toLowerCase().indexOf(this.state.search.toLowerCase())) {
                return [];
            }
            let result = [];
            items.forEach(function (item) {
                let wrap = '<b>' + item.title.substr(0, len) + '</b>';
                let second = item.title.substr(len);
                result.push({
                    title: wrap + second,
                    value: item.title
                });
            });
            return result;
        }
        return [];
    };

    searchOnKeyDown = (event) =>
    {

        if ((event.key === 'Enter') || (event.code === 'Enter') || (event.which == '13')){

            if ( this.flagArrowsInput === true) {

                   this.setSearchVal2(this.props.searchItems[this.state.activeUlChild].title);

                   this.flagArrowsInput = false;

                this.setState({activeUlChild: -1});



            }
            else {
                if (this.state.search.length > 3) {
                    this.setState(() => {
                        if (this.props.withTariff) {
                            if (this.checkTariffChosen()) {
                                this.props.searchMethod(this.context.selectedRegion.id, this.state.fields.tariff.value, this.state.search);
                                this.state.callbackFunc();
                            }
                        } else {
                            this.props.searchMethod(this.state.search, this.context.selectedRegion.id);
                            this.state.callbackFunc();
                        }
                        return {
                            opened: false
                        }
                    })
                }
            }

        }

        if ((event.keyCode === 40) || (event.key === 'ArrowDown') || (event.code === 'ArrowDown') || (event.which === 40)) {


            this.setState( (prevState) => {

                let n = prevState.activeUlChild;

                n++;
                if ( (n) >= this.props.searchItems.length ){
                    n = 0;
                }

                return({
                    activeUlChild: n
                })

            });

            this.flagArrowsInput = true;

        }


        if ((event.keyCode === 38) || (event.key === 'ArrowUp') || (event.code === 'ArrowUp') || (event.which === 38)) {


            this.setState( (prevState) => {

                let n = prevState.activeUlChild;

                n--;
                if ( (n) < 0 ){
                    n = this.props.searchItems.length - 1;
                }

                return({
                    activeUlChild: n
                })

            });

            this.flagArrowsInput = true;

        }


    };

    render() {

        let exampleRegions = [];

        if (this.props.exampleRegions ) {
             exampleRegions = this.props.exampleRegions.split(';');
        }


        let exampleItems = [];

        exampleRegions.forEach(function (item) {
            exampleItems.push({title: item});
        });

        let roamingSearchPlaces = this.addBold(this.props.searchItems);
        let withTariff = this.props.withTariff;
        let infoBlocks = this.props.infoBlocks;

        let field = this.state.fields.tariff;
        let placeholder = this.props.placeholder;


        return (
            <div className="b-roaming__country-search">
                <div className="b-country-search b-country-search_adapt_default">
                    <div className={"b-country-search__wrapper" + (withTariff ? " b-country-search_input-area__with_tariff" : "")}>
                        <div className="b-county-search__input-wrapper">
                            <div className="b-country-search__input-area">
                                <div className="b-country-search__input-block">
                                    <input onChange={this.handleSearchChange} className="b-country-search__input" value={this.state.search} type="text" onKeyDown={this.searchOnKeyDown} placeholder={placeholder}/>
                                    <a className="b-country-search__search-btn" href="#" onClick={this.onClickSearchButton}/>
                                    {
                                        roamingSearchPlaces.length > 0 && this.state.opened
                                            ?   <InputSearchResult closeFunc={this.closeSearchItems} setSearchVal={this.setSearchVal} items={roamingSearchPlaces} activeItemIndex={this.state.activeUlChild}/>
                                            :   ''
                                    }
                                </div>
                                <div className="b-country-search__example-area">
                                    <span className="b-country-search__example-title">Например:</span>
                                    <div className="b-country-search__example-list">
                                        {exampleItems.map((item, index) =>
                                            <a key={index} onClick={this.setSearchVal(item.title)} href="#" className={"b-country-search__example-item"}>{item.title}</a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {
                                withTariff
                                    ?   <div className={"b-country-search__select-area"}>
                                            <div className="b-input-text__label b-input-text__label b-input-text__label_upper">
                                                <div className="b-input-text__label-text">{field.placeholder}</div>
                                            </div>
                                            <Select hasError={field.hasError} options={field.options} attribute={"tariff"} onChange={this.onSelected} value={field.value} />
                                            <div className={"b-country-search__example-area"}>
                                                <p className={"b-country-search__example-title"}>
                                                    <InfoBlock type={'text'} data={infoBlocks} section={'roaming_text_in_search_under_tariff_select'}/>
                                                </p>
                                            </div>
                                        </div>
                                    :   ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        roaming: state.roaming
    }),
    (dispatch) => {
        return {
            getExternalCallsSearchResult : (fromRegionId, tariffId, whitherRegion) => dispatch(callingSearch(fromRegionId, tariffId, whitherRegion)),
            getExternalCallsSearchPlace : (fromRegionId, tariffId, search) => dispatch(callingSearchPlaces(fromRegionId, tariffId, search))
        }
    }
)(RatesRoamingSearch);

