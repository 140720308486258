import RatesMainNew from "./containers/Rates/RatesMainNew";

import RatesMain, {RatesMainLkRequests, RatesMainRequests} from "./containers/Rates/RatesMain";
import RatesArchive from './containers/Rates/RatesArchive';
import RateInner, {RatesTariffsInnerRequestsLk} from './containers/Rates/RateInner';
import RateTariffArchive, {RatesTariffsArchiveRequests} from "./containers/Rates/RateTariffArchive";
import RateTariffIndividual, {
    RatesTariffsIndividualRequests
} from "./containers/Rates/RateTariffIndividual";
import RatesServices, {RatesServicesLkRequests, RatesServicesRequests} from "./containers/Rates/RatesServices";
import RateService, {RateServiceRequests, RateServiceLkRequests} from "./containers/Rates/RateService";
import RatesServiceArchive, {RatesServicesArchiveRequests} from "./containers/Rates/RatesServiceArchive";
import RatesInternet, {RatesInternetLkRequests, RatesInternetRequests} from "./containers/Rates/RatesInternet";
import RatesHomeInternet, {RatesHomeInternetRequests} from "./containers/Rates/RatesHomeInternet";
import RatesMainPage, {RatesMainPageRequests} from "./containers/Rates/RatesMainPage";
import RatesMnp, {RatesMnpRequests} from "./containers/Rates/RatesMnp";
import RatesRoaming, {RatesRoamingRequests, RatesRoamingLkRequests} from "./containers/Rates/RatesRoaming";
import Page404 from "./containers/Page404";
import CookieAgreementContainer from "./containers/Rates/CookieAgreementContainer";
import Support, {supportRequests} from "./containers/Rates/Support";
import Security, {securityRequests} from "./containers/Rates/Security";
import FastDecisions, {fastDecisionsRequests} from "./containers/Rates/FastDecisions";
import MotivOnMap, {motivOnMapRequests} from "./containers/Rates/MotivOnMap";
import ListOffice, {listOfficeRequests} from "./containers/Rates/ListOffice";
import SearchOffice, {searchOfficeRequests} from "./containers/Rates/SearchOffice";
import LKMain from "./containers/LK/LKMain";
import Complain, {complainRequests} from "./containers/Rates/Complain";
import LKAuth from "./containers/LK/LKAuth";
import LKPage, {LkPageRequestsAbonent, LkPageRequestsPayments, LkPageRequestsDetails, LkPageRequestsSecurity, LkPageRequestsServices} from "./containers/LK/LKPage";
import TariffConstructorAbout from "./containers/Rates/TariffConstructorAbout";
import About, { AboutRequests } from "./containers/AboutCompany/About";
import Values, { ValuesRequests } from "./containers/AboutCompany/Values";
import LeaderShip, { LeaderShipRequests } from "./containers/AboutCompany/LeaderShip";
import LegalInformation, { LegalInformationRequests } from "./containers/AboutCompany/LegalInformation";
import Contacts, { ContactsRequests } from "./containers/AboutCompany/Contacts";
import ForSubscribers, { ForSubscribersRequests } from "./containers/AboutCompany/ForSubscribers";
import ForPress, { ForPressRequests } from "./containers/AboutCompany/ForPress";
import ForPartners, { ForPartnersRequests } from "./containers/AboutCompany/ForPartners";
import ForBuisness, { ForBuisnessRequests } from "./containers/AboutCompany/ForBuisness";
import ServicesService from "./containers/Rates/ServicesService";

import PaymentsTransfers from "./containers/PaymentsTransfers/PaymentsTransfers";
import PaymentsMethods from "./containers/PaymentsTransfers/PaymentsMethods";
import Search, { SearchRequests } from "./containers/Search/Search";
import MotivPay from "./containers/PaymentsTransfers/MotivPay";
import DeferredPayment from "./containers/PaymentsTransfers/DeferredPayment";

import Promotions from "./containers/Promotions/Promotions";
import ArchivedPromotions from "./containers/Promotions/ArchivedPromotions";
import RatePromotion from "./containers/Promotions/RatePromotion";
import MotivCoverMap, {motivCoverMapRequests} from "./containers/AboutCompany/MotivCoverMap";
import CookiePolicyContainer from "./containers/Rates/CookiePolicyContainer";
import PolicyAgreement from "./containers/Rates/PolicyAgreement";

export default [
    {
        path: "/",
        component: RatesMainPage,
        exact: true,
        requests: RatesMainPageRequests,
        headerClass: "page__header-holder_float page__header-holder_white"
    },
    {
        path: "/:region/mnp",
        component: RatesMnp,
        exact: true,
        requests: RatesMnpRequests,
        headerClass: "page__header-holder_float page__header-holder_white"
    },
    {
        path: "/:region/cookies-agreement",
        component: CookieAgreementContainer,
        exact: true,
        requests: [],
        headerClass: ""
    },
    {
        path: "/:region/cookies-policy",
        component: CookiePolicyContainer,
        exact: true,
        requests: [],
        headerClass: ""
    },
    {
        path: "/:region/policy-agreement",
        component: PolicyAgreement,
        exact: true,
        requests: [],
        headerClass: ""
    },


    {
        path: "/:region/tariffs",
        // path: "/:region/svoi",
        component: RatesMainNew,
        exact: true,
        // requests: RatesMainRequests,
        // requestsLk: RatesMainLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/svoi/ready-tariffs",
        component: RatesMainNew,
        exact: true,
        // requests: RatesMainRequests,
        // requestsLk: RatesMainLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/tariffs/ready-tariffs",
        // path: "/:region/svoi/ready-tariffs",
        component: RatesMainNew,
        exact: true,
        // requests: RatesMainRequests,
        // requestsLk: RatesMainLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/tariffs/dlya-umnyh-veshchey",
        // path: "/:region/svoi/dlya-umnyh-veshchey",
        component: RatesMainNew,
        exact: true,
        // requests: RatesMainRequests,
        // requestsLk: RatesMainLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/tariffs/vse",
        // path: "/:region/svoi/vse",
        component: RatesMainNew,
        exact: true,
        // requests: RatesMainRequests,
        // requestsLk: RatesMainLkRequests,
        headerClass: ""
    },

    {
        path: "/:region/",
        component: RatesMainPage,
        exact: true,
        requests: RatesMainPageRequests,
        headerClass: "page__header-holder_float page__header-holder_white"
    },
    {
        path: "/:region",
        component: RatesMainPage,
        exact: true,
        requests: RatesMainPageRequests,
        headerClass: "page__header-holder_float page__header-holder_white"
    },
    // {
    //     path: "/:region/tariffs",
    //     component: RatesMain,
    //     exact: true,
    //     requests: RatesMainRequests,
    //     requestsLk: RatesMainLkRequests,
    //     headerClass: ""
    // },
    // {
    //     path: "/:region/tariffs/dlya-umnyh-veshchey",
    //     component: RatesMain,
    //     exact: true,
    //     requests: RatesMainRequests,
    //     requestsLk: RatesMainLkRequests,
    //     headerClass: ""
    // },
    // {
    //     path: "/:region/tariffs/vse",
    //     component: RatesMain,
    //     exact: true,
    //     requests: RatesMainRequests,
    //     requestsLk: RatesMainLkRequests,
    //     headerClass: ""
    // },
    /*{
        path: "/tariffs/number/all",
        component: AllRatesNumber,
        exact: true,
    },*/
    {
        path: "/:region/tariffs/archive",
        component: RatesArchive,
        exact: true,
        requests: RatesTariffsArchiveRequests,
        headerClass: ""
    },
    {
        path: "/:region/tariffs/archive/:slug",
        component: RateTariffArchive,
        exact: true,
        requests: RatesTariffsArchiveRequests,
        headerClass: ""
    },
    {
        path: "/:region/tariffs/individual/:slug",
        component: RateTariffIndividual,
        exact: true,
        requests: RatesTariffsIndividualRequests,
        headerClass: ""
    },
    {
        path: "/:region/tariffs/:slug",
        component: RateInner,
        exact: true,
        requests: RatesMainRequests,
        headerClass: ""
    },
    {
        path: "/:region/services",
        component: RatesServices,
        exact: true,
        requests: RatesServicesRequests,
        requestsLk: RatesServicesLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/services/all",
        component: RatesServices,
        exact: true,
        requests: RatesServicesRequests,
        requestsLk: RatesServicesLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/services/services",
        component: RatesServices,
        exact: true,
        requests: RatesServicesRequests,
        requestsLk: RatesServicesLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/services/cashback",
        component: RatesServices,
        exact: true,
        requests: RatesServicesRequests,
        requestsLk: RatesServicesLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/services/services/:slug",
        component: ServicesService,
        exact: true,
        requests: [],
        requestsLk: [],
        headerClass: "page__header-holder-indentation"
    },

    {
        path: "/:region/services/archive",
        component: RatesServiceArchive,
        exact: true,
        requests: RatesServicesArchiveRequests,
        headerClass: ""
    },
    {
        path: "/:region/services/archive/:slug",
        component: RateService,
        exact: true,
        requests: RateServiceRequests,
        requestsLk: RateServiceLkRequests,
        headerClass: "page__header-holder_float"
    },
    {
        path: "/:region/services/category/:slug",
        component: RatesServices,
        exact: true,
        requests: RatesServicesRequests,
        requestsLk: RateServiceLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/services/:slug",
        component: RateService,
        exact: true,
        requests: RateServiceRequests,
        requestsLk: RateServiceLkRequests,
        headerClass: "page__header-holder_service_slug"
    },
    {
        path: "/:region/internet",
        component: RatesInternet,
        exact: true,
        requests: RatesInternetRequests,
        requestsLk: RatesInternetLkRequests,
        headerClass: ""
    },
    {
        path: "/:region/roaming-i-mezhdugorodnyaya-svyaz/roaming",
        component: RatesRoaming,
        exact: true,
        requests: RatesRoamingRequests,
        requestsLk: RatesRoamingLkRequests,
        headerClass: "",
        queryDependent: true
    },
    {
        path: "/:region/roaming-i-mezhdugorodnyaya-svyaz/roaming-russia",
        component: RatesRoaming,
        exact: true,
        requests: RatesRoamingRequests,
        requestsLk: RatesRoamingLkRequests,
        headerClass: "",
        queryDependent: true
    },
    {
        path: "/:region/roaming-i-mezhdugorodnyaya-svyaz",
        component: RatesRoaming,
        exact: true,
        requests: RatesRoamingRequests,
        headerClass: ""
    },
    {
        path: "/:region/internet/4g",
        component: RatesHomeInternet,
        exact: true,
        requests: RatesHomeInternetRequests,
        requestsLk: RatesInternetLkRequests,
        headerClass: "page__header-holder_white page__header-holder_float"
    },
    {
        path: "/:region/support",
        component: Support,
        exact: true,
        requests: supportRequests,
        headerClass: ""
    },
    // {
    //     path: "/:region/bezopasnost",
    //     component: Security,
    //     exact: true,
    //     requests: securityRequests,
    //     headerClass: ""
    // },
    // {
    //     path: "/:region/fast-decisions",
    //     component: FastDecisions,
    //     exact: true,
    //     requests: fastDecisionsRequests,
    //     headerClass: ""
    // },
    // {
    //     path: "/:region/motiv-on-map",
    //     component: MotivOnMap,
    //     exact: true,
    //     requests: motivOnMapRequests,
    //     headerClass: ""
    // },
    {
        path: "/:region/motiv-cover-map",
        component: MotivCoverMap,
        exact: true,
        requests: motivCoverMapRequests,
        headerClass: ""
    },
    {
        path: "/:region/list-office",
        component: ListOffice,
        exact: true,
        requests: listOfficeRequests,
        headerClass: ""
    },
    {
        path: "/:region/search-office",
        component: SearchOffice,
        exact: true,
        requests: searchOfficeRequests,
        headerClass: ""
    },
    {
        path: "/:region/about-tariff",
        component: TariffConstructorAbout,
        exact: true,
        requests: null,
        headerClass: ""
    },
    {
        path: "/:region/about",
        component: About,
        exact: true,
        requests: AboutRequests,
        headerClass: ""
    },
    //Временно скрыта, подробнее в MOTIV-1196
    // {
    //     path: "/:region/values",
    //     component: Values,
    //     exact: true,
    //     requests: ValuesRequests,
    //     headerClass: ""
    // },
    {
        path: "/:region/leadership",
        component: LeaderShip,
        exact: true,
        requests: LeaderShipRequests,
        headerClass: ""
    },
    {
        path: "/:region/legal-information",
        component: LegalInformation,
        exact: true,
        requests: LegalInformationRequests,
        headerClass: ""
    },
    {
        path: "/:region/contacts",
        component: Contacts,
        exact: true,
        requests: ContactsRequests,
        headerClass: ""
    },
    {
        path: "/:region/for-subscribers",
        component: ForSubscribers,
        exact: true,
        requests: ForSubscribersRequests,
        headerClass: ""
    },

    {
        path: "/:region/payments-methods",
        component: PaymentsMethods,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/payments-transfers",
        component: PaymentsTransfers,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/motiv-pay",
        component: MotivPay,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/deferred-payment",
        component: DeferredPayment,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/actions",
        component: Promotions,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/actions/archive",
        component: ArchivedPromotions,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/actions/archive/:slug",
        component: RatePromotion,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/actions/:slug",
        component: RatePromotion,
        exact: true,
        headerClass: ""
    },
    {
        path: "/:region/actions/category/:slug",
        component: Promotions,
        exact: true,
        headerClass: ""
    },

    {
        path: "/:region/for-press",
        component: ForPress,
        exact: true,
        requests: ForPressRequests,
        headerClass: ""
    },
    {
        path: "/:region/for-partners",
        component: ForPartners,
        exact: true,
        requests: ForPartnersRequests,
        headerClass: ""
    },
    {
        path: "/:region/for-buisness",
        component: ForBuisness,
        exact: true,
        requests: ForBuisnessRequests,
        headerClass: ""
    },
    {
        path: "/:region/search",
        component: Search,
        exact: true,
        requests: SearchRequests,
        headerClass: ""
    },
];

export const notFound = {
    path: "*",
    component: Page404,
    exact: true,
    requests: null,
    headerClass: ""
};

export const lkRoutes = [
    {
        path: "/",
        component: LKMain,
        exact: true,
        requests: [],
        headerClass: ""
    },
    {
        path: "/:region",
        component: LKMain,
        exact: true,
        requests: [],
        headerClass: ""
    },
    {
        path: "/:region/search",
        component: Search,
        exact: true,
        requests: SearchRequests,
        headerClass: ""
    },
];