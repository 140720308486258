import {Component} from "react";
import {onChange, RenderField} from "../Rates/PopupHelpers";
import React from "react";
import {phoneMask} from "../../tools/mask";
import Timer from "../common/Timer";
import {phoneValidator, unitCodeValidator} from "../../tools/form-helper";
import {getWord} from "../../tools/tools";
import TooltipQuestion from "../common/TooltipQuestion/TooltipQuestion";
import PropTypes from "prop-types";


export default class QuickConnection extends Component {

    constructor(props){
        super(props);

        this.state = {
            step: 0,
            isSendToContactNumber: false,
            isSendingRequest: false,
            fields: {
                phone: {
                    name: "phone",
                    type: "text",
                    value: "",
                    placeholder: "+7 ",
                    disabled: false,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: null,
                    textError: "Укажите корректный номер телефона"
                },
                code: {
                    name: "code",
                    type: "text",
                    label: "Код подтверждения",
                    value: "",
                    disabled: false,
                    validators: [unitCodeValidator],
                    hasError: null,
                    textError: ""
                }
            },
            timer: true
        };

        this.onChange = onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onActivate = this.onActivate.bind(this);
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        appRoutes: PropTypes.array,
        setHeaderClassName: PropTypes.func
    };

    onSubmit(e) {
        e.preventDefault();
        if(this.state.isSendToContactNumber){
            if(!this.props.activation.available.hasOwnProperty('error_code') && this.props.activation.available.error_code!=="10016"){
                this.handlerChangeContactPhone();
                let fields = this.state.fields;
                this.setState({fields: {...fields, phone: {...fields.phone, disabled: true}}, isSendToContactNumber:true,  timer: true})
                return;
            }

        }

        this.props.sendNumberForCheck(this.state.fields.phone.value);
        let fields = this.state.fields;
        this.setState({fields: {...fields, phone: {...fields.phone, disabled: true}}, isSendToContactNumber:false, timer: true})
    }

    onActivate (e) {
        e.preventDefault();
        this.props.sendActivateItem(this.state.fields.code.value);
        let fields = this.state.fields;
        this.setState({fields: {...fields, code: {...fields.code, disabled: true}}})
    }

    componentDidUpdate (prevProps, prevState) {

        if(prevState.isSendingRequest && (JSON.stringify(prevProps.activation.available)!==JSON.stringify(this.props.activation.available))){
            this.setState({isSendingRequest:false});
        }

        if (this.props.activation.activated !== false && (this.props.activation.activated === null || !this.props.activation.activated.hasOwnProperty('error_msg'))) {
            this.props.onSuccess();
        }
    }

    isValid = () => {
        return this.state.fields.phone.value.replace(/\D/gim, '').length === 11;
    };

    isValidCode = () => {
        return this.state.fields.code.value.length === 6;
    };

    timerEnd = () => {
        this.setState({
            timer: false
        });
    };

    handlerChangeContactPhone(){
        if(!this.state.isSendingRequest) this.setState({isSendingRequest:true});
        if(!this.state.isSendToContactNumber) this.setState({isSendToContactNumber: true});
        this.props.sendNumberForCheck(this.state.fields.phone.value, true);
    }

    onBlur = (name) => {
        let field = this.state.fields[name];
        if (field.hasOwnProperty('validators')) {
            let valid = true;
            field.validators.forEach(function (func) {
                if (!func.call(this, field.value)) {
                    valid = false;
                }
            });
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: !valid
                    }
                }
            });
        }
    };

    render() {
        let fields = this.state.fields;
        const selectRegion = this.context.selectedRegion.alias;

        let {
            activation,
            type,
            item,
            info_text,
            info_textContactNumber
        } = this.props;

        if (activation.activated && activation.activated.hasOwnProperty('error_msg')) {
            return  <div className="mnp-popup_phone_commands">
                        <div className="inputs-group-title">{activation.activated.error_msg}</div>
                    </div>
        }


        if (Object.keys(activation.available).length === 0) {
            return <div className="mnp-popup_phone_commands">
                        <div>{info_text}</div>
                        <form className="form-horizontal mnp-popup_form" onSubmit={this.onSubmit}>
                            <RenderField field={fields['phone']} onChange={this.onChange} onblur={this.onBlur}/>
                            <button className={"u-btn u-btn_default u-btn_adapt_mobile-sm"+(this.isValid() ? "" : " disabled") + (activation.inProgress ? " disabled" : "")}>Проверить номер</button>
                        </form>
                   </div>
        } else {

            if (activation.available.hasOwnProperty('error_msg')) {
                return  <div className="mnp-popup_phone_commands">
                            <div className="inputs-group-title">{activation.available.error_msg}</div>
                        </div>
            } else if (activation.available.hasOwnProperty('query_id') || activation.available.error_code==="10016") {

                return  (<div className="mnp-popup_phone_commands">
                            {   type === 'tariff'
                                ?   <React.Fragment>
                                        <div className="inputs-group-title">Ваш номер подходит!</div>
                                        <div className="popup-notification">
                                            SMS с кодом подтверждения отправлено на указанный номер. Введите его в поле ниже.
                                        </div>
                                    </React.Fragment>
                                :   ''
                            }
                            {   type === 'service'
                                ?   <React.Fragment>
                                        <div className="popup-notification">
                                            SMS с кодом подтверждения отправлено на указанный номер. Введите его в поле ниже.
                                        </div>
                                        {item && item.activation_price
                                            ?   <div className="popup-notification">
                                                    Обращаем ваше внимание, что при подключении услуги с вашего счета будет списано {item.activation_price} {getWord(item.activation_price, "рубль", "рубля", "рублей")}
                                                </div>
                                            :   ""
                                        }
                                    </React.Fragment>
                                :   ''
                            }
                            <form className="form-horizontal mnp-popup_form mnp-popup_form__can-change" onSubmit={this.onActivate}>
                                <RenderField field={fields['code']} onChange={this.onChange} onblur={this.onBlur}/>
                                <button id={"web-quick-connection-submit-button"} className={"u-btn u-btn_default u-btn_adapt_mobile-sm"+(!this.isValidCode() ? " disabled" : "") + (activation.inProgress ? " disabled" : "")}>{type === 'tariff' ? "Подключить тариф" : "Подключить услугу"}</button>
                            </form>



                            {this.state.timer
                                ? <div className={"popup-repeat-text"}>Заказать код повторно можно через <Timer callbackFunc={this.timerEnd}/> секунд</div>
                                : <div className={"popup-repeat-text popup-repeat-text__link"} onClick={this.onSubmit}>Выслать еще раз</div>}

                    {!this.state.isSendToContactNumber?<div style={{display:'flex', alignItems: 'baseline'}}>
                        <button
                            onClick={()=>this.handlerChangeContactPhone()}
                            className='action-contact-number-button'>Отправить Смс на контактный номер</button>
                        <TooltipQuestion text={info_textContactNumber}/>
                    </div>:!this.state.isSendingRequest?<div>{activation.available.error_code==="10016"?<p className='action-contact-number-text'>Для номера {this.state.fields.phone.value} не назначен контактный номер.<br/> Обратитесь в <a href={`/${selectRegion}/motiv-on-map`}>офис</a> для добавления</p>:<p className='action-contact-number-text'>Смс отправлено на контактный номер</p>}</div>:''}

                        </div>)
            } else {
                return  <div className="mnp-popup_phone_commands">
                            <div className="inputs-group-title">Ошибка сервера</div>
                        </div>
            }
        }
    }
}