import React, { Component } from 'react';
import TabSwitchItem from "./TabSwitchItem";

class TabsSwitcherRoaming extends Component {
    getNext (activeTab, items) {

        if (!items || !items.length) return null;
        const currentIndex = items.findIndex(function (item) {
            return item.alias === activeTab;
        });
        const nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % items.length;
        return items[nextIndex] && items[nextIndex].alias ? items[nextIndex].alias : null;


    };

    getPrev (activeTab, items) {
        if (!items || !items.length) return null;
        const currentIndex = items.findIndex(function (item) {
            return item.alias === activeTab;
        });
        const prevIndex = currentIndex === -1 ? items.length - 1 : (currentIndex - 1 + items.length) % items.length;
        return items[prevIndex] && items[prevIndex].alias ? items[prevIndex].alias : null;

    };

    render() {

        let {
            activeTab,
            onClickFunc,
            items,
            children,
            itemHasWrap,
            className,
            closeCard
        } = this.props;

        const activeTabAlias =
            (activeTab && activeTab.alias) || (items && items[0] && items[0].alias) || null;
        let next = this.getNext(activeTabAlias, items);
        let prev = this.getPrev(activeTabAlias, items);

        if(!activeTab){
            return '';
        }

        return (
            <div className={"b-tabs-header b-tabs-header_adapt_default" + (className ? " " + className : "")}>
                <div className="b-tabs-header__wrapper">
                    <div className="b-tabs-header__items-list" onClick={closeCard}>
                        <div className="b-tabs-header__nav-btn b-tabs-header__nav-btn_prev" onClick={onClickFunc(prev)} />
                        {items.map((item, key) =>
                            <TabSwitchItem key={key} idx={item.alias ? item.alias : key} item={item} activeTab={activeTab.alias || ''} onClickFunc={onClickFunc} hasWrap={itemHasWrap} />
                        )}
                        {children}
                        <div className="b-tabs-header__nav-btn b-tabs-header__nav-btn_next" onClick={onClickFunc(next)} />
                    </div>
                </div>
            </div>
        )
    }
}

export default TabsSwitcherRoaming;