import React, { Component } from 'react';
import Collapse from "./Collapse";
import {putDataFlags} from "../../actions/vacanciesCollapseFlags";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

class CollapseBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapses: null,
            stateChange: false,
            activeDefaultSelect: false
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };


    componentWillMount(){
        let options = this.props.options;

        if ( !!options ){
            this.renderItem(options);
        }

        if (this.props.vacanciesCollapseFlags.collapseFlg.length !== 0 && this.props.vacanciesCollapseFlags.collapseFlg !== this.state.collapses){
            this.setState({collapses: this.props.vacanciesCollapseFlags.collapseFlg});
        }

    }

    componentDidUpdate(prevProps, prevState){
        let collapses = this.state.collapses;

        let block = document.getElementById('collapse-vacancy');

        if(JSON.stringify(this.props.vacanciesCollapseFlags.collapseFlg) === JSON.stringify(prevProps.vacanciesCollapseFlags.collapseFlg)){
            if(this.state.activeDefaultSelect){
                setTimeout(()=>{
                    block.scrollIntoView({behavior:'smooth', block:'nearest'});
                }, 2000)
            }

        }

        if (collapses !== this.props.vacanciesCollapseFlags.collapseFlg && this.props.vacanciesCollapseFlags.collapseFlg.length <= 0){
            this.props.setVacanciesCollapseFlags({data: collapses});
        }

        if (this.state.collapses !== this.props.vacanciesCollapseFlags.collapseFlg && this.state.collapses === prevState.collapses){
            this.props.setVacanciesCollapseFlags({data: collapses});
        }
    }

    componentWillReceiveProps(nextProps) {

        let options = this.props.options;

        if ( options !== nextProps.options ){
            this.renderItem(nextProps.options);
        }
    }

    renderItem = (item) => {

        const self = this;

        const mas = item.map((option) => {

            let opened = false;

            if(option.id===this.props.defaultSelect) {
                opened= true;
                self.setState({activeDefaultSelect:true})
            }
            return {
                ...option,
                flagId: option.id,
                opened: opened
            }
        });

        this.setState({collapses: mas});
    };

    fnCollapse = (id) => {
        let collapses = this.state.collapses;
        let item = collapses.find(x => x.flagId === id);

        if (item.opened === true){
            item.opened = false;
            this.setState({
                collapses
            });
        } else if (item.opened === false){
            item.opened = true;
            this.setState({
                collapses
            });
        }
    };

    render() {

        let {
            classWrappedSubList,
            classSubList,
            classSubListItem,
            vacancyLink
            } = this.props;

        let {collapses} = this.state;
        let selectedRegion = this.context ? this.context.selectedRegion : null;

        return (
            <div className="b-list-collapser b-list-collapser_adapt_tablet-big" id='collapse-vacancy'>
                <div className="b-list-collapser__list">
                    {collapses && collapses.length > 0 ?
                        collapses.map((item, index) =>
                            <Collapse title={item.title} itemLength={item.vacancies.length} key={index} fnCollapse={this.fnCollapse} flag={item.flagId} opened={item.opened}>
                                <ul className={ classWrappedSubList }>
                                    {
                                        item.vacancies.map((subItem) =>
                                            <li className={ classSubList } key={ subItem.id }>
                                                <Link className={ classSubListItem } idv={subItem.id} to={  "/" + selectedRegion.alias + vacancyLink + "/" + subItem.id  }>{ subItem.title }</Link>
                                            </li>
                                        )
                                    }
                                </ul>
                            </Collapse>
                        )
                    : <div className="b-list-collapser__list--dscr">В выбранном городе вакансий не найдено.</div>}
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        vacanciesCollapseFlags: state.vacanciesCollapseFlags,
    }),
    (dispatch) => {
        return {
            setVacanciesCollapseFlags: (obj) => dispatch(putDataFlags(obj)),
        }
    }
)(CollapseBlock);