import React, {useEffect, useMemo} from "react";
import {mediaUrl} from "../../tools/config";
import Icon from "../common/Icon";
import LinkC from "../common/LinkC";
import InfoblockLink from "./InfoblockLink";
import AdvertisingLabeling from "../AdvertisingLabeling/AdvertisingLabeling";


export default function InfoBlockShop({blocks}) {

    return <div className="page__product-plates">
        <div className="js-prod-slider b-product-slider b-product-slider_adapt_b2c b-product-slider_hover_b2c b-product-slider_hoverable">
            <div className="b-product-slider__wrapper">
                <div className="b-product-slider__header">
                    <div className="b-product-slider__title">
                        <div className="b-product-slider__title-label">Интернет–магазин</div>
                    </div>
                </div>
                <div className="b-product-slider__slider">
                    {blocks.map((block) => {
                        const imageSrc = block.imagePath.replace(/\s/g, '').length>0?block.imagePath: require("../../../images/content/no_photo.png");

                        return (
                            <div className="b-product-slider__slide" key={block.alias}>
                                <div className="b-product-slider__slide--flat">
                                    <AdvertisingLabeling
                                        token={block && block.token}
                                        advertiser={block && block.advertiser}
                                    />
                                    <div className="js-product-init b-product-plate b-product-plate_adapt_default b-product-plate_hover_b2c">
                                        <div className="b-product-plate__wrapper">
                                            <div className="b-product-plate__img-holder">
                                                <img className="b-product-plate__image b-product-plate__image_def"
                                                     src={imageSrc} alt="" role="presentation" />
                                            </div>
                                            <div className="b-product-plate__content">
                                                <div className="b-product-plate__main-area">
                                                    <div className="b-product-plate__name">{block.title}</div>
                                                </div>
                                                <div className="b-product-plate__price-area">
                                                    { block.text.indexOf('₽') === -1 ?
                                                        <div className="b-product-plate__price">{block.text}</div> :
                                                        <div className="b-product-plate__price">
                                                            {block.text.replace('₽', '')}
                                                            <span className="b-product-plate__price price-rub">₽</span>
                                                        </div>
                                                    }

                                                    <InfoblockLink className={"b-product-plate__action-block"} item={block}>
                                                        <Icon name="icon_basket-pl" className="b-product-plate__action-icon u-icon u-icon_basket-pl"/>
                                                        <span className="b-product-plate__action-text">{block.link_title}</span>
                                                    </InfoblockLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="b-product-slider__link-sector">
                    <LinkC noFollow={true} className="b-product-slider__link" to="https://shop.motivtelecom.ru/">Перейти в интернет – магазин</LinkC>
                </div>
            </div>
        </div>
    </div>
}
