import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { codeValidatorText, emailValidatorText } from "../../tools/form-helper";
import Icon from "../common/Icon";
import FormField from "./FormField";

import { activateMyTariff, activateTariffConstructor, checkAvailableConstructor, clearTariffPopupError } from "../../actions/activation";
import { timerValueAction } from '../../actions/common';
import { addBrackets, getCookie, isEmptyObj } from "../../tools/tools";
import Timer from "../common/Timer";
import PdfLink from "./PdfLink";
import TooltipQuestion from "../common/TooltipQuestion/TooltipQuestion";
import {settingsList} from "../../actions/settings";

class QuickConnectionFormNew extends Component {

    constructor(props) {
        super(props);
        const validAgreement_with_terms = (props && props.settings && props.settings.agreement_with_terms) || '';

        const indTerms = validAgreement_with_terms && validAgreement_with_terms.indexOf('{{agreement_with_terms_msisdn}}');
        const formatTermsFirst = (indTerms !== -1) ? `${validAgreement_with_terms && validAgreement_with_terms.substr(0, indTerms)}` : validAgreement_with_terms;
        const formatTermsSecond = (indTerms !== -1) ? `${validAgreement_with_terms && validAgreement_with_terms.substr(indTerms + '{{agreement_with_terms_msisdn}}'.length)}` : validAgreement_with_terms;

        this.state = {
            fields: {
                isSendToContactNumber: false,
                isSendToContactNumberError: false,
                isSendingRequest: false,
                email: {
                    type: "text",
                    name: "email",
                    label: "Ваш e-mail",
                    value: '',
                    placeholder: "info@mail.ru",
                    // hint: props.settings.quick_email_hint || '',
                    error: '',
                    validate: emailValidatorText,
                    typeInput: "email"
                },
                code: {
                    type: "text",
                    name: "code",
                    label: "Введите код из Смс",
                    value: '',
                    placeholder: '',
                    error: '',
                    validate: codeValidatorText
                },
                i_agree: {
                    type: "checkbox",
                    name: "i_agree",
                    label: <span dangerouslySetInnerHTML={{ __html: props.settings.agreement_with_rules }} />,
                    value: false,
                    error: ''
                },
                tariff_conditions: {
                    type: "checkbox",
                    name: "tariff_conditions",
                    label: <span><PdfLink constructor={props.constructor} className="link-accent" linkFile={props.pdfFile}>{formatTermsFirst}</PdfLink> {formatTermsSecond}</span>,
                    value: false,
                    error: ''
                }
            },
            // step: 2,
            formValid: false,
            errorMessage: '',
            timer: true,
            formSent: false,
            disableBtnTimer: !!getCookie('has_connection') && getCookie('has_connection') > 1
        }
    }

    static contextTypes = {
        constructorParams: PropTypes.object,
        selectedRegion: PropTypes.object
    };

    componentWillMount() {

    }
    componentDidMount() {
        this.props.getActivationInfo();
    }
    componentWillUnmount() {
        this.props.clearTariffPopupError();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.isSendingRequest && (JSON.stringify(prevProps.activation.available)!==JSON.stringify(this.props.activation.available))){
            this.setState({isSendingRequest:false});
        }
    }

    componentWillReceiveProps(nextProps) {

        let available = nextProps.activation.available;
        let activation = nextProps.activation.activated;

        if (available.hasOwnProperty('query_id')) {
            if (nextProps.activation.activated === true) {
                this.props.openSuccess('success-tariff-connection');
            }
            else {
                this.setState({ formValid: false });
            }
        }



        if (this.props.activation.activatedMyTariff !== nextProps.activation.activatedMyTariff && nextProps.activation.activatedMyTariff === true) {
            this.props.openSuccess('success-tariff-connection');
            if (this.state.formSent) this.setState({ formSent: false });
        }

        if(nextProps.activation.error && available.hasOwnProperty('message') && available.error_code==="10016"){
            if(!this.state.isSendToContactNumberError) this.setState({isSendToContactNumberError: true});
        }

        if (nextProps.activation.error && available.hasOwnProperty('message') && available.error_code!=="10016") {
            this.setState({ errorMessage: available.message })
        }
        // if (nextProps.activation.error && nextProps.activation.balanceLack && nextProps.activation.balanceLack.hasOwnProperty('message')) {
        //     this.setState({ errorMessage: nextProps.activation.balanceLack.message })
        // }

        if (nextProps.activation.error && nextProps.activation.activated.hasOwnProperty('message')) {
            this.setState({ errorMessage: nextProps.activation.activated.message })
        }

        if (nextProps.activation.error && nextProps.activation.activatedMyTariff.hasOwnProperty('message')) {
            this.setState({ errorMessage: nextProps.activation.activatedMyTariff.message })
        }

        if (nextProps.activation.errorBalanceLack && nextProps.activation.balanceLack && nextProps.activation.balanceLack.hasOwnProperty('message')) {
            this.setState({ errorMessage: nextProps.activation.balanceLack.message })
        }
    }

    onChange = (name, value) => {
        let fields = { ...this.state.fields };

        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        fields[name].error = fields[name].validate ? fields[name].validate(fields[name].value) : "";

        this.setState({ fields });

        this.validateForm();
    };

    validateForm = () => {
        let fields = { ...this.state.fields };

        let formValid = true;
        if (this.props.isGuest) {
            // if (this.props.userPhone !== this.props.authPhone) {
            if (fields['code'].validate(fields['code'].value) !== "") {
                formValid = false;
            }

            if (fields['i_agree'].value === false || fields['tariff_conditions'].value === false) {
                formValid = false;
            }

        }

        if (fields['tariff_conditions'].value === false) {
            formValid = false;
        }
        if (fields['email'].value !== "" && fields['email'].validate(fields['email'].value) !== "") {
            formValid = false;
        }

        this.setState({ formValid: formValid });
    };

    renderField = (name, className = false) => {
        let disabled = this.state.fields[name].type === "checkbox" && (this.props.activation.inProgress || this.state.formSent);
        return <FormField
            {...this.state.fields[name]}
            disabled={disabled}
            onChange={this.onChange}
            className={className}
        />;
    };

    onSubmit = (e) => {
        e.preventDefault();
        if(this.state.isSendToContactNumber){
            if(!this.props.activation.available.hasOwnProperty('error_code') && this.props.activation.available.error_code!=="10016"){
                this.handlerChangeContactPhone();
                this.props.timerValueAction(true);
                return;
            }

        }

        this.props.checkAvailableConstructor(this.props.userPhoneValue, this.context.selectedRegion.id);
        this.props.timerValueAction(true);
    };
    // 
    timerEnd = () => {
        this.props.timerValueAction(false);
    };
    disableBtnTimerEnd = () => {
        this.setState({
            disableBtnTimer: false
        });
    };
    // 
    checkTypeVar = typeVar => {
        let result;
        if (isEmptyObj(this.props.tariffConstructor.recalcData)) {
            if (typeVar === 'abon') {
                return result = this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.subs_fee;
            }
            if (typeVar === 'discount') {
                if (this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.discount === 0) {
                    return result = this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.subs_fee;
                } else {
                    return result = this.props.tariffConstructor.defaultData && +this.props.tariffConstructor.defaultData.subs_fee_discount;
                }
            }
        } else {
            if (typeVar === 'abon') {
                return result = this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.subs_fee;
            }
            if (typeVar === 'discount') {
                if (this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.discount === 0) {
                    return result = this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.subs_fee;
                } else {
                    return result = this.props.tariffConstructor.recalcData && +this.props.tariffConstructor.recalcData.subs_fee_discount;
                }
            }
        }
    }

    // сбор аналитики Svoi
    collectionOfAnalyticsSvoi() {
        try {
            ym(5462218, 'reachGoal', 'svoi_form_send');
            // ga('create', 'UA-120539241-1', 'auto');
            // ga('send', 'event', 'forms_sent', 'ga_svoi_form_send');
            VK.Goal('submit_application');
            var _tmr = window._tmr || (window._tmr = []); _tmr.push({ "type": "reachGoal", "id": 2767574, "goal": "svoy_tarif" });
            // ttq.track('CompleteRegistration');

            let { smsCount, tariffConstructor } = this.props;
            let constructor = this.context.constructorParams;
            let recObj = tariffConstructor && tariffConstructor.recalcData;
            let { gb, min, youtube_unlim, motiv_voice_unlim, social_unlim, ivi, data_unlim, router } = constructor;
            const paramsNewFormsTarifs = {
                name: `Svoi Констр ${gb}Гб ${min}минут ${+smsCount} ${youtube_unlim} ${motiv_voice_unlim} ${social_unlim} ${ivi} ${data_unlim} ${router}`,
                price: +recObj.abon_discount || +recObj.abon,
            };

            // для GTM
            setDataLayer('newFormsTarifs', paramsNewFormsTarifs);
        } catch (e) {
            console.log(e);
        }
    };

    activateTariff = (e) => {
        e.preventDefault();
        this.collectionOfAnalyticsSvoi();

        let {
            tariffConstructor,
            tariffName,
            activation,
            userPhoneValue,
            smsCount
        } = this.props;

        let constructor = this.context.constructorParams;
        let phone = userPhoneValue.replace(/\D/gim, '');
        let tariff_id = false;

        this.props.activateTariff({
            region_id: this.context.selectedRegion.id,
            abnum: phone,

            email: this.state.fields.email.value,
            query_id: activation.available.query_id,
            verify_code: this.state.fields.code.value,
            socialNetworkUnlim: constructor.social_unlim,
            motiv_voice_unlim: constructor.motiv_voice_unlim,
            data_unlim: constructor.data_unlim,
            router: constructor.router,
            youtube_unlim: constructor.youtube_unlim,
            ivi: constructor.ivi,
            home_region_checked: constructor.home_region_checked,

            alias: tariffName,
            abon: this.checkTypeVar('abon'),
            abon_discount: this.checkTypeVar('discount'),
            min: constructor.min,
            sms: +smsCount,
            data: constructor.gb,

            tariff_id,
            is_site: 1,
            // smsCount
        }, this.context.selectedRegion.id);
    };

    getTariffData = () => {
        let {
            tariffConstructor,
            tariffName,
            activation,
            userPhoneValue,
            smsCount
        } = this.props;

        let constructor = this.context.constructorParams;
        let phone = userPhoneValue.replace(/\D/gim, '');
        let tariff_id = false;

        return {
            region_id: this.context.selectedRegion.id,
            abnum: phone,

            email: this.state.fields.email.value,
            socialNetworkUnlim: constructor.social_unlim,
            motiv_voice_unlim: constructor.motiv_voice_unlim,
            data_unlim: constructor.data_unlim,
            router: constructor.router,
            youtube_unlim: constructor.youtube_unlim,
            ivi: constructor.ivi,
            home_region_checked: constructor.home_region_checked,

            alias: tariffName,
            abon: this.checkTypeVar('abon'),
            abon_discount: this.checkTypeVar('discount'),
            min: constructor.min,
            sms: +smsCount,
            data: constructor.gb,

            tariff_id,
            is_site: 1,
            // smsCount
        };
    }

    activateMyTariff = (e) => {
        e.preventDefault();
        this.collectionOfAnalyticsSvoi();

        let {
            tariffName,
            userPhoneValue,
            smsCount,
            tariffConstructor
        } = this.props;

        let constructor = this.context.constructorParams;
        let phone = userPhoneValue.replace(/\D/gim, '').replace(/^\d/, '');

        let options = [];
        options.push(constructor.currentSmsPack);
        constructor.social_unlim ? options.push('SOCIAL_UNLIM') : null;
        constructor.data_unlim ? options.push('DATA_UNLIM') : null;
        constructor.motiv_voice_unlim ? options.push('MVOICE_UNLIM') : null;
        constructor.data_unlim && constructor.router ? options.push('ROUTER') : null;
        constructor.youtube_unlim ? options.push('YOUTUBE_UNLIM') : null;
        constructor.ivi ? options.push('IVI') : null;
        constructor.home_region_checked ? options.push('HOME_REGION_CHECKED') : null;

        // let utm_start = getCookie("utm_start");
        // const copy_start_params = getCookie("copy_start_params");
        // let utm_finish = checkUtmParams();
        // if (typeof utm_start === 'undefined' && !!copy_start_params) {
        //     utm_start = copy_start_params;
        // }

        let body = {
            region_id: this.context.selectedRegion.id,
            // region_id: tariffConstructor.recalcData.tariff_id,
            msisdn: phone,
            tariff_id:tariffConstructor.recalcData.tariff_id,
            email: this.state.fields.email.value,
            alias: tariffName,
            min: constructor.min,
            data: constructor.gb,
            abon: this.checkTypeVar('abon'),
            abon_discount: this.checkTypeVar('discount'),
            sms_count: +smsCount,
            options,

            // utm_start,
            // utm_finish,
        }
        this.props.activateMyTariff(body, this.context.selectedRegion.billing_id);

        this.setState({
            formSent: true
        })
    }

    handlerChangeContactPhone(){

        const phone=this.props.userPhoneValue;
        const region=this.context.selectedRegion.id;
        if(!this.state.isSendingRequest) this.setState({isSendingRequest:true});
        if(!this.state.isSendToContactNumber) this.setState({isSendToContactNumber: true});
        this.props.checkAvailableConstructor(phone, region, true);
    }

    renderContent = () => {
        let {
            isGuest,
            userPhone,
            timerValue,
            clientInfo,
            settings,
            checkBalance,
            activation,
            settingsInfo,
            isTariffConnection
        } = this.props;

        const tooltipContactNumber = settingsInfo.tooltip_contact_number_for_connect_tariff || '';
        const selectRegion = this.context.selectedRegion.alias;

        let authPhone = clientInfo && clientInfo.username === undefined ? '' : clientInfo.username.replace(/\D/gim, '');
        let numberEdit = userPhone.replace(/\D/gim, '').replace(/^\d/, '');

        const ind = settings.sms_description.indexOf('{{sms_description_msisdn}}');
        const formatTextSms = (ind !== -1) ? `${settings.sms_description.substr(0, ind)}<span>${addBrackets(userPhone)}</span>&ensp;${settings.sms_description.substr(ind + '{{sms_description_msisdn}}'.length)}` : settings.sms_description;
        const indPhone = settings.switching_to_tariff_note.indexOf('{{switching_to_tariff_msisdn}}');
        const formatTextPhone = (indPhone !== -1) ? `${settings.switching_to_tariff_note.substr(0, indPhone)}<span>${addBrackets(userPhone)}</span>&ensp;${settings.switching_to_tariff_note.substr(indPhone + '{{switching_to_tariff_msisdn}}'.length)}` : settings.switching_to_tariff_note;

        switch (this.state.step) {
            default:
                return <div className="quick_connection">
                    {this.state.errorMessage && activation.available.error_code!=="10016"
                        ? <p> {this.state.errorMessage}</p>
                        : <form
                            onKeyDown={(event) => {
                                // по клику на Enter предотвращаем любые всплытия
                                if (event.which === 13 || event.keyCode === 13 || event.key === "Enter") {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    event.nativeEvent.stopImmediatePropagation();
                                }
                            }}
                        >
                            {/* {isGuest  */}
                            {numberEdit !== authPhone
                                ? <div className="information_phone" dangerouslySetInnerHTML={{ __html: formatTextSms }}>
                                </div>
                                : <div className="information_phone" dangerouslySetInnerHTML={{ __html: formatTextPhone }}>
                                </div>
                            }
                            {/* {!isGuest */}
                            {numberEdit === authPhone
                                ? null
                                : <div style={{marginBottom:32}}>
                                    <div className="verifity_code" style={{marginBottom:0}}>
                                        <div className="code-field">
                                            {this.renderField("code")}
                                        </div>

                                        {timerValue
                                            ? <div className="verifity_code_btn_again">
                                                Заказать код повторно можно через <br />
                                                <div className="verifity_code_btn_timer"><Timer callbackFunc={this.timerEnd} /> секунд</div>
                                            </div>
                                            : <div className="verifity_code_btn" onClick={this.onSubmit}>
                                                <Icon
                                                    name="icon_roll"
                                                    className="roll"
                                                />
                                                Выслать код еще раз
                                            </div>
                                        }
                                    </div>

                                    {!this.state.isSendToContactNumber && !this.state.isSendToContactNumberError?<div style={{display:'flex', alignItems: 'baseline'}}>
                                        <button
                                            onClick={()=>this.handlerChangeContactPhone()}
                                            className='action-contact-number-button'
                                        >Отправить Смс на контактный номер</button>
                                        <TooltipQuestion text={tooltipContactNumber}/>
                                    </div>:<div>{this.state.isSendToContactNumberError?<p className='action-contact-number-text'>Для номера {userPhone} не назначен контактный номер.<br/> Обратитесь в <a href={`/${selectRegion}/motiv-on-map`}>офис</a> для добавления</p>
                                        :!this.state.isSendingRequest?<p className='action-contact-number-text'>Смс отправлено на контактный номер</p>:''}</div>}
                                </div>
                            }



                            <div className="form_row">
                                {this.renderField("email", "email_focus")}
                            </div>
                            <div className="description">{settings.quick_email_hint}</div>

                            <div className="form_row agree">
                                {/* {!isGuest ? null : this.renderField("i_agree")} */}
                                {numberEdit === authPhone ? null : this.renderField("i_agree")}
                                {this.renderField("tariff_conditions")}
                            </div>

                            <div className="form_row btns">
                                {
                                    activation.is500Error ? (
                                        <span className='b-error-text'>К сожалению, мы не можем подключить интересующий вас тариф. Повторите попытку позже или позвоните по номеру 8 800 240 0000 (звонок бесплатный).</span>
                                    ) : (
                                        <Fragment>
                                            <button
                                                className="btn_cancel"
                                                onClick={this.props.close}
                                            >
                                                Отмена
                                            </button>
                                            {
                                                checkBalance ?
                                                    <button
                                                        className={`btn_accent ${this.state.formValid && !this.props.activation.inProgress ? '' : "disabled"}`}
                                                        type="submit"
                                                        onClick={(e) => { e.preventDefault(); checkBalance(this.state.fields['code'].value, this.getTariffData()) }}
                                                    >
                                                        Подключить
                                                    </button>
                                                    :
                                                    <Fragment>
                                                        {/* {!isGuest */}
                                                        {numberEdit === authPhone
                                                            ? <button
                                                                className={`btn_accent ${this.state.formValid && !this.state.formSent && !this.state.disableBtnTimer ? '' : "disabled"}`}
                                                                onClick={this.activateMyTariff}
                                                                type="submit"
                                                            >
                                                                {this.state.disableBtnTimer
                                                                    ? <React.Fragment>
                                                                        Осталось&nbsp;
                                                                        <Timer
                                                                            callbackFunc={this.disableBtnTimerEnd}
                                                                            withMinutes seconds={Math.round((getCookie('has_connection') - new Date().getTime()) / 1000)}
                                                                        />
                                                                    </React.Fragment>
                                                                    : 'Подключить'
                                                                }
                                                            </button>
                                                            : <button
                                                                className={`btn_accent ${this.state.formValid && !this.props.activation.inProgress ? '' : "disabled"}`}
                                                                onClick={this.activateTariff}
                                                                type="submit"
                                                            >
                                                                Подключить
                                                            </button>
                                                        }
                                                    </Fragment>
                                            }
                                        </Fragment>
                                    )
                                }
                            </div>
                        </form>
                    }
                </div>;
        }
    };

    render() {
        return this.renderContent();
    }
}

export default connect(
    state => ({
        isGuest: state.client.isGuest,
        activation: state.activation,
        settings: state.constructor.settings,
        settingsInfo: state.settings,
        userPhoneValue: state.common.userPhoneValue,
        tariffConstructor: state.tariffConstructor,
        timerValue: state.common.timerValue,
        clientInfo: state.client.clientInfo,
    }),
    (dispatch) => {
        return {
            checkAvailableConstructor: (phone, regionId, smartDevice) => dispatch(checkAvailableConstructor(phone, regionId, null, smartDevice)),
            activateTariff: (tariff, regionId) => dispatch(activateTariffConstructor(tariff, regionId)),
            timerValueAction: (data) => dispatch(timerValueAction(data)),
            getActivationInfo: () => dispatch(settingsList("tooltip_contact_number_for_connect_tariff")),
            activateMyTariff: (tariff, regionId) => dispatch(activateMyTariff(tariff, regionId)),
            clearTariffPopupError: () => dispatch(clearTariffPopupError()),
        }
    }
)(QuickConnectionFormNew);
