import React, {Component} from 'react';
import PropTypes from "prop-types";
import ChangeRate from "./ChangeRate";
import Modal from "../common/Modal";
import {getMeasure}  from '../../tools/tools';
import SwitcherInput from "../common/SwitcherInput";
import ChangeRateOk from "../modals/ChangeRateOk";
import {connect} from "react-redux";
import ChangeRateConfirmation from "../modals/ChangeRateConfirmation";
import RecoveryForm from "../../components/Forms/RecoveryForm";
import {clearState, recovery, toggleServices} from "../../actions/client";
import {mainUrl} from "../../tools/config";
import {getPathWithoutRegion} from "../../tools/url-helper";

class RateServiceListItem extends Component {

    constructor(props, context){
        super(props, context);
        this.state = {
            modal: context.modalData && context.modalData.id === props.item.id ? 'change-rate' : null,
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore:  PropTypes.func,
        modalData: PropTypes.object,
        setModalData: PropTypes.func,
        isLk: PropTypes.bool,
        isB2b: PropTypes.bool,
        appRoutes: PropTypes.array,
        setHasItemModal: PropTypes.bool,
        locationPath: PropTypes.string
    };

    showModal(type) {
        return (e) => {
            if (this.props.isGuest && type === 'change-rate' && this.context.setModalData) {
                this.context.setModalData(this.props.item)
            }
            if (typeof e !== "undefined") {
                e.stopPropagation();
                e.preventDefault();
            }
            this.setState({
                modal: type
            });
            this.context.setHasItemModal && this.context.setHasItemModal(this.props.item.id);
        }
    }

    static getDerivedStateFromProps(props, state){

        if (props.successMessageTitle && "recovery" === state.modal) {
            return {...state, modal: "recovery-ok"}
        }
        if (props.toggleServiceError && "change-rate" === state.modal) {
            return {...state, modal: "change-rate-error"}
        }
        if (props.toggleServiceMessage && "change-rate" === state.modal) {
            return {...state, modal: "change-rate-ok"}
        }
        return state;
    }

    componentWillUnmount(){
        if (this.state.modal === "change-rate") {
            this.props.clear()
        }
    }

    hideModal(e) {

        this.setState({
            modal: null,
        });
        if (typeof e !== 'undefined') {
            e.stopPropagation();
        }
        this.context.setModalData && this.context.setModalData(null);
        this.props.clear();
        this.context.setHasItemModal && this.context.setHasItemModal(null);
    }

    onService = (id) => {
        let serviceFind = this.props.availableServiceAll.find((item)=>item.id===id);
        let isNotAvailable = serviceFind?serviceFind.not_available_on_tariff:false;
        if(isNotAvailable){
            this.setState({modal: 'not-available-service'})
        }else{
            this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), this.props.item, true);
        }

    };

    offService = (id) => {
        this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), this.props.item, false, !!this.props.clearAfter); //if my service, for prevent deleting card before we don't close modal window
    };

    toggleRegion = (region) => {
        const path = getPathWithoutRegion(this.context.locationPath);

        location.href = '/' + region + '/' + path;
    };

    renderModal() {
        let content = "";

        let formsSettings = this.props.formsSettings;
        let isOn = this.props.item.isOn;
        switch (this.state.modal) {
            case 'change-rate':
                content = this.props.isGuest ? <ChangeRate
                    onSuccess={this.showModal('change-rate-ok')}
                    item={this.props.item}
                    hideModal={this.hideModal}
                    title={(isOn ? "Отключить" : "Подключить")+" услугу «" + this.props.item.name + "»"}
                    type={"service"}
                    forgetLinkClick={this.showModal('recovery')}
                /> : <ChangeRateConfirmation type={"service"}
                                            item={{
                                                name: this.props.item.name,
                                                isOn,
                                                info: [{
                                                    items: isOn ? [
                                                    {
                                                        title: "Дата отключения",
                                                        value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                    }
                                                    ] : [
                                                        {
                                                            title: "Абонентская плата",
                                                            value: this.props.item.subscription_fee || 0,
                                                            measure: "₽"
                                                        },
                                                        {
                                                            title: "Стоимость подключения",
                                                            value: this.props.item.activation_price || 0,
                                                            measure: "₽"
                                                        },
                                                        {
                                                            title: "Дата подключения",
                                                            value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                        }
                                                    ]
                                                }]
                                            }}
                                            notice={isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_form_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_form_description}
                                            onClick={() => {isOn ? this.offService(this.props.item.id_billing) : this.onService(this.props.item.id_billing)}}
                                            onCancel={this.hideModal}
                />;
                break;
            case 'change-rate-ok':
                if (this.props.isGuest) {
                    isOn = true;
                }
                content = <ChangeRateOk item={this.props.item}
                                        title={!isOn 
                                            ?   formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_title
                                            :   formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_title}
                                        description={!isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_description}
                                        type="service"
                                        onClick={this.hideModal}
                />;
                break;
            case 'change-rate-error':
                if (this.props.isGuest) {
                    isOn = true;
                }
                content = <ChangeRateOk type="error"
                                        item={this.props.item}
                                        btnText={this.props.toggleServiceError.indexOf('Регион') !== -1 ? 'Сменить регион' : 'ОК'}
                                        title={this.props.toggleServiceError === "error" 
                                            ?   (isOn 
                                                ?   formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_title 
                                                :   formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_title) 
                                            :   this.props.toggleServiceError}
                                        description={this.props.toggleServiceError === "error" ? (isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_description) : ""}
                                        onClick={() => {
                                            if(this.props.toggleServiceError.indexOf('Регион') !== -1) {
                                                this.toggleRegion(this.props.client.clientInfo.region.alias);
                                            } else {
                                                this.hideModal();
                                            }
                                        }}/>;
                break;
            case 'not-available-service':

                content = <ChangeRateOk type="error"
                                        item={this.props.item}
                                        btnText={'ОК'}
                                        title={'Невозможно подключить услугу'}
                                        description={<p>Услуга <b>"{this.props.item.name}"</b> не предоставляется на Вашем тарифе</p>}
                                        onClick={() => {
                                            this.hideModal();
                                        }}/>;
                break;
            case 'recovery':
                content = <RecoveryForm recover={this.props.recover} error={this.props.recoveryError}/>;
                break;
            case 'recovery-ok':
                content = <ChangeRateOk type={"recovery"} onClick={this.hideModal}/>;
                break;
            default:
                return null;
        }
        return <Modal close={this.hideModal}>{content}</Modal>
    }

    renderSwitch = () => {
        let item = this.props.item;

        // поля и чекбоксы в админке
        let {
            activation_contact_center,  // Подключение с помощью КЦ
            switch_opio,                // Переход возможен через ОПиО
            sms_activation_info,        // Sms Activation Info
            sms_activation_desc,        // Sms Activation Desc
            ussd_activation_code,       // Ussd Activation Code
            ussd_activation_desc,       // Ussd Activation Desc
            quick_activation,           // Бытрая активация
            switch_by_account,          // Переход возможен через ЛК
            isOn,
        } = item;

        const showSwitcher = (activation_contact_center || switch_opio || (sms_activation_info && sms_activation_desc) || (ussd_activation_code && ussd_activation_desc))
        // отображение чекбокса в авторизованной зоне
        const showSwitcherB2cAuth = switch_by_account;
        // отображение чекбокса в НЕ авторизованной зоне
        const showSwitcherB2cNotAuth = (((  activation_contact_center || switch_opio) && 
                                            (sms_activation_info && sms_activation_desc) || 
                                            (ussd_activation_code && ussd_activation_desc) || 
                                            switch_by_account || 
                                            quick_activation
                                        ) || 
                                        switch_by_account ||
                                        quick_activation || 
                                        (sms_activation_info && sms_activation_desc) || (ussd_activation_code && ussd_activation_desc))

        if (this.context.isB2b) {
            return (
                <div
                    onClick={this.showModal('change-rate')}
                    style={{visibility: showSwitcher ? 'visible' : 'hidden'}}>
                    <SwitcherInput />
                </div>
            )
        }

        const curentPath = this.context.locationPath.split("/")[this.context.locationPath.split("/").length - 1];
        // отвечает за отображение свитчера
        const switchedServices = (isOn !== undefined && curentPath === 'services') 
                                    // для авторизованной зоны
                               ? (!item.is_package && item.id_billing)
                                    // НЕ для авторизованной зоны
                               : (this.props.isGuest 
                                    ? showSwitcherB2cNotAuth 
                                    : showSwitcherB2cAuth
                                );

        // для авторизованной зоны
        if (!this.props.isGuest) {
            return (
                <div
                    onClick={(item.canDisable) ? this.showModal('change-rate') : () => {}}
                    style={{visibility: switchedServices ? 'visible' : 'hidden'}}>
                    <SwitcherInput
                        readOnly={!item.canDisable}
                        id={"switcher-service-" + item.id}
                        name={false}
                        value={item.isOn || !!this.state.modal} />
                </div>
            )
        }

        // для не авторизованной зоны
        if (this.props.isGuest) {
            return (
                <div
                    onClick={item.canSwitch ? this.showModal('change-rate') : () => {}}
                    style={{visibility: switchedServices ? 'visible' : 'hidden'}}>
                    <SwitcherInput
                        readOnly={!item.canSwitch}
                        id={"switcher-service-" + item.id}
                        name={false}
                        value={item.isOn || !!this.state.modal} />
                </div>
            )
        }
        
    }

    render() {

        let item = this.props.item;
        let selectedRegion = this.context.selectedRegion;

        let url = (this.context.isLk ? mainUrl : "/") + selectedRegion.alias;
        if (this.context.isB2b) {
            const parentRoute = this.context.appRoutes.find((item) => item.sys_name === 'servicesB2BRoute');
            url += parentRoute ? parentRoute.route : '/services'
        } else {
            url += '/services'
        }
        url += '/' + item.slug;

        let fetchBefore = this.context.fetchBefore;
        let currentPage = this.props.currentPage;

        let period = item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 1) : '';

        if (period === 'подключение' || item.subscription_fee === 0 && item.activation_price > 0 || item.subscription_fee === 0 && item.activation_price === 0) {
            period = 'подкл.';
        }

        let itemDate = item && item.date;
        
        // выводит дату
        // let date = (new Date(itemDate)).toLocaleDateString('ru')
        // выводит дату со временем
        let options = {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"};
        let date = (new Date(itemDate)).toLocaleString('ru', options);

        const curentPath = this.context.locationPath.split("/")[this.context.locationPath.split("/").length - 1];

        return (
            <a 
                className="b-services-group__item"
                href={url}
                // onClick={item.slug 
                //     ?   (this.context.isLk 
                //         ?   () => {location.href = url} 
                //         :   fetchBefore(url, item.slug, currentPage ? currentPage : null)) 
                //     :   () => {}
                // }
            >
                <div className="b-options-table b-options-table b-options-table_adapt_default">
                    <div className="b-options-table__wrapper">
                        <div className="b-options-table__items-area">
                            <div className={"b-options-table__item"+(this.props.isAdvert ? " b-options-table__item_gray" : "")}>
                                {this.renderSwitch()}
                                <div className="b-options-table__item-elem b-options-table__item-elem_text">
                                    <div className="b-options-table__text-title">{item.name}</div>
                                    {item.canDisable === false ? <div className="b-options-table__text-subtitle">Услугу невозможно отключить</div> : null}
                                    <div className="b-options-table__text-inner" dangerouslySetInnerHTML={{__html: item.short_description}}/>

                                    {itemDate && (
                                        <div className="service__date-activation">Дата подключения:&nbsp;{date}</div>
                                    )}
                                </div>
                                {item.subscription_fee != 0 || !this.props.hideZeroPrice ? <div className="b-options-table__item-elem b-options-table__item-elem_price">
                                    {
                                        item.subscription_fee > 0 && item.activation_price > 0 ?
                                            <React.Fragment>
                                                <div className="b-options-table__price">{item.is_package ? 'от ' : ''}
                                                    {item.subscription_fee}
                                                    {/*<sup className="b-options-table__sup">*/}
                                                    {/*    <span className="u-rub"></span>*/}
                                                    {/*</sup>*/}
                                                </div>
                                                <div className="b-options-table__period">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}{period ? '/' + period : null}</div>
                                            </React.Fragment>
                                        : item.subscription_fee > 0 && item.activation_price === null || item.subscription_fee > 0 && item.activation_price === 0 ?
                                            <React.Fragment>
                                                <div className="b-options-table__price">{item.is_package ? 'от ' : ''}
                                                    {item.subscription_fee}
                                                    {/*<sup className="b-options-table__sup">*/}
                                                    {/*    <span className="u-rub"></span>*/}
                                                    {/*</sup>*/}
                                                </div>
                                                <div className="b-options-table__period">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}{period ? '/' + period : null}</div>
                                            </React.Fragment>
                                        : item.subscription_fee === 0 && item.activation_price > 0 ?
                                            <React.Fragment>
                                                <div className="b-options-table__price">{item.is_package ? 'от ' : ''}
                                                    {item.activation_price}
                                                    {/*<sup className="b-options-table__sup">*/}
                                                    {/*    <span className="u-rub"></span>*/}
                                                    {/*</sup>*/}
                                                </div>
                                                <div className="b-options-table__period">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}{period ? '/' + period : null}</div>
                                            </React.Fragment>
                                        : item.subscription_fee === 0 && item.activation_price === null ?
                                            <React.Fragment>
                                                <div className="b-options-table__price">{item.is_package ? 'от ' : ''}
                                                    {item.subscription_fee}
                                                    {/*<sup className="b-options-table__sup">*/}
                                                    {/*    <span className="u-rub"></span>*/}
                                                    {/*</sup>*/}
                                                </div>
                                                <div className="b-options-table__period">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}{period ? '/' + period : null}</div>
                                            </React.Fragment>
                                        : item.subscription_fee === 0 && item.activation_price === 0 ?
                                            <React.Fragment>
                                                <div className="b-options-table__price">{item.is_package ? 'от ' : ''}
                                                    {item.activation_price}
                                                    {/*<sup className="b-options-table__sup">*/}
                                                    {/*    <span className="u-rub"></span>*/}
                                                    {/*</sup>*/}
                                                </div>
                                                <div className="b-options-table__period">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}{period ? '/' + period : null}</div>
                                            </React.Fragment>
                                        : !this.props.isGuest ?
                                            <React.Fragment>
                                                <div className="b-options-table__price">{item.is_package ? 'от ' : ''}{item.subscription_fee}
                                                    <sup className="b-options-table__sup">
                                                        <span className="u-rub"></span>
                                                    </sup>
                                                </div>
                                                <div className="b-options-table__period">{item.writeOffPeriod ? getMeasure(item.writeOffPeriod.measure, 0) : ''}{period ? '/' + period : null}</div>
                                            </React.Fragment>
                                        : null
                                    }
                                    {item.slug 
                                        ?   <a 
                                                href={url} 
                                                className="b-options-table__more-info-btn" 
                                                // onClick={this.context.isLk ? () => {} : fetchBefore(url, item.slug, currentPage ? currentPage : null)}
                                            >
                                                Подробнее об услуге
                                            </a> 
                                        :   null
                                    }
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </a>
        )
    }
}

export default connect(
    state => ({
        client: state.client,
        isGuest: state.client.isGuest,
        availableServiceAll: state.client.availableServicesAll,
        availableService: state.client.availableServices,
        services: state.client.services,
        recoveryError: state.client.recoveryFormError || state.client.fatalError,
        successMessageTitle: state.client.successMessageTitle,
        toggleServiceMessage: state.client.toggleServiceMessage,
        toggleServiceError: state.client.toggleServiceError,
        formsSettings: state.client.formsSettings
    }),
    (dispatch) => {
        return {
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState()),
            toggleService: (data, service, on, clearAfter) => dispatch(toggleServices(data, service, on, clearAfter))
        }
    }
)(RateServiceListItem);