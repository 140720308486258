const initState = {};

export function settings(state = initState, action) {
    switch (action.type) {
        case 'SETTINGS_LIST_SUCCESS':
            return {...state, ...action.data};
        case 'SETTINGS_LIST_INDEX_SUCCESS':
            return {...state, settingsIndex:action.data};
    }
    return state;
}