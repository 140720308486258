import {Helmet} from "react-helmet";
import React, {Component, useEffect} from "react";
import { connect } from "react-redux";
import {tariffsList} from "../../actions/tariffs";
import {getMnpBonuses, infoBlocksList} from "../../actions/infoBlocks";
import {checkOperator, checkRadioValue, clearActivationData, clearOperatorData} from "../../actions/activation";
import {formsList, submitMnpForm} from "../../actions/forms";
import {mainVideo} from "../../actions/media";
import {newsListShort} from "../../actions/news";
import {onSubscriptionIn, onSubscriptionOut} from "../../actions/support";
import PropTypes from "prop-types";
import {getPage} from "../../actions/routes";


class CookiePolicyContainer extends Component{

    constructor(props) {
        super(props);

        this.state = {
            content: ''
        }

    }


    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBeforeAfterSubmit: PropTypes.func,
        constructorParams: PropTypes.object,
    };

    componentDidMount() {
        if(this.context.selectedRegion.id){
            this.props.getPage('cookiesPolicyRoute', this.context.selectedRegion.id)
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.support && this.props.support.cookiesPolicyRoute && prevState.content!==this.props.support.cookiesPolicyRoute.content){
            this.setState({content: this.props.support.cookiesPolicyRoute.content})
        }
    }


   render(){
       const {content} = this.state;
       const selectRegion = this.context.selectedRegion?this.context.selectedRegion.id:null;
       let routeSeo = this.props.routes?this.props.routes.list.find(x => x.sys_name === 'cookiesPolicyRoute'):null;
       const currentMetaTags = routeSeo && selectRegion && routeSeo.seoRegions.find(i => i.region_id === selectRegion);

       return (
           <main className="page__main-holder" role="main">
               <Helmet
                   title={currentMetaTags ? currentMetaTags.meta_title : ""}
                   meta={[
                       {
                           "name": "description",
                           "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                       },
                       { "name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : "" }
                   ]}
                   htmlAttributes={{"class": "pp-mod_internet"}}
               />
               <div className="page__container" dangerouslySetInnerHTML={{__html: content}}/>
           </main>
       )
   }
}

export default connect(
    state => ({
        routes: state.routes,
        support: state.support
    }),
    (dispatch) => {
        return {
            getPage: (page, region) => dispatch(getPage(page, region)),
        }
    }

)(CookiePolicyContainer);