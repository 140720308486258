import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ModalNew from "../../common/ModalNew";
import TariffConnectionNew from "../../Constructor/TariffConnectionNew";
import {tariffsType} from "../../../tools/tools";

class TariffConnectionModalNew extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    static contextTypes = {
        constructorParams: PropTypes.object,
    };

    render() {

        let {
            tariffName,
            recObj,
            tariffConnectionValue,
            userPhone,
            smsCount,
            constructor,
            user,
            isTariffConnection,
            checkBalance
        } = this.props;

        let tariffSum = recObj && recObj.abon;
        let discountSum = recObj && recObj.abon_discount;
        let discount = recObj && recObj.discount;
        let savePrice = recObj && recObj.save_price;

        let authPhone = user && user.username === undefined ? '' : user.username.replace(/\D/gim, '');
        let numberEdit = userPhone.replace(/\D/gim, '').replace(/^\d/, '');

        return <ModalNew className="u-modal u-modal_active u-modal-top tariff-connection-modal modal_new" close={this.props.close}>
            <div className="content">
                <h2 className="modal_title">Подключение тарифа <br/>«{tariffName}»</h2>
                {/* {tariffConnectionValue === 'quick' && discountSum &&  */}
                {authPhone === numberEdit && tariffConnectionValue === tariffsType.find(item=>item.id==='change_tariff').type && discountSum &&
                    (<div className="modal_discount_wrapper">
                        {!savePrice && <div className="modal_discount_sum">{tariffSum}</div>}
                        <div className="modal_discount_persent">{savePrice ? 'ПЕРВЫЙ МЕСЯЦ' : `-${discount}%`}</div>
                    </div>)
                }
                
                {/* <div className="modal_price">за {tariffConnectionValue === 'quick' && discount ? discountSum : tariffSum} ₽/месяц</div> */}
                <div className="modal_price">за {authPhone === numberEdit && tariffConnectionValue === tariffsType.find(item=>item.id==='change_tariff').type && discountSum ? discountSum : tariffSum} ₽/месяц</div>
            </div>

            <TariffConnectionNew
                tariffName={tariffName}
                userPhone={userPhone}
                close={this.props.close}
                openSuccess={this.props.openSuccess}
                settings={this.props.settings}
                smsCount={smsCount}
                constructor={constructor}
                checkBalance={checkBalance}
                isTariffConnection={isTariffConnection}
            />
        </ModalNew>
    }
}

export default connect(
    state => ({

    }),
    (dispatch) => {
        return {

        }
    }
)(TariffConnectionModalNew);