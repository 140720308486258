import React, { Component } from 'react';
import {connect} from "react-redux";
import { Helmet } from "react-helmet";
import {getPage} from "../../actions/routes";
import PropTypes from "prop-types";

class CookieAgreementContainer extends Component {

    constructor(props) {

        super(props);

        this.state = {
            content: ''
        };
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        fetchBeforeAfterSubmit: PropTypes.func,
        constructorParams: PropTypes.object,
    };

    componentDidMount() {
        if(this.context.selectedRegion.id){
            this.props.getPage('cookiesAgreementRoute', this.context.selectedRegion.id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.support && this.props.support.cookiesAgreementRoute && prevState.content!==this.props.support.cookiesAgreementRoute.content){
            this.setState({content: this.props.support.cookiesAgreementRoute.content})
        }
    }


    componentWillMount() {

    }

    render() {
        const {content} = this.state;
        const selectRegion = this.context.selectedRegion?this.context.selectedRegion.id:null;
        let routeSeo = this.props.routes?this.props.routes.list.find(x => x.sys_name === 'policyAgreementRoute'):null;
        const currentMetaTags = routeSeo && selectRegion && routeSeo.seoRegions.find(i => i.region_id === selectRegion);

        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        { "name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : "" }
                    ]}
                    htmlAttributes={{"class": "pp-mod_internet"}}
                />
                <div className="page__container" dangerouslySetInnerHTML={{__html: content}} />
            </main>
        )
    }
}

export default connect(
    state => ({
        support: state.support,
        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getPage: (page, region) => dispatch(getPage(page, region)),
        }
    }
)(CookieAgreementContainer);