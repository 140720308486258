import React, { Component } from 'react';
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types";
import {servicesView} from "../../actions/services";
import RatesBreadcrumbs from "../../components/Rates/RatesBreadcrumps";
import SidebarHeadInfo from "../../components/common/SidebarHeadInfo";
import RatesPriceList from "../../components/Rates/RatesPriceList";
import RatesCollapsing from "../../components/Rates/RatesCollapsing";
import RateCooperation from "../../components/Rates/RateCooperation";
import ChangeRate from "../../components/Rates/ChangeRate";
import Modal from "../../components/common/Modal";
import RatesActivationMethods from "../../components/Rates/RatesActivationMethods";
import RatesPackageConditions from "../../components/Rates/RatesPackageConditions";
import RatesAdditionalServices from "../../components/Rates/RatesAdditionalServices";
import ChangeRateOk from "../../components/modals/ChangeRateOk";
import {Route} from "react-router-dom";
import Page404 from "../Page404";
import BBblock from "../../components/Services/BBblock";
import ChangeRateConfirmation from "../../components/modals/ChangeRateConfirmation";
import RecoveryForm from "../../components/Forms/RecoveryForm";
import {clearState, recovery, toggleServices, services} from "../../actions/client";
import {getPathWithoutRegion} from "../../tools/url-helper";

class RateService extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            modalService: null,
            service : null,

        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.renderModal = this.renderModal.bind(this);
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        setHeaderClassName: PropTypes.func,
        locationPath: PropTypes.string,
    };

    static getDerivedStateFromProps(props, state){
        if (props.successMessageTitle && "recovery" === state.modal) {
            return {...state, modal: "recovery-ok"}
        }
        if (props.toggleServiceError && state.modal === "change-rate") {
            return {...state, modal: "change-rate-error"}
        }
        if (props.toggleServiceMessage && state.modal === "change-rate") {
            return {...state, modal: "change-rate-ok"}
        }
        return state;
    }

    componentWillMount() {

        if (this.props.services.service.length === 0) {
            this.props.getService(this.props.match.params.slug, this.context.selectedRegion.id);
        }
        if (!this.props.isGuest && (!this.props.myServices || this.props.myServices.length === 0)) {
            this.props.getMyServices();
        }
    }

    componentDidMount() {

        if (!this.props.myServices || this.props.myServices.length === 0) {
            this.props.getMyServices();
        }

        if (!this.props.services.length) {
            this.props.getService(this.props.match.params.slug, this.context.selectedRegion.id);
        }
    }

    showModal(type, modalService = null) {
        return (e) => {
            if (typeof e !== 'undefined') {
                e.preventDefault();
            }
            this.setState({
                modal: type,
                modalService: modalService
            });
        }
    }

    hideModal() {
        this.setState({modal: null, modalService: null});
        this.props.clear();
    }

    onService = (id) => {
        let serviceFind = this.props.availableServiceAll.find((item)=>item.id===id);
        let isNotAvailable = serviceFind?serviceFind.not_available_on_tariff:false;
        if(isNotAvailable){
            this.setState({modal: 'not-available-service'})
        }else{
            this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), this.state.modalService ? this.state.modalService : this.state.service, true);
        }



    };

    offService = (id) => {
        this.props.toggleService(JSON.stringify({id: id, region_id: this.context.selectedRegion.billing_id}), this.state.modalService ? this.state.modalService : this.state.service, false);
    };

    toggleRegion = (region) => {
        const path = getPathWithoutRegion(this.context.locationPath);

        location.href = '/' + region + '/' + path;
    };

    renderModal() {

        let content = "";
        let service = this.state.modalService ? this.state.modalService : this.state.service;
        let title = "Подключить услугу «" + service.name + "»";
        let titleDeactivate = "Отключить услугу «" + service.name + "»";
        let titleCheck = "Проверка статуса для услуги «" + service.name + "»";
        let formsSettings = this.props.formsSettings;
        let isOn = service.isOn;


        switch (this.state.modal) {
            case 'change-rate':
            case 'change-rate-personal':
                content = this.props.isGuest ? <ChangeRate
                    onSuccess={this.showModal('change-rate-ok', this.state.modalService ? this.state.modalService : null)}
                    item={service}
                    hideModal={this.hideModal}
                    title={title}
                    type={"service"}
                    forgetLinkClick={this.showModal('recovery')}
                    value={this.state.modal === "change-rate-personal" ? "personal" : null}
                /> : <ChangeRateConfirmation type={"service"}
                                             item={{
                                                 name: service.name,
                                                 isOn,
                                                 info: [{
                                                     items: isOn ? [
                                                         {
                                                             title: "Дата отключения",
                                                             value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                         }
                                                     ] : [
                                                         {
                                                             title: "Абонентская плата",
                                                             value: service.subscription_fee || 0,
                                                             measure: "₽"
                                                         },
                                                         {
                                                             title: "Стоимость подключения",
                                                             value: service.activation_price || 0,
                                                             measure: "₽"
                                                         },
                                                         {
                                                             title: "Дата подключения",
                                                             value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                                         }
                                                     ]
                                                 }]
                                             }}
                                             notice={isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_form_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_form_description}
                                             onClick={() => {isOn ? this.offService(service.id_billing) : this.onService(service.id_billing)}}
                                             onCancel={this.hideModal}
                />;
                break;
            case 'change-rate-phone':
                content = <ChangeRate
                    onSuccess={this.showModal('change-rate-ok')}
                    item={service}
                    value={"phone"}
                    hideModal={this.hideModal}
                    title={title}
                    type={"service"}
                    forgetLinkClick={this.showModal('recovery')}
                    isGuest={this.props.isGuest}
                />;
                break;
            case 'change-rate-ok':
                content = <ChangeRateOk item={service}
                                        title={typeof isOn === 'undefined' || isOn === true ? formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_title : formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_title}
                                        description={!isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_description}
                                        type="service"
                                        onClick={this.hideModal}/>;
                break;
            case 'not-available-service':
                content = <ChangeRateOk type="error"
                                        item={service}
                                        btnText={'ОК'}
                                        title={'Невозможно подключить услугу'}
                                        description={<p>Услуга <b>"{service.name}"</b> не предоставляется на Вашем тарифе</p>}
                                        onClick={() => {
                                            this.hideModal();
                                        }}/>;
                break;
            case 'change-rate-error':
                content = <ChangeRateOk type="error"
                                        item={service}
                                        btnText={this.props.toggleServiceError.indexOf('Регион') !== -1 ? 'Сменить регион' : 'ОК'}
                                        title={this.props.toggleServiceError === "error" ? (isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_title : formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_title) : this.props.toggleServiceError}
                                        description={this.props.toggleServiceError === "error" ? (isOn ? formsSettings.service_off_form && formsSettings.service_off_form.service_off_unavailable_description : formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_description) : ""}
                                        onClick={() => {
                                            if(this.props.toggleServiceError.indexOf('Регион') !== -1) {
                                                this.toggleRegion(this.props.client.clientInfo.region.alias);
                                            } else {
                                                this.hideModal();
                                            }
                                        }}/>;
                break;
            case 'deactivate-rate-personal':
                content = <ChangeRate
                    onSuccess={this.showModal('change-rate-ok')}
                    item={service}
                    value={""}
                    hideModal={this.hideModal}
                    title={titleDeactivate}
                    disableQuick={true}
                    type={this.state.modal}
                />;
                break;
            case 'check-rate-personal':
                content = <ChangeRate
                    onSuccess={this.showModal('change-rate-ok')}
                    item={service}
                    value={""}
                    hideModal={this.hideModal}
                    title={titleCheck}
                    disableQuick={true}
                    type={this.state.modal}
                />;
                break;
            case 'recovery':
                content = <RecoveryForm recover={this.props.recover} error={this.props.recoveryError}/>;
                break;
            case 'recovery-ok':
                content = <ChangeRateOk type={"recovery"} onClick={this.hideModal}/>;
                break;
            default:
                return null;
        }
        return <Modal close={this.hideModal}>{content}</Modal>
    }

    render() {

        const { regions, location } = this.props;
        let selectedRegion = this.context.selectedRegion;

        this.isMine = false;

        const servicesProp = this.props.services?this.props.services.service:[];
        let service = servicesProp.find(x => x.slug === this.props.match.params.slug);


        this.state.service = service;
        if (typeof service === 'undefined') {
            if (typeof document !== 'undefined') {
                this.context.setHeaderClassName("page__header-holder");
            }

            return (
                (this.props.services.isOk || (this.props.services.isNotFound && !this.props.services.isOk) ) ? <Route component={Page404} />  : <main className="page__main-holder" role="main"/>
            )
        }

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + "/services",
                title: "Услуги",
            },
            {
                url: "/" + selectedRegion.alias + "/services/category/" + service.mainCategory.slug,
                title: service.mainCategory.title,
            },
            {
                url: null,
                title: service.name,
            }
        ];


        if (service.status_id === 2) {
            breadcrumbs.splice(1, 1, {
                url: "/" + selectedRegion.alias + "/services/archive",
                title: "Архив услуг"
            });
        }

        if (this.props.location.state) {
            if (this.props.location.state.title != null) {
                breadcrumbs.splice(0, 1, this.props.location.state);
            }
        }

        let servicesCooperation = service.serviceServiceCooperations;
        let tariffServiceCooperation = service.tariffServiceCooperations;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");


        return (
            <main className="page__main-holder" role="main">
                <Helmet
                    title={service.meta_title}
                    meta={[
                        {"name": "description", "content": service.meta_desc},
                        {"name": "keywords", "content": service.meta_keywords}
                    ]}
                >
                        <link rel="canonical" href={pageLink} />
                </Helmet>
                <div className="b-service-inner b-service-inner_adapt_default">
                    <div className="b-service-inner__service-header b-service-inner__service-header--mob">
                        <div className="b-inner-intro b-inner-intro_adapt_default">
                            <div className="b-inner-intro__wrapper">
                                <div className="b-inner-intro__inner page__container">
                                    <div className="b-inner-intro__header" />
                                    <div className={`b-inner-intro__main-area ${!this.props.isGuest ? 'dop-size' : ''}`} >
                                        <div className="b-inner-intro__main-area-wrapper">
                                            <RatesBreadcrumbs items={breadcrumbs} />
                                            <h1 className="b-inner-intro__title">Услуга «{service.name}»</h1>
                                            <div className="b-inner-intro__text" dangerouslySetInnerHTML={{__html: service.short_description}}/>
                                            {   service.base_service
                                                ?   ''
                                                :   <SidebarHeadInfo item={service} isMine={this.isMine} type={"service"} />
                                            }
                                            {   service.base_service
                                                ?   ''
                                                :   service.status_id === 1
                                                    ?   (service.switch_by_account && (!service.is_package && service.canSwitch && service.canDisable && service.id_billing))
                                                        ?   <div className="b-inner-intro__btn">
                                                                <a className="u-btn u-btn_default u-btn_adapt_default u-btn_mobile-responsive"
                                                                   href="#" onClick={this.showModal('change-rate')} >{service.isOn ? "Отключить" : "Подключить"}</a>
                                                            </div>
                                                        :   ''
                                                    :   <div className="b-inner-intro__btn">
                                                            <div className="b-inner-intro__add-info b-inner-intro__add-info_btnless">
                                                                Перемещен в архив
                                                            </div>
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page__container">
                        <div className="b-with-aside-area b-with-aside-area_adapt_default">
                            <RatesActivationMethods
                                isGuest={this.props.isGuest}
                                item={service} settings={this.props.settings}
                                showModal={this.showModal}
                            />
                            <div className="b-with-aside-area__column">
                                {service.additional_description
                                    ?   <div className="b-service-inner__content-area b-service-inner__content-area_top b-service-inner__content-area_pp_si-conf">
                                        <div className="b-content-area" dangerouslySetInnerHTML={{__html: service.additional_description}} />
                                    </div> : ''}
                                {
                                    service.base_service && service.description
                                        ?   <div className="b-service-inner__content-area b-service-inner__content-area_top b-service-inner__content-area_pp_si-conf">
                                                <div className="b-content-area">
                                                    {service.description}
                                                </div>
                                            </div>
                                        : service.is_package
                                            ?    <RatesPackageConditions items={service.packageServices} showModal={this.showModal} />
                                            :    service.info
                                                ?    <RatesPriceList blocks={service.info} />
                                                :    <BBblock content={service.additional_description} />
                                }
                                {
                                    service.info_dropdown
                                        ?    <RatesCollapsing info_dropdown={service.info_dropdown} />
                                        :    ''
                                }
                                {
                                    servicesCooperation.length > 0
                                        ?
                                        <React.Fragment>
                                            <div className="page__title-3 page__title-3_mobile-less page__title-3_socnmest-serv-inner">
                                                Совместимость с другими услугами
                                            </div>
                                            <RateCooperation items={servicesCooperation} section={"services"} />
                                        </React.Fragment>
                                        :   ''
                                }
                                {
                                    tariffServiceCooperation.length > 0
                                        ?   <React.Fragment>
                                                <div className="page__title-3 page__title-3_mobile-less page__title-3_socnmest-serv-inner">
                                                    Совместимость с тарифами
                                                </div>
                                                <RateCooperation items={tariffServiceCooperation} section={"tariffs"} />
                                            </React.Fragment>
                                        :   ''
                                }
                            </div>
                        </div>
                        {
                            service.randomServices.length > 0 && service && service.mainCategory && !service.mainCategory.hide_other
                                ?
                                <div className="b-service-inner__options-table-plates">
                                    <RatesAdditionalServices title={"Другие услуги в категории"} items={service.randomServices} />
                                </div>
                                :   ''
                        }
                    </div>
                </div>
                {this.renderModal()}
            </main>
        );
    }
}

export default connect(
    state => ({
        client: state.client,
        services: state.services,
        settings: state.settings,
        isGuest: state.client.isGuest,
        recoveryError: state.client.recoveryFormError || state.client.fatalError,
        successMessageTitle: state.client.successMessageTitle,
        toggleServiceMessage: state.client.toggleServiceMessage,
        toggleServiceError: state.client.toggleServiceError,
        myServices: state.client.services,
        formsSettings: state.client.formsSettings,
        regions: state.regions,
        availableServiceAll: state.client.availableServicesAll,
        availableService: state.client.availableServices,

    }),
    (dispatch) => {
        return {
            getService : (slug, regionId) => dispatch (servicesView(slug, regionId)),
            recover: (data) => dispatch(recovery(data)),
            clear: () => dispatch(clearState()),
            toggleService: (data, service, on) => dispatch(toggleServices(data, service, on)),
            getMyServices: () => dispatch(services())
        }
    }
)(RateService);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const RateServiceRequests = [
    {
        func: 'getService',
        args: [],
        object: 'services.service',
        checkField: 'slug',
        main: true,
        withRegion: true,
        objectReceived: false
    }
];

export const RateServiceLkRequests = [
    {
        func: 'getMyServices',
        args: [],
        object: 'client.services',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    }
];