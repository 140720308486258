export const clientApiUrl = 'https://api.motivtelecom.ru/v2';

export const clientLkApiUrl = 'https://api.motivtelecom.ru/client';
export const mediaUrl = 'https://media.motivtelecom.ru/';
export const imgUrl = 'https://img.motivtelecom.ru/';
export const constuctorUrl = 'https://svoi.motivtelecom.ru/';

export const lkUrl = 'https://lk.motivtelecom.ru/';
export const mainUrl = 'https://motivtelecom.ru/';
export const b2bUrl = 'https://business.motivtelecom.ru/';

export const esiaUrl = 'https://esia.motivtelecom.ru/';

export const cookieDomain = 'motivtelecom.ru';
export const cookiePrefix = 'prod';
export const isProduction = 'true';

export const oldRegions = {
    sverdlovsk_oblast: 'sverdlovsk-oblast',
    khmao: 'khanty-mansijskij-ao',
    yanao: 'yanao',
    kurgan_oblast: 'kurgan-oblast',
};

export const shopOffices = {
    sverdlovsk_oblast: '/sverdlovsk_oblast/motiv-on-map',
    khmao: '/khmao/motiv-on-map',
    yanao: '/yanao/motiv-on-map',
    kurgan_oblast: '/kurgan_oblast/motiv-on-map',
};
