import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import {routesList, routesVacanciesList, getPage} from "../../actions/routes";
import Breadcrumbs from "../../components/Vacancies/Breadcrumbs";
import InfoBlock from "../../components/common/InfoBlock";
import AsideMenu from "../../components/Vacancies/AsideMenu";
import {VacancyModalForm} from "../../components/modals/VacancyModal";
import {infoBlocksList} from "../../actions/infoBlocks";
import {vacanciesList} from "../../actions/vacancies";
import {clearVacancyRespondFormData} from "../../actions/forms";
import {
    FacebookShareButton,
    VKShareButton,
    WhatsappShareButton,
    TelegramShareButton
} from 'react-share';

class Career extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: null,
            linkBreadcrumbsVakansii: null
        };
    }

    componentWillMount() {
        let regionId = this.context && this.context.selectedRegion.id;
        if ( this.props.routes.list.length === 0 ) {
            this.props.getRoutes();
        }
        this.props.getInfoBlocks(regionId, 'about_company');
        if (this.props.infoBlocks.list.length === 0) {
            // this.props.getInfoBlocks(regionId, 'about_company');
        }
        if ( this.props.routesVacancies.list.length === 0 ){
            this.props.getRoutesVacancies();
        }
        this.props.getVacanciesList();

        if (!this.props.support.careerInMotivRoute) {
            this.props.getPage('careerInMotivRoute', this.context.selectedRegion.id)
        }
    }

    componentDidUpdate() {
        let modal = this.state.modal;
        if (typeof document !== 'undefined') {
            if (modal === "open" || modal === "success") {
                this.hideScroll();
            } else {
                this.showScroll();
            }
        }

        let vacancyFormOk = this.props.vacancyRespond && this.props.vacancyRespond.vacancyFormOk;
        if ( !!vacancyFormOk && modal === "open" ){
            this.setState({modal: "success"});
            this.props.clearData();
        }
    }

    componentWillReceiveProps() {
        if (this.props.routesVacancies){
            let routeVakansii = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.alias === 'vakansii');
            let linkBreadcrumbsVakansii = routeVakansii && routeVakansii.link;
            this.setState({linkBreadcrumbsVakansii: linkBreadcrumbsVakansii});
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
        setHeaderClassName: PropTypes.func
    };

    openModal = (modal) => {

        return (e) => {

            if (e) e.preventDefault();
            this.setState({
                modal: modal
            });
        }
    };

    hideScroll = () =>{
        let scrollTop = 0;
        if (typeof document != "undefined") {
            document.body.classList.add("no-scroll");
            scrollTop = window.pageYOffset;
            document.body.style.position = "fixed";
            document.body.style.top = -scrollTop + 'px';
        }
    };

    closeModal = (e) => {
        if (e) e.preventDefault();

        this.setState({modal: "close"});
        this.showScroll();
    };

    showScroll = () => {

        if (typeof document != "undefined") {
            document.body.classList.remove('no-scroll');
            document.body.style.position = '';
            document.body.style.top = '';
            window.scroll(0, this.scrollTop);
        }
    };

    renderModal = () => {
        let selectedRegion = this.context ? this.context.selectedRegion : null;
        let infoBlocks = this.props.infoBlocks ? this.props.infoBlocks.list : null;
        let regionsList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.regions : [];
        let vacanciesList = this.props.vacanciesList ? this.props.vacanciesList.vacancies.vacancies : [];
        switch (this.state.modal) {
            case "open":
                return <VacancyModalForm close={ this.closeModal }
                                         regions={regionsList}
                                         vacancyList={vacanciesList}
                                         selectedRegion={selectedRegion}
                />;
            case "success":
                return <InfoBlock type={'about_modal_success'} data={infoBlocks} section={'about_modal_success'} close={ this.closeModal } />;
            default:
                return "";
        }
    };

    render() {
        const { regions, location } = this.props;
        if (typeof document !== 'undefined') this.context.setHeaderClassName("page__header-holder career");
        let infoBlocks = this.props.infoBlocks.list;
        let selectedRegion = this.context.selectedRegion;
        let route = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "careerInMotivRoute");
        let routeAliasVacancy = this.props.routesVacancies && this.props.routesVacancies.list.find(x => x.sys_name === "vakansiiRoute");

        let breadcrumbs = [
            {
                url: "/" + selectedRegion.alias + this.state.linkBreadcrumbsVakansii,
                title: "Работа в МОТИВ"
            },
            {
                url: "",
                title: "Карьера в МОТИВ"
            }
        ];
        let shareUrl = typeof window === 'undefined' ? "#" : window.location.href;
        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        const content = this.props.support && this.props.support.careerInMotivRoute && this.props.support.careerInMotivRoute.content

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'careerInMotivRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        return (
            <main className="page__main-holder page-vacancy page-career" role="main">
                <Helmet
                    htmlAttributes={{"class": "pp-mod_support_spam"}}
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>
                
                <div className="page__container">
                    <div className="page__cols">
                        <div className="page__main-col page__main-col_no-mb">
                            <div className="b-intro-simple b-intro-simple_adapt_default">
                                <div className="b-intro-simple__breadcrumbs">
                                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                                </div>
                            </div>

                            <InfoBlock type={'about_career_video'} data={infoBlocks} section={'about_career_video'}/>

                            {/* <InfoBlock type={'about_career_text_block'} data={infoBlocks} section={'about_career_text_block'}/> */}
                            <div 
                                className="career-description-txt"
                                dangerouslySetInnerHTML={{__html: content}}
                            />


                            <div className="page__content-block">
                                <InfoBlock type={'about_send_cv'} data={infoBlocks} section={'about_send_cv'} openModal={ this.openModal } />
                            </div>

                            <div className="page__vacancy-social">
                                <span className="vacancy-social-title">Мы ждем тебя в нашей команде!</span>
                                <div className="share-buttons__default share-buttons__top">
                                    <FacebookShareButton url={shareUrl} quote="Карьера в МОТИВ" />
                                    <VKShareButton url={shareUrl} title="Карьера в МОТИВ" />
                                    <WhatsappShareButton url={shareUrl} title="Карьера в МОТИВ" />
                                    <TelegramShareButton url={shareUrl} title="Карьера в МОТИВ" />
                                </div>
                            </div>
                        </div>

                        <div className="page__right-aside about-aside-menu">
                            <div className="page__aside-item page__aside-item_menu">
                                {routeAliasVacancy ? 
                                    <AsideMenu
                                        flag={ routeAliasVacancy.sys_name }
                                        flagSubMenu={ route.sys_name }
                                        route={ this.props.routesVacancies }
                                        customTitleMenu={'Работа в Мотив'}
                                    /> :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    state => ({
        routes: state.routes,
        routesVacancies: state.routesVacancies,
        infoBlocks: state.infoBlocks || [],
        vacanciesList: state.vacancies || [],
        vacancyRespond: state.forms || [],
        regions: state.regions,
        support: state.support,
    }),
    (dispatch) => {
        return {
            getRoutes: () => dispatch(routesList()),
            getRoutesVacancies: () => dispatch(routesVacanciesList()),
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getVacanciesList: () => dispatch(vacanciesList()),
            clearData: () => dispatch(clearVacancyRespondFormData()),
            getPage: (page, region) => dispatch(getPage(page, region))
        }
    }
)(Career);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const CareerRequests = [
    {
        func: 'getRoutes',
        args: [],
        object: 'routes.list',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false,
    },
    {
        func: 'getInfoBlocks',
        args: ['about_company'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "about_vacancy_list_banner"},
            {type: "about_career_video"},
            {type: "about_career_text_block"},
            {type: "about_social_fb"},
            {type: "about_social_vk"},
            {type: "about_social_ok"},
            {type: "about_send_cv"},
            {type: "about_modal_success"},
        ]
    },
];