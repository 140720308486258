import React, { Component } from 'react';
import PropTypes from "prop-types";
import Icon from './Icon';
import LinkResultSearch from "../common/LinkResultSearch";
import { imgUrl, b2bUrl, mainUrl, lkUrl } from "../../tools/config";
import { declOfNum, insertMark } from "../../tools/tools";

class SearchResult extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {}
    }

    static contextTypes = {
        selectedRegion: PropTypes.object,
        locationPath: PropTypes.string,
        isB2b: PropTypes.bool,
    };

    componentDidUpdate(prevProps, prevState) {
        let {
            stateListFilters,
            currentFilterAction,
            stateSearchInput,
        } = this.props

        // если фильров всего один, то выбираем его
        if (stateListFilters !== prevProps.stateListFilters && stateListFilters.length === 1) {
            currentFilterAction(stateListFilters[0].slug);

            // при первой загрузке страницы делаем запрос на поиск
            if ((stateListFilters[0] && stateListFilters[0].slug) !== (prevProps.stateListFilters.length && prevProps.stateListFilters[0].slug)) {
                // очистка результатов
                this.props.clearSearchPageAction();
                this.props.clearSearchResultPaginationAction();
    
                // запускаем поиск
                const website = this.props.activeTab === 0 ? 'b2c' : 'b2b';
                this.props.searchPageAction(website, stateSearchInput, this.context.selectedRegion.id, '', stateListFilters[0].slug);
            }
        }

        // если фильров больше одного, то выбираем первый (ВСЕ)
        if ((stateSearchInput !== prevProps.stateSearchInput) && (stateListFilters !== (prevProps.stateListFilters && stateListFilters.length > 1))) {
            currentFilterAction('');
        }

        // при переключении табов, если фильров больше одного, то выбираем первый (ВСЕ)
        if ((this.props.activeTab !== prevProps.activeTab) && (stateListFilters !== (prevProps.stateListFilters && stateListFilters.length > 1))) {
            currentFilterAction('');
        }
    }

    // по клику на фильтры
    onActiveFilter = (data) => {
        // записываем выбранный фильтр
        this.props.currentFilterAction(data);

        // очистка результатов
        this.props.clearSearchPageAction();
        this.props.clearSearchResultPaginationAction();

        // запускаем поиск
        const website = this.props.activeTab === 0 ? 'b2c' : 'b2b';
        this.props.searchPageAction(website, this.props.stateSearchInput, this.context.selectedRegion.id, '', data);
        this.props.listFiltersAction(website, this.props.stateSearchInput, this.context.selectedRegion.id);
    }

    // подставляет нужный текст в информер
    switchTextRegions = (data) => {
        switch (data) {
            case 66: return 'Свердловской области';
            case 45: return 'Курганскойской области';
            case 86: return 'Ханты-Мансийском автономном округе - Югра';
            case 89: return 'Ямало-Ненецком автономном округе';
        
            default: return 'Не известно';
        }
    }

    // для подгрузки контента
    handlerChangeNumPage = () => {
        // увеличиваем счетчик для номера страницы
        this.props.searchResultPaginationAction();

        // запускаем поиск
        const website = this.props.activeTab === 0 ? 'b2c' : 'b2b';
        this.props.searchPageAction(website, this.props.stateSearchInput, this.context.selectedRegion.id, this.props.stateSearchResultPagination, this.props.stateCurrentFilter);
        this.props.listFiltersAction(website, this.props.stateSearchInput, this.context.selectedRegion.id);
    }

    // скролл к элементу
    scrollToAnchorName = (hash) => {
        // находим нужный элемент по id
        let target = document.getElementById(hash);

        setTimeout(() => {
            if (target) {
                let rect = target.getBoundingClientRect();
                window.scrollTo((document.documentElement.scrollLeft || 0),
                    rect.top + (document.documentElement.scrollTop || 0)
                    - (document.documentElement.clientTop || 0));
            }
        }, 300);
    }  

    // когда подгруженные елементы заканчиваются, мы загружаем первую пачку результатов
    onEndChangeNumPage = () => {
        // очистка результатов
        this.props.clearSearchPageAction();
        this.props.clearSearchResultPaginationAction();

         // запускаем поиск
        const website = this.props.activeTab === 0 ? 'b2c' : 'b2b';
        this.props.searchPageAction(website, this.props.stateSearchInput, this.context.selectedRegion.id, '', this.props.stateCurrentFilter);
        this.props.listFiltersAction(website, this.props.stateSearchInput, this.context.selectedRegion.id);
        this.scrollToAnchorName("terget_search_result");
    } 

    render() {
        const {
            stateSearchPage,
            stateSearchPagePagination,
            stateListFilters,
            stateCurrentFilter,
            stateSearchInput,
        } = this.props;


        // определяем еслить ли найденные элементы в выбранном регилоне
        const arrAllRegions = [];
        stateSearchPage.forEach(e => {
            arrAllRegions.push(...e.regions);
        });
        const showErrorGerion = arrAllRegions.some((i) => i == this.context.selectedRegion.id);

        // склоняет окончания слов
        const preReclOfNum = declOfNum(stateSearchPagePagination && +stateSearchPagePagination.totalCount, ['результат', 'результата', 'результатов']);

        // склоняет окончания слов
        const preReclOfNum2 = declOfNum(stateSearchPagePagination && +stateSearchPagePagination.totalCount, ['Найден', 'Найдено', 'Найдено']);


        // определяет количество подгруженных элементов по отношению к максимальному количеству
        const endResult = +stateSearchPage.length >= (stateSearchPagePagination && +stateSearchPagePagination.totalCount);

        return (
            <div className='search_result'>
                <div className='search_result__wrap_bad_result'>
                    <div
                        className={`search_result__item_search_bad_result ${(stateSearchPage && stateSearchPage.length < 1) ? 'active' : ''}`}
                    >
                        <div className='search_result__item_search_bad_result_wrap_img'>
                            <img src={`${require('../../../images/icons/sad-emoticon.svg')}`} alt="Изображение" />
                        </div>

                        <div className='search_result__item_search_bad_result_text'>
                            По вашему запросу ничего не найдено.
                        </div>
                    </div>
                        

                    {!showErrorGerion && stateSearchPage && stateSearchPage.length > 0
                        && (
                            <div className='search_result__item_search_bad_result_region'>
                                <div className='search_result__item_search_bad_result_wrap_img'>
                                    <img src={`${require('../../../images/icons/sad-emoticon.svg')}`} alt="Изображение" />
                                </div>

                                <div className='search_result__item_search_bad_result_text'>
                                    По вашему запросу в {this.switchTextRegions(this.context.selectedRegion.id)} ничего не найдено.
                                </div>
                            </div>
                        )}
                </div>

                {stateSearchPagePagination && !!+stateSearchPagePagination.totalCount
                    && (
                        <div className='search_result__title_result'>
                            {preReclOfNum2} {stateSearchPagePagination && +stateSearchPagePagination.totalCount}
                            {' '}
                            {!showErrorGerion 
                                ? `${preReclOfNum} в других регионах:`
                                : `${preReclOfNum}`
                            }
                        </div>
                    )}

                {stateListFilters.length > 0
                    && (
                        <div className='search_result__wrap_tabs'>
                            <div className='search_result__tabs'>
                                {stateListFilters.length > 1
                                    && (
                                        <div
                                            className={`search_result__item_tabs ${'' === stateCurrentFilter ? 'active' : ''}`}
                                            onClick={() => this.onActiveFilter('')}
                                        >
                                            Все
                                        </div> 
                                    )}
                                
                                {stateListFilters.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`search_result__item_tabs ${item.slug === stateCurrentFilter ? 'active' : ''}`}
                                        onClick={() => this.onActiveFilter(item.slug)}
                                    >
                                        {item.title}
                                    </div> 
                                ))}
                            </div> 
                        </div>
                    )}

                {stateSearchPage && stateSearchPage.length > 0
                    && (
                        <div className='search_result__wrap_all_items_search' id="terget_search_result">
                            {stateSearchPage && stateSearchPage.map((item, index) => (
                                <div
                                    key={index}
                                    className='search_result__item_search'
                                >
                                    <div className='search_result__item_search_left_part'>
                                        <div className='search_result__item_search_title'>
                                            {index + 1}.
                                        </div>
                                    </div>

                                    <div className='search_result__item_search_right_part'>
                                        <div className='search_result__item_search_badge'>
                                            {item.entity.title}
                                        </div>

                                        <LinkResultSearch
                                            preTo={`${item.is_lk ? lkUrl : (this.props.activeTab === 0 ? mainUrl : b2bUrl)}`}
                                            arrRegions={item.regions}
                                            to={item.slug}
                                        >
                                            <div className='search_result__item_search_title'>
                                                {item.title && insertMark(item.title, stateSearchInput)}
                                            </div>
                                        </LinkResultSearch>

                                        <LinkResultSearch
                                            preTo={`${item.is_lk ? lkUrl : (this.props.activeTab === 0 ? mainUrl : b2bUrl)}`}
                                            arrRegions={item.regions}
                                            to={item.slug}
                                        >
                                            <div className='search_result__item_search_description'>
                                                {item.content_search && insertMark(item.content_search, stateSearchInput)}
                                            </div>
                                        </LinkResultSearch>

                                        <div className='search_result__item_search_wrap_all_img_and_more'>
                                            <div className='search_result__item_search_wrap_all_img'>
                                                {item.image
                                                    && (
                                                        <div className='search_result__item_search_wrap_img'>
                                                            <img src={imgUrl + item.image} alt="Изображение" />
                                                        </div>
                                                    )}
                                                
                                                {/* <div className='search_result__item_search_wrap_img'>
                                                    <img src={`${require('../../../images/content/other/search-2.jpg')}`} alt="Изображение" />
                                                </div> */}

                                                {/* <div className='search_result__item_search_wrap_more'>
                                                    <div className='search_result__item_search_more'>
                                                        + еще (10)
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            
                            {stateSearchPagePagination && +stateSearchPagePagination.totalCount > 10
                                && (
                                    <div className="search_result__wrap_btn_more">
                                        <div
                                            className="more-news-block"
                                            onClick={endResult ? () => this.onEndChangeNumPage() : () => this.handlerChangeNumPage()}
                                        >
                                            <Icon name={"icon_read-more-news"}/>
                                            <div className="more-news-block__title" >
                                                { endResult ? 'Свернуть' : 'Показать еще' }
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}
            </div>
        )
    }
}

export default SearchResult;