import {
    call,
    put,
    take,
    cancel,
    fork,
    select
} from 'redux-saga/effects';
import { CANCEL } from 'redux-saga';
import {
    fetchClientApi,
    fetchFileApi,
    checkErrors
} from '../tools/api-helper';
import {tariffsType} from "../tools/tools";

// получаем состояние напрямую через редьюсер
const sendParam = state => state.sendParamsStore;
const sendClient = state => state.client;
const tariffConnectionValue = state => state.common.tariffConnectionValue;

export function axisValues(regionId) {
    return {
        type: 'AXIS_VALUES',
        regionId
    }
}

export function* fetchAxisValues(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/constructor?region_id="+action.regionId, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function getTariffConstructor(regionId) {
    return {
        type: 'GET_TARIFF_CONSTRUCTOR',
        regionId
    }
}

export function* fetchGetTariffConstructor(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/tariff-constructor/"+action.regionId, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function price(regionId, min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim, router, youtube_unlim, ivi, currentSmsPack, cost_type, user_cost) {
    return {
        type: 'TARIFF_PRICE',
        regionId,
        min,
        gb,
        sms,
        social_unlim,
        data_unlim,
        motiv_voice_unlim,
        router,
        youtube_unlim,
        ivi,

        currentSmsPack,
        cost_type, 
        user_cost
    }
}

export function* fetchPrice(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/constructor-price?region_id="+action.regionId+
                "&m="+action.min+
                "&gb="+action.gb+
                "&sms="+action.sms+
                "&social_unlim="+action.social_unlim+
                "&data_unlim="+action.data_unlim+
                "&motiv_voice_unlim="+action.motiv_voice_unlim
                +(action.data_unlim ? "&router="+action.router : "")
                +"&youtube_unlim="+action.youtube_unlim
                +"&ivi="+action.ivi
                // +"&cost_type="+action.cost_type
                // +(action.cost_type === 1 ? "&user_cost="+action.user_cost : "")
                , {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function setTariffParams(tariff, regionId) {
    return {
        type: 'SET_TARIFF_PARAMS',
        tariff,
        regionId,
        getType: 'POST_TARIFF_CONSTRUCTOR'
    }
}

export function* onPostTariffConstructor() {
    yield put({ type: 'STOP_POST_TARIFF_CONSTRUCTOR' });
    yield put({ type: 'SEND_POST_TARIFF_CONSTRUCTOR' });
}

export function postTariffConstructor(tariff, regionId, phone = null) {
    return {
        type: 'POST_TARIFF_CONSTRUCTOR',
        tariff,
        regionId,
        phone
    }
}

export function* fetchPostTariffConstructor(action) {
    yield put({type: 'IS_CONSTRUCTOR_OK', data: true})

    const params = yield select(sendParam);
    let {regionId, tariff, getType: actionType} = params && params.getParamsTariff;

    // получаем номер телефона авторизованного пользователя
    const client = yield select(sendClient);
    let {username} = client && client.clientInfo;

    const tariffConnectionVal = yield select(tariffConnectionValue);

    if (tariff) {
        try {
            let body = new FormData();
            
            body.append('data', tariff.data);
            body.append('min', tariff.min);

            body.append('cost_type', tariff.cost_type);
            if(tariff.cost_type === 1){
                body.append('user_cost', tariff.user_cost);
            }

            if(username && tariffConnectionVal === tariffsType.find(item=>item.id==='change_tariff').type){
                body.append('phone', username);
                body.append('first_in', tariff.first_in);
            }

            if (tariff.options) {
                for (let i = 0; i < tariff.options.length; i++) {
                    body.append('options[]', tariff.options[i]);
                }
            }

            const controller = new AbortController();

            const {status, data, error} = yield call(() => {
                const promise = fetchClientApi("/tariff-constructor/" + regionId, {
                    method: 'POST',
                    body,
                    signal: controller.signal
                });
                promise[CANCEL] = () => controller.abort();

                return promise;
            });

            if (error) console.log(error);
            else {
                yield put(checkErrors(actionType, data, status));
            }

        } catch (e) {
            console.log(e);
        }
        finally {
            yield put({type: 'IS_CONSTRUCTOR_OK', data: false})
        }
    }
}

export function* calculateTariff() {
    while (true) {
        yield take('SEND_POST_TARIFF_CONSTRUCTOR');
        const calculateTariff = yield fork(fetchPostTariffConstructor);

        const act = yield take([
            'STOP_POST_TARIFF_CONSTRUCTOR',
            'POST_TARIFF_CONSTRUCTOR_SUCCESS',
        ]);

        if (act.type === 'STOP_POST_TARIFF_CONSTRUCTOR') {
            yield cancel(calculateTariff);
        }
    }
}

export function banner() {
    return {
        type: 'CONSTRUCTOR_BANNER'
    }
}

export function* fetchBanner(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/constructor-banner", {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function settings(regionId) {
    return {
        type: 'CONSTRUCTOR_SETTINGS',
        regionId
    }
}

export function* fetchSettings(action) {
    try {
        const {status, data, error} = yield call(() => {
            return fetchClientApi("/constructor-settings?region_id=" + action.regionId, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function pdf(regionId, min, gb, sms, social_unlim, data_unlim, motiv_voice_unlim) {
    return {
        type: 'CONSTRUCTOR_PDF',
        regionId,
        min,
        gb,
        sms,
        social_unlim,
        data_unlim,
        motiv_voice_unlim
    }
}

export function* fetchPdf(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchFileApi("/constructor-pdf?region_id="+action.regionId+
                "&m="+action.min+"&gb="+action.gb+"&sms="+action.sms+"&social_unlim="+action.social_unlim+"&data_unlim="+action.data_unlim+"&motiv_voice_unlim="+action.motiv_voice_unlim)
        });

        if (error) console.log(error);
        else {
            if (status === 200) {
                var url = URL.createObjectURL(data);
                yield put(checkErrors(action.type, {url}, status));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
        }

    } catch(e) {
        console.log(e);
    }
}
