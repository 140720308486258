import {
    call,
    put,
    take,
} from 'redux-saga/effects';

import {
    fetchClientApi,
    fetchClientLkApi,
    checkErrors
} from '../tools/api-helper';

import {clientApiUrl} from "../tools/api-helper";
import { getCookie } from '../tools/tools';
import {info, remainsInfo, tariffInfo} from "./client";

// получаем состояние напрямую через редьюсер
// const sendClient = state => state.client;

export function checkToken() {
    return {
        type: "CHECK_TOKEN"
    }
}

export function checkAvailable(kind, phone, serv_id, smartDevice=false) {
    return {
        type: 'CHECK_AVAILABLE',
        kind,
        phone,
        serv_id,
        smartDevice
    }
}

export function* fetchCheckAvailable(action) {
    try {

        let body = new FormData();

        body.append('type', action.kind);
        body.append('phone', action.phone.replace(/\D/gim, '').slice(1));
        body.append('serv_id', action.serv_id);
        body.append('smart_device', action.smartDevice);

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/check-available", {
                method: 'POST',
                body
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function activateItem(kind, verify_code, query_id) {
    return {
        type: 'ACTIVATE_ITEM',
        kind,
        verify_code,
        query_id
    }
}

export function* fetchActivateItem(action) {
    try {
        let body = new FormData();

        body.append('type', action.kind);
        body.append('verify_code', action.verify_code);
        body.append('query_id', action.query_id);

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/activate", {
                method: 'POST',
                body
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        console.log(e);
    }
}

export function clearActivationData() {
    return {
        type: 'CLEAR_ACTIVATION_DATA'
    }
}

export function clearOperatorData() {
    return {
        type: 'CLEAR_OPERATOR_DATA'
    }
}

export function checkOperator(number, params = false) {
    return {
        type: 'CHECK_OPERATOR',
        number,
        params
    }
}

export function checkRadioValue(value) {
    return {
        type: 'CHECK_RADIO_VALUE',
        value
    }
}

export function* fetchCheckOperator(action) {
    try {
        const { status, data, error } = yield call( () => {

            return fetchClientApi("/operator?number="+action.number.phone.slice(1), {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, {...data, params: action.params}, status));
        }

    } catch(e) {
        console.log(e);
    }
}

// C-5
export function checkAvailableConstructor(phone, regionId, checkData, smartDevice) {
    if(smartDevice){
        return {
            type: 'CHECK_AVAILABLE_CONSTRUCTOR',
            phone,
            regionId,
            checkData,
            smartDevice
        }
    }

    return {
        type: 'CHECK_AVAILABLE_CONSTRUCTOR',
        phone,
        regionId,
        checkData,
    }

}

export function* fetchCheckAvailableConstructor(action) {

    const smart_device = action.smartDevice?action.smartDevice:undefined;
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/constructor-check-available?abnum=" + action.phone.replace(/\D/gim, '').slice(1) + "&regionId=" + action.regionId+`${smart_device!==undefined?`&smart_device=${smart_device}`:''}`, {
                method: 'GET',
            })
        });
        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));

            if(action.checkData) {
                yield put({ type: 'CHECK_BALANCE_LACK', data: {
                        abnum: action.checkData.phone,
                        abon: action.checkData.abon,
                        queryId: data.query_id
                    }
                });
            }
        }

    } catch(e) {
        console.log(e);
    }
}

// C-27
export function checkBalanceLack(data) {
    return {
        type: 'CHECK_BALANCE_LACK',
        data
    }
}
export function* fetchCheckBalanceLack(action) {
    try {
        let body = new FormData();

        body.append('abnum', action.data.abnum.replace(/\D/gim, '').slice(1));
        body.append('abon', parseInt(action.data.abon));
        if(action.data.queryId) body.append('queryId', action.data.queryId);
        if(action.data.code) body.append('code', action.data.code);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/check-balance", {
                method: 'POST',
                body
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}



// C-7
export function activateTariffConstructor(tariff, regionId) {
    return {
        type: 'ACTIVATE_TARIFF_CONSTRUCTOR',
        tariff,
        regionId
    }
}

export function* fetchActivateTariffConstructor(action) {

    // получаем номер телефона авторизованного пользователя
    // const client = yield select(sendClient);
    // let {username} = client && client.clientInfo;

    try {
        let body = new FormData();

        if (action.tariff.region_id) {
            body.append('region_id', action.tariff.region_id);
        }
        body.append('abnum', action.tariff.abnum.replace(/\D/gim, '').slice(1));

        body.append('email', action.tariff.email);
        if(action.tariff.query_id) body.append('query_id', action.tariff.query_id);
        if(action.tariff.verify_code) body.append('verify_code', action.tariff.verify_code);
        body.append('social_network_unlim', action.tariff.socialNetworkUnlim);
        body.append('motiv_voice_unlim', action.tariff.motiv_voice_unlim);
        body.append('data_unlim', action.tariff.data_unlim);
        body.append('youtube_unlim', action.tariff.youtube_unlim);
        body.append('ivi', action.tariff.ivi);
        body.append('home_region_checked', action.tariff.home_region_checked);
        
        body.append('alias', action.tariff.alias);
        body.append('abon', action.tariff.abon);
        body.append('abon_discount', action.tariff.abon_discount);
        body.append('min', action.tariff.min);
        body.append('sms', action.tariff.sms);
        body.append('data', action.tariff.data);
        body.append('is_site', action.tariff.is_site);

        // if(username){
            // body.append('phone', username);
        // }

        if (!!action.tariff.tariff_id) {
            body.append('tariff_id', action.tariff.tariff_id);
        }
        if (action.tariff.data_unlim) {
            body.append('router', action.tariff.router);
        }

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/tariff-constructor?regionId=" + action.regionId, {
                method: 'POST',
                body
            })
        });

        if (status === 500) {
            yield put({type: action.type + '_ERROR'});
        }
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}


export function checkWord(word) {
    return {
        type: 'CHECK_WORD',
        word
    }
}

export function* fetchCheckWord(action) {

    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/word-state?word=" + action.word, {
                method: 'GET',
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function clearWord() {
    return {
        type: 'CLEAR_WORD'
    }
}

// S-34
export function activateMyTariff(tariff, regionId) {
    return {
        type: 'ACTIVATE_MY_TARIFF',
        tariff,
        regionId
    }
}

export function* fetchActivateMyTariff(action) {
    try {
        yield put(checkToken());
        yield take("TOKEN_CHECKED");

        let body = JSON.stringify({...action.tariff, region_id: action.regionId});

        const { status, data, error } = yield call( () => {
            return fetchClientLkApi("/v1/my-tariff", {
                method: 'POST',
                token: getCookie("access_token"),
                body
            })
        });

        if (status === 500) {
            yield put({type: action.type + '_ERROR'});
        }
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        console.log(e);
    }
}

export function sendB2bRequest(data, tariffId) {
    return {
        type: 'SEND_B2B_REQUEST',
        data,
        tariffId
    }
}

export function* fetchSendB2bRequest(action) {
    try {

        if (action.tariffId) {
            action.data.append('tariff_id', action.tariffId)
        }

        const { status, data, error } = yield call( () => {
            return fetchClientApi(action.tariffId ? "/tariff-request" : "/become-client", {
                method: 'POST',
                body: action.data
            })
        });

        if (error) console.log(error);
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        yield put(checkErrors(action.type, {message: e}, 500));
    }
}


export function clearTariffPopupError() {
    return {
        type: 'CLEAR_TARIFF_POPUP_ERROR',
    }
}
