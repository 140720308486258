import React, {Component} from 'react';
import {onlyLetters, phoneMask} from "../../tools/mask";
import {
    docNumberValidator, dogovorNumberValidator,
    emailValidator, formatBytes,
    innValidator, licNumberValidator,
    phoneValidator,
    usernameValidator, whiteSpaceValidator
} from "../../tools/form-helper";
import PropTypes from "prop-types";
import Input from "../Constructor/Input";
import Select from "../Vacancies/Select";
import Icon from "../common/Icon";
import CheckBox from "../common/CheckBox";
import {connect} from "react-redux";
import {vacancyRespond} from "../../actions/forms";
import {cvForm} from "../../actions/cvForm";
import {feedbackForm, feedbackRequest, feedbackSubjects} from "../../actions/support";
import {checkCallbackForm} from "../../actions/callback";
import SuccessModal from "../modals/SuccessModal";

class SupportFeedbackForm extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            regions: [],
            cities: [],
            selectRegionId: null,
            selectCityId:  null,
            specialization: null,
            vacancyByList: null,
            selectSupportTypeId: 'account',
            selectSupportThemeId: null,
            formValid: null,
            file: null,
            files: [],
            fieldLocked: false,
            agreementChecked: false,
            isModal: false,
            captchaError: "",
            fields: {
                supportType: {
                    typeInput: "select",
                    // label: "Город",
                    value: 'account',
                    options: [],
                    name: "supportType",
                    hasError: false,
                    errorText: "Выберите тип документа",
                    validators: false,
                },
                personalAcc: {
                    typeInput: "text",
                    placeholder: '10 цифр без пробелов',
                    // label: "e–mail",
                    value: "",
                    name: "personalAcc",
                    // required: true,
                    validators: [licNumberValidator, whiteSpaceValidator],
                    hasError: false,
                    errorText: "Укажите корректный номер лицевого счета",
                    dot_orange: true
                },
                docNumber: {
                    typeInput: "text",
                    placeholder: 'До 12 символов',
                    // label: "e–mail",
                    value: "",
                    name: "docNumber",
                    // required: true,
                    validators: [dogovorNumberValidator, whiteSpaceValidator],
                    hasError: false,
                    errorText: "Укажите корректный номер договора",
                    dot_orange: true
                },
                inn: {
                    typeInput: "text",
                    placeholder: "10 или 12 цифр без пробелов",
                    // label: "e–mail",
                    value: "",
                    name: "inn",
                    // required: true,
                    validators: [innValidator, whiteSpaceValidator],
                    hasError: false,
                    errorText: "Укажите корректный номер ИНН",
                    dot_orange: true
                },
                supportTheme: {
                    typeInput: "select",
                    // label: "Город",
                    value: "",
                    options: [],
                    name: "supportTheme",
                    hasError: false,
                    errorText: "Выберите тематику",
                    validators: false,
                }
            },
        }
    }
    static contextTypes = {
        fields: PropTypes.object
    };

    componentDidMount(){
        this.props.onFeedbackForm();
        this.props.getFeedbackSubjects()


        let dropArea = document.getElementById('drop-area')

        dropArea.addEventListener('dragenter', preventDefaults, false)
        dropArea.addEventListener('dragover', preventDefaults, false)
        dropArea.addEventListener('dragleave', preventDefaults, false)
        dropArea.addEventListener('drop', preventDefaults, false)

        function preventDefaults (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        dropArea.addEventListener('dragenter', highlight, false)
        dropArea.addEventListener('dragover', highlight, false)

        dropArea.addEventListener('dragleave', unhighlight, false)
        dropArea.addEventListener('drop', unhighlight, false)

        function highlight(e) {
            dropArea.classList.add('dragged')
        }

        function unhighlight(e) {
            dropArea.classList.remove('dragged')
        }
        dropArea.addEventListener('drop', this.handleDrop, false)


        const fileInput = document.querySelector('.b-form-spam__attach-input');
        let reader = new FileReader();

        const addListeners = (reader, index) => {
            reader.addEventListener("loadstart", (e) => {handleEventLoadStart(e, index)});
            reader.addEventListener("loadend", (e) => {handleEventLoadend(e, index)});
            reader.addEventListener("progress", (e) => {handleEventProgress(e, index)});
        }

        const handleEventLoadStart = (e, index) => {

        }
        const handleEventProgress = (e, index) => {
            changeFileLoader(e.loaded / e.total * 100, index);
        }
        const handleEventLoadend = (e, index) => {
            if(document.querySelector('.support-files-container-' + (index + 1))) {
                document.querySelector(`.support-files-container-${index + 1}`).classList.add('support-files-container-load-end')
            }
            reader = new FileReader();
        }
        const handleSelected = (e, index) => {
            const selectedFile = fileInput.files[0];
            if (selectedFile) {
                addListeners(reader, index);
                reader.readAsDataURL(selectedFile);
            }
        }
        fileInput.addEventListener("change", (e) => {handleSelected(e, this.state.files.length)});

        const changeFileLoader = (percent, index) => {
            if(document.querySelector('.support-files-container-' + (index + 1))) {
                document.querySelector(`.support-files-container-${index + 1} .file-doc-size-loading`).innerHTML = Math.floor(percent) + '% загружено';
                document.querySelector(`.support-files-container-${index + 1} .file-doc-progressBar`).style.width = percent + '%'
            }
        }
    }
    handleDrop = (e) => {
        if((5 - this.state.files.length) >= e.dataTransfer.files.length) {
            let dt = e.dataTransfer
            let files = dt.files


            const oldLength = this.state.files.length;
            let isCheck = true;
            for (let i = 0; i < files.length; i++) {
                if (!(
                    files[i].type === "application/pdf" ||
                    files[i].type === "image/jpeg" ||
                    files[i].type === "image/jpg" ||
                    files[i].type === "image/gif"
                )) isCheck = false;
            }
            if (files.length && isCheck) {
                const addListeners = (reader, index) => {
                    reader.addEventListener("loadend", (e) => {handleEventLoadend(e, index)});
                    reader.addEventListener("progress", (e) => {handleEventProgress(e, index)});
                }
                const handleEventProgress = (e, index) => {
                    changeFileLoader(e.loaded / e.total * 100, index);
                }
                const handleEventLoadend = (e, index) => {
                    if(document.querySelector('.support-files-container-' + (index + 1))) {
                        document.querySelector(`.support-files-container-${index + 1}`).classList.add('support-files-container-load-end')
                    }
                }
                const handleSelected = (e, index) => {
                    const selectedFile = e;
                    if (selectedFile) {
                        let reader = new FileReader();
                        addListeners(reader, index);
                        reader.readAsDataURL(selectedFile);
                    }
                }

                const changeFileLoader = (percent, index) => {
                    if(document.querySelector('.support-files-container-' + (index + 1))) {
                        document.querySelector(`.support-files-container-${index + 1} .file-doc-size-loading`).innerHTML = Math.floor(percent) + '% загружено';
                        document.querySelector(`.support-files-container-${index + 1} .file-doc-progressBar`).style.width = percent + '%'
                    }
                }

                this.setState({
                    files: [...this.state.files, ...files],
                    fileError: null
                }, () => {

                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        handleSelected(e.dataTransfer.files[i], oldLength + i);
                    }
                });
            } else {
                this.setState({
                    fileError: 'Неверный формат файла'
                })
            }
        } else {
            this.setState({
                fileError: 'Можно загружать не более 5 файлов'
            })
        }
    }
    componentWillMount() {
        if (typeof location != "undefined") {
            this.props.loadForm(location.origin + location.pathname, this.context.isB2b ? 'b2b' : '');
        }
    }

    createReCaptcha = (siteKey) => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
        script.async = true;
        script.defer = true;
        document.body.appendChild(script)
    }
    componentWillReceiveProps(nextProps, nextContext) {
        const fields = {...this.state.fields}

        if (this.props.callbackForm.recaptchaSiteKey !== nextProps.callbackForm.recaptchaSiteKey) {
            this.createReCaptcha(nextProps.callbackForm.recaptchaSiteKey);
        }
        if(this.props.support.feedbackError !== nextProps.support.feedbackError) {
            for (const key in nextProps.support.feedbackError) {
                if(key === 'captcha') {
                    this.setState({captchaError: nextProps.support.feedbackError['captcha']})
                }
                if(key === 'theme') {
                    fields['supportTheme'].hasError = true
                }
                if(key === 'number') {
                    if(this.state.selectSupportTypeId === 'account') {
                        fields['personalAcc'].hasError = true
                    }
                    else if(this.state.selectSupportTypeId === 'contract') {
                        fields['docNumber'].hasError = true
                    }
                    else if(this.state.selectSupportTypeId === 'inn') {
                        fields['inn'].hasError = true
                    }
                }
            }
        }
        if ((this.props.support.feedbackSuccess !== nextProps.support.feedbackSuccess) && nextProps.support.feedbackSuccess) {
            this.setState({isModal: true})
            this.cleanForm();
        }
        this.setState({fields})
    }
    componentWillUpdate(nextProps, nextState, nextContext) {

    }

    handlerGetListRegions = (list) => {
        let regions = [];

        if (list) {
            list.map((item, index) => {
                regions.push({ value: item.id, label: item.name });
            })
        }

        this.setState({ regions: regions });
    };

    handlerChangeDocType = (value) => {
        this.setState({
            selectSupportTypeId: value
        })
    };
    handlerChangeDocTheme = (value) => {
        this.setState({
            selectSupportThemeId: value
        })
    }

    selectFormName = (name, value) => {
        let fields = {...this.state.fields};

        fields[name].value = value;
        this.setState({fields});
    };

    onSubmit = (e) => {
        e.preventDefault();

        if(this.isValid()) {
            window.grecaptcha
                .execute(this.props.callbackForm.recaptchaSiteKey)
                .then(token => {
                    const fields = {...this.state.fields}
                    let formData = new FormData();

                    formData.append('agreement', this.state.agreementChecked ? 1 : 0);
                    formData.append('captcha', token);
                    formData.append('type', this.state.selectSupportTypeId);
                    switch (this.state.selectSupportTypeId) {
                        case 'account':
                            formData.append('number', fields.personalAcc.value);
                            break
                        case 'contract':
                            formData.append('number', fields.docNumber.value);
                            break
                        case 'inn':
                            formData.append('number', fields.inn.value);
                            break
                        default: break
                    }
                    formData.append('theme', fields.supportTheme.value);

                    for (var i = 0; i < this.state.files.length; i++) {
                        formData.append('files[]', this.state.files[i]);
                    }

                    this.setState({captchaError: ''})
                    this.props.onFeedbackRequest(formData);
                })
        }
    };

    onBlur = (name) => {

        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid
                        }
                    }
                });
            }
        };
    };

    onBlurSelect = (name, value) => {
        return (e) => {
            if (value === ""){
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: true
                        }
                    }
                });
            }
        };
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    onFieldChange = (name, value) => {
        let fields = {...this.state.fields};
        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        let valid = true;
        if (!value) {
            valid = false
        }
        if (fields[name].hasOwnProperty('validators') && !!fields[name].validators) {
            fields[name].validators.forEach(function (func) {
                if (!func.call(this, fields[name].value)) {
                    valid = false;
                }
            });
        }
        fields[name].hasError = !valid;
        this.setState({fields});
    };
    onAgreementChange = (name, val) => this.setState((prev) => {
        return {
            agreementChecked: !prev.agreementChecked
        }
    })
    isValid = () => {
        const fields = {...this.state.fields}
        return !(
            this.state.files.length === 0 ||
            (this.state.selectSupportTypeId === 'account' && (!fields["personalAcc"].value || fields['personalAcc'].hasError)) ||
            (this.state.selectSupportTypeId === 'contract' && (!fields["docNumber"].value || fields['docNumber'].hasError)) ||
            (this.state.selectSupportTypeId === 'inn' && (!fields["inn"].value || fields['inn'].hasError)) ||
            !this.state.selectSupportThemeId
        )
    }

    // позволяет загружать один и тот же файл в inputFile
    preClearFile = (e) => {
        e.target.value = null;
    }

    // добавляем файл
    onFileChange = (e) => {
        if(this.state.files.length < 5) {
            if (
                e.target.files.length &&
                (
                    e.target.files[0].type === "application/pdf" ||
                    e.target.files[0].type === "image/jpeg" ||
                    e.target.files[0].type === "image/jpg" ||
                    e.target.files[0].type === "image/gif"
                )
            ) {
                this.setState({
                    files: [...this.state.files, e.target.files[0]],
                    fileError: null
                });
            } else {
                this.setState({
                    fileError: 'Неверный формат файла'
                })
            }
        } else {
            this.setState({
                fileError: 'Можно загружать не более 5 файлов'
            })
        }

    };

    fileClean = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            files: this.state.files.filter((el, i) => index != i),
            fileError: null
        })
    };

    cleanForm = () => {
        let fields = {...this.state.fields}
        fields.personalAcc.value = ""
        fields.docNumber.value = ""
        fields.inn.value = ""
        fields.supportTheme.value = ""
        this.setState({
            files: [],
            agreementChecked: false,
            selectSupportTypeId: 'account',
            selectSupportThemeId: null,
            fields
        })
    }

    render () {

        let {
            supportType,
            supportTheme,
            personalAcc,
            docNumber,
            inn
        } = this.state.fields;

        let {
            fieldLocked,
            agreementChecked,
            files,
            fileError,
            selectSupportTypeId,
            isModal
        } = this.state;


        let cvForm = this.props.cvForm ? this.props.cvForm.cvForm.find(x => x.alias === 'send_cv_form') : [];
        let modalComment = cvForm && cvForm.settings.send_cv_form_attach_comment;
        let agreement = cvForm && cvForm.settings.send_cv_form_agreement;

        return (
            <React.Fragment>
                <div className="support-page">
                    <div className="page__title-3 page__title-3_mobile-less">Обратная связь</div>
                    {
                        isModal &&
                        <SuccessModal
                            title={(this.props.support.feedbackForm && this.props.support.feedbackForm.settings) ? this.props.support.feedbackForm.settings.feedback_form_b2b_header : ''}
                            text={(this.props.support.feedbackForm && this.props.support.feedbackForm.settings) ? this.props.support.feedbackForm.settings.feedback_form_b2b_content : ''}
                            icon={true}
                            btnText="Ок"
                            close={() => {this.setState({isModal: false})}}
                            className="u-modal u-modal-support-success"
                            wrapperClassName="u-modal-wrapper support-modal-success"
                        />
                    }

                    <form onSubmit={ this.onSubmit } className="vacancy-modal-form support-form">
                        <div className="form-row form-vacancy-row form-support-row">
                            <div className="form-vacancy-row--item form-vacancy-label-column form-select-full-width form-support-row-item form-support-docType-row-item">
                                <Select
                                    options={ [
                                        {value: 'account', label: 'Номер лицевого счета'},
                                        {value: 'contract', label: 'Номер договора'},
                                        {value: 'inn', label: 'ИНН'}
                                    ] }
                                    onToggle={ this.handlerChangeDocType }
                                    selectFormName={ this.selectFormName }
                                    attribute={ "supportType" }
                                    value={ this.state.selectSupportTypeId }
                                    hasError={ supportType.hasError }
                                    errorText={ supportType.errorText }
                                    onChange={this.onFieldChange}
                                    onblur={this.onBlurSelect(supportType.name, supportType.value)}
                                    onfocus={this.onFocus(supportType.name)}
                                    fieldLocked={fieldLocked}
                                    placeholder="Номер лицевого счета"
                                />
                            </div>
                            <div className="form-vacancy-row--item form-vacancy-row--email form-vacancy-label-column form-support-row-item">
                                {
                                    selectSupportTypeId === 'account' &&
                                    <Input { ...personalAcc }
                                           onChange={this.onFieldChange}
                                           name={personalAcc.name}
                                           onFocus={this.onFocus(personalAcc.name)}
                                           onBlur={this.onBlur(personalAcc.name)}
                                           placeholder="10 цифр без пробелов"
                                    />
                                }
                                {
                                    selectSupportTypeId === 'contract' &&
                                    <Input { ...docNumber }
                                           onChange={this.onFieldChange}
                                           name={docNumber.name}
                                           onFocus={this.onFocus(docNumber.name)}
                                           onBlur={this.onBlur(docNumber.name)}
                                           placeholder="До 12 символов"
                                    />
                                }
                                {
                                    selectSupportTypeId === 'inn' &&
                                    <Input { ...inn }
                                           onChange={this.onFieldChange}
                                           name={inn.name}
                                           onFocus={this.onFocus(inn.name)}
                                           onBlur={this.onBlur(inn.name)}
                                           placeholder="10 или 12 цифр без пробелов"
                                    />
                                }
                            </div>
                        </div>
                        <div className="b-vacancy-search__input-carousels form-vacancy-search__input-carousels">
                            <div className="b-support-search__input-carousel">
                                <Select
                                    options={this.props.support.feedbackSubjects}
                                    onToggle={ this.handlerChangeDocTheme }
                                    selectFormName={ this.selectFormName }
                                    attribute={ "supportTheme" }
                                    value={ this.state.selectSupportThemeId }
                                    hasError={ supportTheme.hasError }
                                    errorText={ supportTheme.errorText }
                                    onChange={this.onFieldChange}
                                    onblur={this.onBlurSelect(supportTheme.name, supportTheme.value)}
                                    onfocus={this.onFocus(supportTheme.name)}
                                    fieldLocked={fieldLocked}
                                    placeholder="Выберите тематику"
                                />
                            </div>
                        </div>
                        <div id="drop-area" className={`support-form-upload-container ${fileError ? 'error-upload-form' : ''}`}>
                            <svg className="upload-form-icon" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M30.4072 12.0201C30.4694 12.1724 30.501 12.3355 30.5 12.5V17.5H28V15H20.5C19.837 15 19.2011 14.7366 18.7322 14.2678C18.2634 13.7989 18 13.1631 18 12.5V5.00002H8V35H28V32.5H30.5V35C30.5 35.6631 30.2366 36.2989 29.7678 36.7678C29.2989 37.2366 28.663 37.5 28 37.5H8C7.33696 37.5 6.70107 37.2366 6.23223 36.7678C5.76339 36.2989 5.5 35.6631 5.5 35V5.00002C5.5 4.33698 5.76339 3.70109 6.23223 3.23225C6.70107 2.76341 7.33696 2.50002 8 2.50002H20.5C20.6645 2.49907 20.8276 2.5306 20.9799 2.59281C21.1322 2.65501 21.2707 2.74667 21.3875 2.86252L30.1375 11.6125C30.2534 11.7293 30.345 11.8678 30.4072 12.0201ZM20.5 5.51252V12.5H27.4875L20.5 5.51252ZM19.0375 23.75H35.5V26.25H19.0375L22.2625 29.4875L20.5 31.25L14.25 25L20.5 18.75L22.2625 20.5125L19.0375 23.75Z" fill="black"/>
                            </svg>
                            <p className="support-upload-title">Загрузите файл или изображение</p>
                            <p className="support-upload-dragged-title">Перетащите файл в эту область</p>
                            <p className="support-upload-subtitle">PDF, JPEG, JPG или GIF</p>
                            <label className="support-upload-btn">
                                Выбрать
                                <input
                                    className="b-form-spam__attach-input"
                                    type="file"
                                    onClick={this.preClearFile}
                                    onChange={this.onFileChange}
                                    accept="image/jpeg,image/jpg,image/gif,.pdf"
                                />
                            </label>
                            {fileError && <div className="form-field-error file-upload-error">{fileError}</div>}
                        </div>
                        {
                            files.length > 0 &&
                            <ul className="files-list">
                                {
                                    files.map((file, index) => (
                                        <li className={`support-files-container support-files-container-${index + 1}`} key={index}>
                                            <div className="file-title">
                                                <span className="file-doc-icon">
                                                    <svg className="support-file-icon" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 28H15V30H8C7.47004 29.9984 6.96222 29.7872 6.58748 29.4125C6.21274 29.0378 6.00153 28.5299 6 28V3.99998C6.00153 3.47002 6.21274 2.9622 6.58748 2.58745C6.96222 2.21271 7.47004 2.00151 8 1.99998H18C18.1314 1.99636 18.2621 2.02131 18.3829 2.07311C18.5038 2.1249 18.612 2.20231 18.7 2.29998L25.7 9.29998C25.7976 9.38803 25.875 9.4962 25.9268 9.61705C25.9786 9.7379 26.0036 9.86855 26 9.99998V18H24V12H18C17.47 11.9984 16.9622 11.7872 16.5875 11.4125C16.2127 11.0378 16.0015 10.5299 16 9.99998V3.99998H8V28ZM23.6 9.99998L18 4.39998V9.99998H23.6ZM19.41 24.59L22 27.18L28.59 20.59L30 22L22 30L18 26L19.41 24.59Z" fill="#FA6600"/>
                                                    </svg>
                                                    <svg className="support-file-icon support-file-icon-loading" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 10V14H22V12H16C15.4696 12 14.9609 11.7893 14.5858 11.4142C14.2107 11.0392 14 10.5305 14 10V4.00002H6V28H22V26H24V28C24 28.5305 23.7893 29.0392 23.4142 29.4142C23.0391 29.7893 22.5304 30 22 30H6C5.46957 30 4.96086 29.7893 4.58579 29.4142C4.21071 29.0392 4 28.5305 4 28V4.00002C4 3.46958 4.21071 2.96088 4.58579 2.5858C4.96086 2.21073 5.46957 2.00002 6 2.00002H16C16.1316 1.99926 16.2621 2.02448 16.3839 2.07425C16.5057 2.12401 16.6166 2.19734 16.71 2.29002L23.71 9.29002C23.8027 9.38346 23.876 9.49427 23.9258 9.61611C23.9755 9.73795 24.0008 9.86841 24 10ZM16 4.41002V10H21.59L16 4.41002ZM14.83 19H28V21H14.83L17.41 23.59L16 25L11 20L16 15L17.41 16.41L14.83 19Z" fill="#929292"/>
                                                    </svg>
                                                </span>
                                                <span className="file-doc-name">
                                                    {file.name} <br/>
                                                    <div className="file-doc-progressBar-container">
                                                        <div className="file-doc-progressBar" />
                                                    </div>
                                                    <span className="file-doc-size file-doc-size-loading">0% загружено</span>
                                                    <span className="file-doc-size">{formatBytes(file.size)}</span>
                                                </span>
                                            </div>
                                            <span className="remove-file-icon" onClick={(e) => {this.fileClean(e, index)}}>
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5858 16.0001L8.79295 10.2072L10.2072 8.79295L16.0001 14.5858L21.793 8.79295L23.2072 10.2072L17.4143 16.0001L23.2072 21.793L21.793 23.2072L16.0001 17.4143L10.2072 23.2072L8.79295 21.793L14.5858 16.0001Z" fill="#707478"/>
                                                </svg>
                                            </span>
                                        </li>
                                    ))
                                }
                            </ul>
                        }
                        <div className="support-block-agreement">
                            <CheckBox
                                name={'agree-feedback'}
                                className="support-form-success"
                                label={(this.props.support.feedbackForm && this.props.support.feedbackForm.settings) ? this.props.support.feedbackForm.settings.feedback_form_b2b_agreement : ''}
                                value={agreementChecked}
                                onChange={this.onAgreementChange}
                            />
                            <div className="description--dot support-desc">
                                <span className="form-vacancy-description-txt">Все поля обязательные для заполнения</span>
                            </div>
                        </div>
                        <div>
                            {this.state.captchaError && <div className='captcha-error'>{this.state.captchaError}</div>}
                        </div>
                        <div className="vacancy-modal-btn-block support-send-block">
                            <div className="text-description" style={{display:'none'}}>
                                Этот сайт защищен reCAPTCHA и применяются<br/>
                                <a href="https://policies.google.com/privacy" target='_blank'>Политика конфиденциальности</a> и <a href="https://policies.google.com/terms" target='_blank'>Условия обслуживания</a> Google
                            </div>
                            <button
                                onClick={this.onSubmit}
                                type="submit"
                                className={"u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default support-form-send-btn"+(this.isValid() && agreementChecked && !this.props.support.inProgress ? "" : " disabled")}>
                                Отправить
                            </button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }

}


export default connect(
    state => ({
        cvForm: state.cvForm || [],
        support: state.support,
        callbackForm: state.callbackForm,
    }),
    (dispatch) => {
        return {
            loadForm: (url, businessLine) => dispatch(checkCallbackForm(url, businessLine)),
            getCvForm: () => dispatch(cvForm()),
            getFeedbackSubjects: () => dispatch(feedbackSubjects()),
            onFeedbackRequest: (data) => dispatch(feedbackRequest(data)),
            onFeedbackForm: () => dispatch(feedbackForm()),
        }
    }
)(SupportFeedbackForm);