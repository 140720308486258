import React, {Component} from 'react';
import Select from "./Select";
import CollapseBlock from "./CollapseBlock";
import PropTypes from "prop-types";
import {putData} from "../../actions/vacanciesFilterSave";
import {connect} from "react-redux";
import {vacanciesFilterSave} from "../../reducers/vacanciesFilterSave";
import {getCookie} from "../../tools/tools";

class SearchVacancy extends Component {

    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            regions: [],
            cities: [],
            selectRegionId: null,
            selectCityId:  null,
            vacancyByCategory: [],
            filterSave: {
                regionId: null,
                cityId:  null,
            },
            selectVacancyId: null
        }
    }

    static contextTypes = {
        selectedRegion:  PropTypes.object,
    };

    componentDidMount() {
        const hashVacancy = window.location.hash;
        if(hashVacancy){
            const vacancyId = hashVacancy.replace('#vacancy-', '');
           this.setState({selectVacancyId:Number(vacancyId)});
        }
    }


    // когда используется рендеринг на стороне сервера
    componentWillMount(){

        let regionIdStore = this.props.vacanciesFilterSave && this.props.vacanciesFilterSave.vacancies.regionId;
        let cityIdStore = this.props.vacanciesFilterSave && this.props.vacanciesFilterSave.vacancies.cityId;

        let regionIdMain = this.context.selectedRegion.id;

        if (!!regionIdStore && regionIdStore !== regionIdMain) {
            this.handlerGetListRegions(this.props.regions);
            this.handlerChangeRegion(regionIdStore, this.props.regions);
            this.sort( regionIdStore, this.props.vacancyList, this.props.regions);
            if (!!cityIdStore ) {
                this.handlerGetListVacancyByCategory(this.props.vacancyList, regionIdStore, cityIdStore);
                this.handlerChangeCity(cityIdStore);
            }
        } else {
            this.handlerGetListRegions(this.props.regions);
            this.handlerChangeRegion(regionIdMain, this.props.regions);
            this.sort( regionIdMain, this.props.vacancyList, this.props.regions);

            if (!!cityIdStore ) {
                this.handlerGetListVacancyByCategory(this.props.vacancyList, regionIdStore, cityIdStore);
                this.handlerChangeCity(cityIdStore);
            }
        }

    }

    // Эта функция будет вызываться после того как отработала функция render, в каждом цикле перерисовки
    componentDidUpdate(prevProps, prevState){

        let filterSave = this.state.filterSave;
        let filterSaveCopy = this.arrayClone(filterSave);

        if (this.state.selectRegionId !== null && this.state.filterSave.regionId === null || this.state.selectRegionId !== prevState.selectRegionId
            || this.state.selectCityId !== prevState.selectCityId){
            filterSaveCopy.regionId = this.state.selectRegionId;
            filterSaveCopy.cityId = this.state.selectCityId;
            this.setState({
                filterSave: filterSaveCopy
            });

        }

        if (this.state.filterSave !== prevState.filterSave){
            this.props.setVacanciesFilterSave({data: filterSave});
        }

    }

    // Эта функция будет вызываться при каждом апдейт жизненном цикле
    componentWillReceiveProps(nextProps, nextContext) {

        if (this.props.regions !== nextProps.regions) {
            this.handlerGetListRegions(nextProps.regions);
        }

        let regionIdStore = this.props.vacanciesFilterSave && this.props.vacanciesFilterSave.vacancies.regionId;
        let cityIdStore = this.props.vacanciesFilterSave && this.props.vacanciesFilterSave.vacancies.cityId;
        let regionIdMain = this.context.selectedRegion.id;

        if (typeof regionIdStore === 'undefined' && this.state.selectRegionId === regionIdMain && typeof cityIdStore === 'undefined' ){
            this.sort( regionIdMain, nextProps.vacancyList, nextProps.regions);
        }

        if (typeof regionIdStore === 'undefined' && this.props.regions !== nextProps.regions) {
            this.handlerChangeRegion(this.props.selectedRegion.id, nextProps.regions);
        }

    }

    // Эта функция в основном используется для того чтобы сделать синхронизацию между состоянием
    componentWillUpdate(nextProps, nextState, nextContext) {

        let regionIdStore = this.props.vacanciesFilterSave && this.props.vacanciesFilterSave.vacancies.regionId;
        let cityIdStore = this.props.vacanciesFilterSave && this.props.vacanciesFilterSave.vacancies.cityId;
        let regionIdMain = this.context.selectedRegion.id;

        if (this.state.selectRegionId !== nextState.selectRegionId) {
            this.handlerChangeCity(null);
        }

        if (this.state.selectCityId !== cityIdStore ) {
            this.handlerGetListVacancyByCategory(nextProps.vacancyList, nextState.selectRegionId, nextState.selectCityId);
        }

        if ( this.state.selectRegionId !== nextState.selectRegionId && this.state.selectRegionId !== null && this.state.regions !== null ){
            this.handlerGetListVacancyByCategory(nextProps.vacancyList, nextState.selectRegionId, nextState.selectCityId, nextProps.regions);
        }

    }

    arrayClone = (arr)=> {
        let i, copy;
        if (Array.isArray(arr)) {
            copy = arr.slice(0);
            for (i = 0; i < copy.length; i++) {
                copy[i] = this.arrayClone(copy[i]);
            }
            return copy;
        } else if(typeof arr === 'object') {
            let obj = {};
            for (let key in arr) {
                if ((Array.isArray(arr[key])) || (typeof arr[key] === 'object')) {
                    obj = {...obj, [key]: this.arrayClone(arr[key]) }
                }
                else {
                    obj = {...obj, [key]: arr[key]}
                }
            }
            return obj;
        } else {
            return arr;
        }
    };

    // get list regions

    handlerGetListRegions = (list) => {

        let regions = [];

        if (list) {
            list.map((item, index) => {
                regions.push({ value: item.id, label: item.name });
            })
        }

        let sortRegions = regions.sort(function (a, b) {
            if (a.label > b.label){
                return 1;
            }
            if (a.label < b.label) {
                return -1;
            }
            return 0;
        });

        this.setState({ regions: sortRegions });
    };

    // get list cities

    handlerGetListCities = (list, value) => {
        let cities = [];

        if (list && value !== null) {
            list[value].cities.map((city, index) => {
                cities.push({ value: city.id, label: city.name });
            });
        }

        let sortCities = cities.sort(function (a, b) {
            if (a.label > b.label){
                return 1;
            }
            if (a.label < b.label) {
                return -1;
            }
            return 0;
        });

        this.setState({
            cities: sortCities
        });
    };

    // handler change region selector

    handlerChangeRegion = (value, regions) => {
        const regionsList = regions ? regions : this.props.regions;
        const selectRegionId = regionsList && regionsList.findIndex(item => item.id === value);

        this.handlerGetListCities(regionsList, selectRegionId);
        this.setState({
            selectRegionId: value
        })
    };

    // handler change city selector

    handlerChangeCity = (value) => {
        this.setState({
            selectCityId: value
        })
    };

    // sorting vacancy by category and rank

    sort =( regionId, vacanciesList, regionsList ) => {
        const valable_id =[];
        if (regionsList && regionsList.length > 0) {
            let regionsSort = regionsList.find(regionItem => regionItem.id === regionId);
            const city_ids = regionsSort.cities.map(city => city.id);
            vacanciesList && vacanciesList.forEach(vacancy => {
                const vacancies = vacancy.vacancies.filter(vacant => city_ids.includes(vacant.city_id));
                if (!vacancies || !vacancies.length) return;

                valable_id.push({...vacancy, vacancies})
            });

            this.setState({
                vacancyByCategory: valable_id
            });
        }
    };

    handlerGetListVacancyByCategory = (vacancyList, region, city, regionsList) => {
        const valable_id = [];
        // filter by city or region
        if ( city !== null ) {

            const city_ids = [city];
            vacancyList.forEach(vacancy => {
                const vacancies = vacancy.vacancies.filter(vacant => city_ids.includes(vacant.city_id));
                if (!vacancies || !vacancies.length) return;
                valable_id.push({...vacancy, vacancies})
            });
            this.setState({
                vacancyByCategory: valable_id
            });

        }
        if ( region !== null && !!regionsList ) {
            this.sort( region, vacancyList, regionsList );
        }
    };

    render() {

        let { subTitle } = this.props;
        
        return (
            <div className="b-vacancy-search b-vacancy-search_adapt_default">
                <div className="b-vacancy-search__wrapper">
                    <div className="b-vacancy-search__input-carousels">
                        <div className="b-vacancy-search__input-carousel">
                            <span className="b-vacancy-search__input-carousel--desc">Ваш регион</span>
                            <Select
                                options={ this.state.regions }
                                onToggle={ this.handlerChangeRegion }
                                value={ this.state.selectRegionId }
                            />
                        </div>
                        <div className="b-vacancy-search__input-carousel">
                            <span className="b-vacancy-search__input-carousel--desc">Ваш город</span>
                            <Select
                                options={ this.state.cities }
                                onToggle={ this.handlerChangeCity }
                                value={ this.state.selectCityId }
                            />
                        </div>
                    </div>
                    {subTitle ? <div className="b-vacancy-search__list-header">{ subTitle }</div> : null}
                    <div className="b-vacancy-search__list-collapser">
                        <CollapseBlock
                            defaultSelect={this.state.selectVacancyId}
                            options={this.state.vacancyByCategory}
                            classWrappedSubList="b-list-collapser__vacancy-list"
                            classSubList="b-vacancy-list"
                            classWrapperSubListItem="b-vacancy-list__item"
                            classSubListItem="b-vacancy-list__item-href"
                            vacancyLink={this.props.vacancyLink}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        vacanciesFilterSave: state.vacanciesFilterSave,
    }),
    (dispatch) => {
        return {
            setVacanciesFilterSave: (obj) => dispatch(putData(obj)),
        }
    }
)(SearchVacancy);

