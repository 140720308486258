import React, {Component} from "react";

import {phoneMask, onlyLetters} from "../../tools/mask";
import {emailValidator, phoneValidator, usernameValidator, emptyTextarea} from "../../tools/form-helper";
import Input from "../../components/Constructor/Input";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {clearComplainFormData, complainForm} from "../../actions/forms";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import SuccessModal from "../modals/SuccessModal";
import {checkCallbackForm} from "../../actions/callback";

class ComplainForm extends Component{

    constructor(props, context){
        super(props, context);

        let formTitle = this.props.items ? this.props.items.settings.appeal_form_title : null;
        this.state = {
            formValid: null,
            agreement: false,
            fieldLocked: false,
            captchaSuccessLoad: false,
            limitTextValue: 2000,
            captchaToken: '',
            siteKey: null,
            modal: null,
            blockSubmitBtn: false,
            fields: {
                form_name: {
                    value: formTitle,
                },
                fio: {
                    typeInput: "text",
                    label: "фио",
                    name: "fio",
                    value: "",
                    required: true,
                    mask: onlyLetters,
                    validators: [usernameValidator],
                    hasError: false,
                    errorText: "Укажите ФИО",
                    dot_orange: true,
                    disabled: false
                },
                phone: {
                    typeInput: "text",
                    label: "телефон",
                    name: "phone",
                    value: "",
                    required: true,
                    mask: phoneMask,
                    validators: [phoneValidator],
                    hasError: false,
                    errorText: "Укажите корректный номер телефона",
                    dot_orange: false,
                    placeholder: "+7",
                    disabled: false
                },
                email: {
                    typeInput: "text",
                    label: "e–mail",
                    value: "",
                    name: "email",
                    required: true,
                    validators: [emailValidator],
                    hasError: false,
                    errorText: "Укажите корректный email",
                    dot_orange: true,
                    placeholder: "info@mail.ru",
                    disabled: false
                },
                textarea: {
                    typeInput: "textarea",
                    label: "ваше обращение",
                    name: "textarea",
                    value: "",
                    options: [],
                    placeholder: "",
                    required: true,
                    dot_orange: true,
                    hasError: false,
                    errorText: "Заполните текст обращения",
                    validators: [emptyTextarea],
                    disabled: false
                },
            },
        };
    }

    static contextTypes = {
        fields: PropTypes.object
    };

    componentWillMount() {

        let isGuest = this.props.isGuest;
        let clientPhoneValue = this.props.clientInfo && this.props.clientInfo.username ? this.props.clientInfo.username : '';
        let clientFioValue = this.props.clientInfo && this.props.clientInfo.name ? this.props.clientInfo.name : '';
        this.loadForm();
        if ( !isGuest && !!clientPhoneValue ) {
            this.setFieldValue( isGuest, clientPhoneValue, clientFioValue );
        }
    };


    componentDidUpdate(prevProps, prevState){

        let isGuest = this.props.isGuest;
        let clientPhoneValue = this.props.clientInfo && this.props.clientInfo.username ? this.props.clientInfo.username : '';
        let clientFioValue = this.props.clientInfo && this.props.clientInfo.name ? this.props.clientInfo.name : '';
        let phoneValue = this.state.fields.phone.value;
        let inProgress = this.props.forms && this.props.forms.inProgress;
        let isSubmitOk = this.props.forms && this.props.forms.isSubmitOk;
        let submitError = this.props.forms && this.props.forms.submitError;

        if (this.state.siteKey && !prevState.siteKey) {
            this.createReCaptcha(this.state.siteKey);
        } else {
            console.warn('ReCaptcha siteKey is missing.');
        }

        if ( !isGuest && !!phoneValue ) {
            let clientPhone = clientPhoneValue ? phoneMask(clientPhoneValue) : null;
            if (clientPhone !== phoneValue) {
                this.clearPhoneValue();
            }
        }

        if ( !isGuest && !!clientPhoneValue && !phoneValue || this.state.modal === "success" && this.state.fields.phone.value === '' && this.state.fields.fio.value === '' ) {
            this.setFieldValue( isGuest, clientPhoneValue, clientFioValue );
        }

        if ( isGuest !== prevProps.isGuest && isGuest && !!phoneValue ) {
            this.clearPhoneValue();
        }

        if ( inProgress !== prevProps.inProgress && inProgress && isSubmitOk ) {
            this.setState({modal: "success"});
            this.props.clearData();
        }

        if ( submitError !== prevProps.submitError && !!submitError ) {
            this.setState({modal: 'error'});
        }

        let modal = this.state.modal;
        if ( typeof document !== 'undefined' ) {
            if ( modal === 'success' ) {
                this.hideScroll();
            }
        }

        if (modal !== prevState.modal && modal === 'success' || modal !== prevState.modal && modal === 'error') {
            this.props.hideLoading();
        }


    };

    componentWillReceiveProps(nextProps){

        if (this.props.forms.errorValidation !== nextProps.forms.errorValidation && nextProps.forms.errorValidation || this.props.forms.formError !== nextProps.forms.formError && nextProps.forms.formError) {
            this.setState({modal: 'error'});
            this.props.clearData();
        }


        if(!this.props.data.isLoaded && nextProps.data.isLoaded){
            this.setState({siteKey: nextProps.data.recaptchaSiteKey})
        }

    }

    createReCaptcha = (siteKey) => {
        if (!window.grecaptcha) {
            const script = document.createElement("script");
            script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
            script.async = true;
            script.defer = true;
            script.onload = () => this.executeRecaptcha();
            document.body.appendChild(script);
        }
    }

    executeRecaptcha = () => {

        if (window.grecaptcha && window.grecaptcha.execute) {
            const siteKey = this.state.siteKey;
            let self = this;

            window.grecaptcha.ready(function() {
                window.grecaptcha.execute(siteKey, {action: 'submit'}).then(function(token) {
                    self.verifyCallback(token);
                }).catch(err=>console.log('Captcha error', err));
            });



            // window.grecaptcha.execute(recaptchaClient).then(token => {
            //     this.setState({ captchaToken: token });
            // }).catch(error => {
            //     console.error('ReCaptcha execution failed 22:', error);
            // });
        } else {
            console.error('ReCaptcha not ready, retrying...');
            setTimeout(this.executeRecaptcha, 1000); // Повтор через 1 секунду
        }
    };

    loadForm = () => {
        if (typeof location != "undefined") {
            this.props.loadForm(location.origin + location.pathname, this.context.isB2b ? 'b2b' : '');
        }
    };

    hideScroll = () =>{
        let scrollTop = 0;
        if (typeof document != "undefined") {
            document.body.classList.add("no-scroll");
            scrollTop = window.pageYOffset;
            document.body.style.position = "fixed";
            document.body.style.top = -scrollTop + 'px';
        }
    };

    showScroll = () => {
        if (typeof document != "undefined") {
            document.body.classList.remove('no-scroll');
            document.body.style.position = '';
            document.body.style.top = '';
            window.scroll(0, this.scrollTop);
        }
    };

    openModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();
            this.setState({
                modal: modal
            });
        }
    };

    closeModal = (e) => {
        if (e) e.preventDefault();

        this.setState({modal: null});
        this.showScroll();
        if (!this.props.forms.errorValidation && this.props.forms.inProgress) {
            this.clearFields();
        } else {
            this.unlockFields();
        }

        try {

            // window.grecaptcha.execute();
            this.executeRecaptcha();
        } catch (error) {
            console.error('ReCaptcha execution failed:', error);
            this.setState({ captchaToken: '' });
        }
    };

    renderModal = () => {
        let modal = this.state.modal;
        let formTitleSuccess = this.props.items && this.props.items.settings.appeal_form_success;
        let formTitleError = this.props.items && this.props.items.settings.appeal_form_failed;

        switch ( modal ) {
            case "success":
                return <SuccessModal title={ formTitleSuccess }
                                     text={ '' }
                                     btnText="Ок"
                                     close={this.closeModal} />;
            case "error":
                return <SuccessModal title={ formTitleError }
                                     text={ '' }
                                     icon={false}
                                     btnText="ОК"
                                     close={this.closeModal}
                />;
            default:
                return "";
        }
    };

    clearFields = () => {
        this.setState({
            fields: {
                ...this.state.fields,
                fio: {
                    ...this.state.fields.fio,
                    value: '',
                    disabled: false,
                },
                email: {
                    ...this.state.fields.email,
                    value: '',
                    disabled: false,
                },
                phone: {
                    ...this.state.fields.phone,
                    value: '',
                    disabled: false,
                },
                textarea: {
                    ...this.state.fields.textarea,
                    value: '',
                    disabled: false,
                },
            },
            agreement: false,
            blockSubmitBtn: false
        });
    };

    blockFields = () => {
        this.setState({
            fields: {
                ...this.state.fields,
                fio: {
                    ...this.state.fields.fio,
                    disabled: true,
                },
                email: {
                    ...this.state.fields.email,
                    disabled: true,
                },
                phone: {
                    ...this.state.fields.phone,
                    disabled: true,
                },
                textarea: {
                    ...this.state.fields.textarea,
                    disabled: true,
                },
            },
            blockSubmitBtn: true
        });
    };

    unlockFields = () => {
        let isGuest = this.props.isGuest;
        if (!isGuest) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    fio: {
                        ...this.state.fields.fio,
                        disabled: false,
                    },
                    email: {
                        ...this.state.fields.email,
                        disabled: false,
                    },
                    textarea: {
                        ...this.state.fields.textarea,
                        disabled: false,
                    },
                },
                blockSubmitBtn: false
            });
        } else {
            this.setState({
                fields: {
                    ...this.state.fields,
                    fio: {
                        ...this.state.fields.fio,
                        disabled: false,
                    },
                    email: {
                        ...this.state.fields.email,
                        disabled: false,
                    },
                    phone: {
                        ...this.state.fields.phone,
                        disabled: false,
                    },
                    textarea: {
                        ...this.state.fields.textarea,
                        disabled: false,
                    },
                },
                blockSubmitBtn: false
            });
        }
    };

    setFieldValue = ( isGuest, clientPhoneValue = '', clientFioValue = '' ) => {
        let phoneValue = clientPhoneValue ? phoneMask(clientPhoneValue) : null;
        if ( !isGuest && !!clientPhoneValue ) {
            this.setState({
                fields: {
                    ...this.state.fields,
                    phone: {
                        ...this.state.fields.phone,
                        value: phoneValue,
                        disabled: true
                    },
                    fio: {
                        ...this.state.fields.fio,
                        value: clientFioValue
                    }
                }
            });
        }
    };

    clearPhoneValue = () => {
        this.setState({
            fields: {
                ...this.state.fields,
                phone: {
                    ...this.state.fields.phone,
                    value: '',
                    disabled: false
                },
                fio: {
                    ...this.state.fields.fio,
                    value: ''
                }
            }
        });
    };

    emptyFields = (name) => {
        let fields = {...this.state.fields};
        fields[name].hasError = true;
        this.setState({fields});
    };

    verifyCallback = (recaptchaToken) => {

        try {
            this.setState({
                captchaToken: recaptchaToken
            });
        } catch (error) {
            console.error('ReCaptcha verify callback failed:', error);
            this.setState({ captchaToken: '' }); // Сброс токена при ошибке
        }
    };

    onSubmit = (e) => {
        e.preventDefault();

        if (!this.state.captchaToken) {
            this.setState({ modal: 'error' });
            return;
        }

        if (!this.state.siteKey) {

            this.setState({ modal: 'error' }); // Покажите сообщение об ошибке
            return;
        }

        let fields = Object.assign({}, this.state.fields);
        let captchaToken = this.state.captchaToken;
        let agreementForm = !!this.state.agreement ? 1 : 0;

        if (!fields.fio.value) {
            this.emptyFields("fio");
        }
        if (!fields.email.value) {
            this.emptyFields("email");
        }
        if (!fields.textarea.value) {
            this.emptyFields("textarea");
        }

        let data = new FormData();
        let phoneValue = '';
        if ( !!fields.fio.value && !!fields.email.value && !!fields.textarea.value && agreementForm ) {
            phoneValue = fields.phone.value.replace(/\D/gim, '').slice(1);
        }

        data.append('form_name', fields.form_name.value);
        data.append('fio', fields.fio.value);
        data.append('email', fields.email.value);
        data.append('captcha', captchaToken);
        data.append('abnum', phoneValue);
        data.append('comment', fields.textarea.value);
        data.append('agreement', agreementForm);
        if ( !!fields.fio.value && !!fields.email.value && !!fields.textarea.value && agreementForm ) {
            this.props.submitForm(data, 'appeal_form');
            this.blockFields();
            this.props.showLoading();
        }
    };

    onBlur = (name) => {
        return (e) => {
            let field = this.state.fields[name];
            if (field.hasOwnProperty('validators')) {
                let valid = true;
                field.validators.forEach(function (func) {
                    if (!func.call(this, field.value)) {
                        valid = false;
                    }
                });
                this.setState({
                    fields: {
                        ...this.state.fields,
                        [name]: {
                            ...this.state.fields[name],
                            hasError: !valid                            
                        }
                    }
                });
            }
        };
    };

    onFocus = (name) => {
        return (e) => {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: {
                        ...this.state.fields[name],
                        hasError: null
                    }
                }
            })
        }
    };

    onFieldChange = (name, value) => {
        let fields = {...this.state.fields};
        fields[name].value = fields[name].mask ? fields[name].mask(value) : value;
        if (!value) {
            fields[name].hasError = true;
        }
        this.setState({fields});

        if ( !!fields["fio"].value && !!fields["phone"].value && !!fields["textarea"].value ){
            this.setState({
                formValid: true
            });
        } else {
            this.setState({
                formValid: false
            });
        }

        if ( name === 'textarea') {
            if ( value.length <= 2000 ) {
                let charactersLeft = 2000 - value.length;
                this.setState({
                    limitTextValue: charactersLeft
                })
            }
            if ( value === '' ) {
                this.setState({
                    limitTextValue: 2000
                })
            }
            if ( value ) {
                this.setState({
                    fields: {
                        ...this.state.fields,
                        textarea: {
                            ...this.state.fields.textarea,
                            hasError: false,
                        },
                    }
                });
            }
        }
    };

    toggleAgreement = () => {
        this.setState((prevState) => {
            return {
                agreement: !prevState.agreement
            }
        })
    };

    isNotMax = (e) => {
        e = e || window.event;
        let target = e.target || e.srcElement;
        let code = e.keyCode ? e.keyCode:( e.which ? e.which: e.charCode );

        switch (code){
            case 13:
            case 8:
            case 9:
            case 46:
            case 37:
            case 38:
            case 39:
            case 40:
                return true;
        }

        return target.value.length <= target.getAttribute('maxlength');
    };

    render(){

        let {
            fio,
            phone,
            email,
            textarea,
        } = this.state.fields;

        let {
            limitTextValue,
            agreement,
            siteKey,
            blockSubmitBtn
        } = this.state;

        let { isGuest } =this.props;
        let agreementText = this.props.items ? this.props.items.settings.appeal_form_agreement : null;
        let title = this.props.items ? this.props.items.settings.appeal_form_title : null;

        return  <div className={"complain-form"}>
            <span className="complain-form-title">{ title }</span>
            <form onSubmit={ this.onSubmit } className="complain-form-content">
                <div className="form-row complain-form-row complain-form-label-column">
                    <Input { ...fio }
                           onChange={this.onFieldChange}
                           name={fio.name}
                           onFocus={this.onFocus(fio.name)}
                           onBlur={this.onBlur(fio.name)}
                    />
                </div>
                <div className="complain-form-row">
                    <div className="complain-form-row--item complain-form-label-column">
                        <Input { ...email }
                               onChange={this.onFieldChange}
                               name={email.name}
                               onFocus={this.onFocus(email.name)}
                               onBlur={this.onBlur(email.name)}
                        />
                    </div>
                    <div className="complain-form-row--item complain-form-label-column">
                        <Input { ...phone }
                               onChange={this.onFieldChange}
                               name={phone.name}
                               onFocus={this.onFocus(phone.name)}
                               onBlur={this.onBlur(phone.name)}
                        />
                    </div>
                </div>
                <div className="form-row complain-form-row">
                    <div className="complain-form-textarea">
                        <label className="form-group-label">{ textarea.label }
                            {textarea.dot_orange ? <span className="orange">*</span> : null}
                        </label>
                        <textarea className={"complain-form-textarea--item"+(textarea.hasError ? " complain-form-textarea--error" : "")}
                                  maxLength={2000}
                                  value={ textarea.value }
                                  placeholder={ textarea.placeholder }
                                  onChange={(e) => { this.onFieldChange(textarea.name, e.target.value)}}
                                  onKeyPress={(e) => {this.isNotMax(e)}}
                                  onBlur={this.onBlur(textarea.name)}
                                  onFocus={this.onFocus(textarea.name)}
                                  disabled={ textarea.disabled }
                        />
                        {textarea.hasError ? <div className="form-textarea-error">{textarea.errorText}</div> : null}
                        <div className={"complain-form-textarea--limit"}>осталось <span>{limitTextValue}</span> символов</div>
                    </div>
                </div>

                <div className="b-step-form__block b-step-form__block_checkbox-agree">
                    <div className="b-step-form__checkbox-item">
                        <div className="b-input-checkbox b-input-checkbox_adapt_mobile-small">
                            <label className="b-input-checkbox__wrap" htmlFor="checkbox_agree">
                                <input className="b-input-checkbox__input"
                                       type="checkbox"
                                       id="checkbox_agree"
                                       onChange={this.toggleAgreement}
                                       checked={agreement ? "checked" : false}
                                />
                                <span className="b-input-checkbox__icon"/>
                                { agreementText ?
                                    <span className="b-input-checkbox__text" dangerouslySetInnerHTML={{ __html: agreementText }} />
                                : null }
                            </label>                
                        </div>
                        <div className="form-vacancy-description description--dot description-dot-pc">
                            <span className="orange">*</span>
                            <span className="form-complain-description-txt">– поля обязательные для заполнения</span>
                        </div>
                    </div>
                </div>

                <div className="complain-form-btn-block">
                    {
                        isGuest ?
                            <span className={"complain-form-captcha-text"}>
                                Этот сайт защищен reCAPTCHA и применяются <a href="https://policies.google.com/privacy" target="_blank" >Политика конфиденциальности</a> и <a href="https://policies.google.com/terms" target="_blank" >Условия обслуживания</a> Google
                            </span>
                        : null
                    }


                    <div className="form-vacancy-description description--dot description-dot-mobile">
                        <span className="orange">*</span>
                        <span className="form-complain-description-txt">– поля обязательные для заполнения</span>
                    </div>
                    <button
                        onClick={this.onSubmit}
                        type="submit"
                        className={"form-vacancy-btn u-btn u-btn_default u-btn_adapt_mobile-sm u-btn_responsive u-btn_adapt_default "+(agreement && !blockSubmitBtn ? "" : " disabled")}>
                        Отправить
                    </button>
                </div>
            </form>
            {this.renderModal()}
        </div>
    }
}

export default connect(
    state => ({
        forms: state.forms,
        data: state.callbackForm,
    }),
    (dispatch) => {
        return {
            loadForm: (url, businessLine) => dispatch(checkCallbackForm(url, businessLine)),
            submitForm: (formData) => dispatch(complainForm(formData)),
            clearData: () => dispatch(clearComplainFormData()),
            showLoading: () => dispatch(showLoading()),
            hideLoading: () => dispatch(hideLoading())
        }
    }
)(ComplainForm);