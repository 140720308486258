import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {infoBlocksList} from "../../actions/infoBlocks";
import {
    services,
    exchangeMinsSend,
    exchangeMins,
    serviceCashback,
    auth,
    recovery,
    toggleServices, clearState, callLogin
} from "../../actions/client";
import {Helmet} from "react-helmet";
import {servicesList} from "../../actions/services";
import {categoriesFromList, sortByProp}  from '../../tools/tools';
import InfoBlock from '../../components/common/InfoBlock';
import RatesServiceCatList from "../../components/Rates/RatesServiceCatList";
import RatesServicesFilter from "../../components/Rates/RatesServicesFilter";
import {Link, Redirect, Route} from "react-router-dom";
import Page404 from "../Page404";
import TabsSwitcher from "../../components/common/TabsSwitcher";
import RateServiceListItem from "../../components/Rates/RateServiceListItem";
import LKModals from "../LK/LKModals"
import ServicesServices from "../../components/Services/ServicesServices";
import ServicesCashback from "../../components/Services/ServicesCashback";
import {faqList} from "../../actions/questions";
import AuthorizationModal from "../../components/modals/AuthorizationModal";
import RecoveryModal from "../../components/modals/RecoveryModal";
import SuccessModal from "../../components/modals/SuccessModal";
import {mainUrl} from "../../tools/config";
import ChangeRateOk from "../../components/modals/ChangeRateOk";
import {Modal} from "../../components/common/Modal";
import ChangeRateConfirmation from "../../components/modals/ChangeRateConfirmation";
import {settingsListIndex} from "../../actions/settings";

const categoriesBase = [{
    id: 0,
    slug: "",
    title: 'Все',
    in_filter: true,
    rank_filter: 1000000,
    is_active: true,
    meta_title: "Услуги мобильной связи | Оператор сотовой связи МОТИВ",
    meta_desc: "",
    meta_keywords: ""
}];

class RatesServices extends Component {

    constructor(props) {
        super(props);

        let category = categoriesBase[0];
        let slug = props.match.params.slug;

        if (slug) {
            let categories = categoriesFromList(props.services.servicesLists, 'mainCategory');
            category = categories.find(x => x.slug === slug);
        }

        let url = this.props.history.location.pathname.split('/');

        let activeTab = 0;
        if (!this.props.client.isGuest) {
            if (url[url.length - 1] === 'all' || url[url.length - 2] === 'category') {
                activeTab = 1;
            }
            if (url[url.length - 1] === 'services' && url[url.length - 2] === 'services') {
                activeTab = 2;
            }
            if (url[url.length - 1] === 'cashback' && url[url.length - 2] === 'services') {
                activeTab = 3;
            }
        } else {
            if (url[url.length - 1] === 'cashback' && url[url.length - 2] === 'services') {
                activeTab = 1;
            }
        }

        this.state = {
            modalData: null,
            headTitle : category ? category.meta_title : "",
            metaDesc: category ? category.meta_desc : "",
            metaKeywords: category ? category.meta_keywords : "",
            categoriesFilter : [],
            slug: slug ? slug : "",
            servicesByCats: [],
            activeTab,
            modal: null,
            isEnableCashBack: undefined
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.client.exchangeMinsSuccess) {
            return {...state, modal: "exchange-success"}
        }
        if (props.client.exchangeMinsError) {
            return {...state, modal: "exchange-error"}
        }
    }

    onToggleTab = (val) => {
        return (e) => {
            e.preventDefault();
            let toggleVal = '';
            if(this.props.client.isGuest) {
                switch (val) {
                    case 1: toggleVal = '/cashback'; break;
                    default: toggleVal = ''; break;
                }
            } else {
                switch (val) {
                    case 1: toggleVal = '/all'; break;
                    case 2: toggleVal = '/services'; break;
                    case 3: toggleVal = '/cashback'; break;
                    default: toggleVal = ''; break;
                }
            }
            this.props.history.replace(`/${this.context.selectedRegion.alias}/services${toggleVal}`);
            this.setState({activeTab: val});
        }
    };

    // для ssr
    componentWillMount(){
        if (this.props.services.servicesLists.length === 0) {
            this.props.getServices(this.context.selectedRegion.id, 'rank_filter');
        }
        if (this.props.infoBlocks.list.length === 0) {
            this.props.getInfoBlocks(this.context.selectedRegion.id, 'services');
        }
        if (this.props.client.clientInfo.tariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'services', this.props.client.clientInfo.tariff.id);
        }
        if (!this.props.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }
        if (!this.props.client.isGuest && !this.props.exchange) {
            this.props.getExchangeMins(this.context.selectedRegion.id);
        }
    }

    componentDidMount() {
        if (this.props.services.servicesLists.length === 0) {
            this.props.getServices(this.context.selectedRegion.id, 'rank_filter');
        }

        if (!this.props.settings.settingsIndex) {
            this.props.getRoamingSettingsIndex();
        }

        this.props.getInfoBlocks(this.context.selectedRegion.id, 'services');
        if (this.props.client.clientInfo.tariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'services', this.props.client.clientInfo.tariff.id);
        }
        if (!this.props.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }
        if (!this.props.client.isGuest && !this.props.exchange) {
            this.props.getExchangeMins(this.context.selectedRegion.id);
        }


        if (!this.props.client.isGuest && (this.state.activeTab == 1)) {
            this.props.getMyServices();
        }

        this.props.getFaq(this.context.selectedRegion.id);
        if(!this.props.client.isGuest) {
            this.props.serviceCashback();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if(typeof this.state.isEnableCashBack !== 'undefined'){
            if(window.location.pathname.match('cashback') && !this.state.isEnableCashBack){
                let url = '/' + this.context.selectedRegion.alias + '/services';
                this.context.pushHistory(url, {doNotScroll: true});
            }
        }
        if (!this.props.client.isGuest && prevProps.client.isGuest && (!this.props.client.services || this.props.client.services.length === 0)) {
            this.props.getMyServices();
        }

        if (!this.props.client.isGuest && prevProps.client.isGuest && !this.props.exchange) {
            this.props.getExchangeMins(this.context.selectedRegion.id);
        }
        if (this.props.client.clientInfo.tariff && !prevProps.client.clientInfo.tariff) {
            this.props.getInfoBlocksWithTariffs(this.context.selectedRegion.id, 'services', this.props.client.clientInfo.tariff.id);
        }
        if (prevProps.client.isGuest && !this.props.client.isGuest) {
            this.props.serviceCashback();
            this.setState({modal: null});
            if(this.state.activeTab === 1) this.setState({activeTab: 3});
        }
        if (!prevProps.client.isGuest && this.props.client.isGuest) {
            if(this.state.activeTab === 3) this.setState({activeTab: 1});
        }
        if (prevProps.client.toggleServiceError !== this.props.client.toggleServiceError && this.props.client.toggleServiceError && !this.props.client.isGuest && this.state.activeTab === 3) {
            this.props.serviceCashback();
            this.props.getMyServices();
            this.setState({modal: 'change-rate-error'})
        }
        if (prevProps.client.toggleServiceMessage !== this.props.client.toggleServiceMessage && this.props.client.toggleServiceMessage && !this.props.client.isGuest && this.state.activeTab === 3) {
            this.props.serviceCashback();
            this.props.getMyServices();
            this.setState({modal: 'change-rate-ok'})
        }


        if(this.props.settings && this.props.settings.settingsIndex){
            if(prevState.isEnableCashBack!==this.state.isEnableCashBack){
                let selectedRegion = this.context.selectedRegion;
                let settingRegion = (this.props.settings.settingsIndex.serviceTabsCashback||[]).find(item=>Number(item.region_id)===selectedRegion.id);

                if(settingRegion){
                    this.setState({isEnableCashBack: settingRegion.enabled});
                }
            }

        }

        if(typeof prevState.isEnableCashBack === 'undefined' && this.props.settings && this.props.settings.settingsIndex){
            let selectedRegion = this.context.selectedRegion;
            let settingRegion = (this.props.settings.settingsIndex.serviceTabsCashback||[]).find(item=>Number(item.region_id)===selectedRegion.id);

            if(settingRegion){
                this.setState({isEnableCashBack: settingRegion.enabled});
            }
        }
    }

    static contextTypes = {
        isGuest: PropTypes.bool,
        selectedRegion:  PropTypes.object,
        fetchBefore: PropTypes.func,
        pushHistory: PropTypes.func,
    };

    static childContextTypes = {
        setModalData: PropTypes.func,
        modalData: PropTypes.object
    };

    getChildContext() {
        return {
            modalData: this.state.modalData,
            setModalData: this.setModalData
        }
    }

    setModalData = (data) => {
        // this.setState((prevState) => {return {modalData: data, activeTab: data ? 1 : prevState.activeTab}})
        this.setState({modalData: data})
    };

    toggleCat = (slug) => {

        let category = categoriesBase[0];

        if (slug) {
            let categories = categoriesFromList(this.props.services.servicesLists, 'mainCategory');
            category = categories.find(x => x.slug === slug);
        }

        this.setState(() => {
            return {
                slug: slug,
                headTitle : category ? category.meta_title : "",
                metaDesc: category ? category.meta_desc : "",
                metaKeywords: category ? category.meta_keywords : "",
            }
        });

        let url = "";

        if (slug === "") {
            url = '/' + this.context.selectedRegion.alias + '/services';
        }else {
            url = '/' + this.context.selectedRegion.alias + '/services/category/' + slug;
        }
        this.context.pushHistory(url, {doNotScroll: true});

    };

    servicesByCats (serviceCats, services) {

        let result = [];

        serviceCats.forEach (function (cat, i) {

            let obj = {'category': {}, 'items' : []};

            obj.category = cat;
            result[i] = obj;

            services.forEach(function (elem) {
                let additionalCategories = elem.additionalCategories.map(cat => cat.id) || [];
                if (cat.id === elem.mainCategory.id || additionalCategories.indexOf(cat.id) !== -1) {
                    result[i].items.push(elem);
                }
            });

            sortByProp(result[i].items, 'rank');
        });

        return result;
    }

    showModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();

            this.setState({modal})
        }
    };

    renderModal = () => {
        let {
            modal
        } = this.state;

        const formsSettings = this.props.formsSettings;
        const service = this.props.client.availableServicesAll && this.props.client.availableServicesAll.find(el => el.id === 2028);

        switch (modal) {
            case "AuthorizationModal":
                return <AuthorizationModal
                    login={this.props.login}
                    onForgetLinkClick={
                        (e) => {
                            e.preventDefault();
                            this.setState({modal: "RecoveryModal"});
                        }
                    }
                    error={this.props.client.authFormError}
                    close={() => {this.setState({modal: null})}}
                    subtitle={this.props.client.formsSettings && this.props.client.formsSettings.auth_form.auth_form_description}
                    authFormSettings={this.props.client.formsSettings ? this.props.client.formsSettings.auth_form : {}}
                    qrText={this.props.qrText}
                />
            case "RecoveryModal":
                return <RecoveryModal
                    close={() => {this.setState({modal: null})}}
                    recover={this.props.recover}
                    error={this.props.client.recoveryFormError}
                    modal={this.state.modal}
                    recoveryFormSettings={this.props.client.formsSettings ? this.props.client.formsSettings.recovery_form : {}}
                />;
            case 'change-rate-ok':
                const cashbackServiceIsOn = this.props.client.services.find(el => el.id === 2028);

                return <Modal close={() => {this.setState({modal: null})}}>
                    <ChangeRateOk
                        item={{...service, name: service.name || service.title}}
                        title={cashbackServiceIsOn ? formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_title : formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_title}
                        description={cashbackServiceIsOn ? formsSettings.service_on_form && formsSettings.service_on_form.service_on_change_description : formsSettings.service_off_form && formsSettings.service_off_form.service_off_change_description}
                        type="service"
                        onClick={() => {
                            this.setState({modal: null});
                            this.props.clear();
                        }}
                    />
                </Modal>;
                break;
            case 'change-rate-error':
                return <Modal close={() => {this.setState({modal: null})}}>
                    <ChangeRateOk
                        type="error"
                        item={{name: service.name || service.title}}
                        title={this.props.toggleServiceError === "error" ? (formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_title) : this.props.toggleServiceError}
                        description={this.props.toggleServiceError === "error" ? (formsSettings.service_on_form && formsSettings.service_on_form.service_on_unavailable_description) : ""}
                        onClick={() => {
                            this.setState({modal: null});
                            this.props.clear();
                        }}
                    />
                </Modal>;
                break;
            case 'change-rate-off':
                return <Modal close={() => {this.setState({modal: null})}}>
                    <ChangeRateConfirmation
                        type={"service"}
                        item={{
                            name: service.name || service.title,
                            isOn: true,
                            info: [{
                                items: [
                                    {
                                        title: "Дата отключения",
                                        value: (new Date()).toLocaleString('ru', {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})
                                    }
                                ]
                            }]
                        }}
                        notice={formsSettings.service_off_form && formsSettings.service_off_form.service_off_form_description}
                        onClick={() => {this.activateCashback(false)}}
                        onCancel={() => {
                            this.setState({modal: null});}
                        }
                    />
                </Modal>;
            default:
                return "";
        }
    }

    activateCashback = (toOn = true) => {
        const service = this.props.client.availableServicesAll.find(el => el.id === 2028);

        this.props.toggleService(JSON.stringify({id: 2028, region_id: this.context.selectedRegion.billing_id}), {...service, name: service.name || service.title}, toOn);
    }

    render() {
        const { regions, location, callLogin, settings  } = this.props;
        const {isEnableCashBack} = this.state;
        let selectedRegion = this.context.selectedRegion;
        let infoBlocks = this.props.infoBlocks.list;
        var activeTab = this.state.activeTab;
        var client = this.props.client;
        // if (client.isGuest && ((location.pathname !== `/${this.context.selectedRegion.alias}/services`) && location.pathname.indexOf('category') === -1)) {
        //     return <Redirect to={`/${this.context.selectedRegion.alias}/services`}/>
        // }



        let services = this.props.services.servicesLists;

        let categories = categoriesFromList(services, 'mainCategory');

        let slug = this.state.slug;

        let category = categories.find(x => x.slug === slug);

        if (slug && typeof category === 'undefined') {
            return this.props.services.isOk ? <Route component={Page404} /> : <main className="page__main-holder" role="main"/>
        }

        this.state.categoriesFilter = sortByProp(categories.concat(categoriesBase), 'rank_filter');
        this.state.servicesByCats = this.servicesByCats(sortByProp(categories, 'rank_services'), services);


        let clientTabs = [];
        if (!client.isGuest) clientTabs.push({title: "Подключенные услуги"})
        clientTabs.push({title: "Все услуги"});

        const canExchange = client.clientInfo.exchange && client.clientInfo.exchange.is_available && client.clientInfo.exchange.max_minutes && client.clientInfo.exchange.allowForTariff;
        let obmen = !client.isGuest && client.settings ? services.find(item => item.id_billing == client.settings.exchange_service_id) : null;

        let deferred = !client.isGuest && client.settings ? services.find(item => item.name === "Отложенный платеж") : null;

        if (canExchange && obmen || deferred) {
            clientTabs.push({title: "Сервисы"})
        } else if (activeTab === 2 && client.clientInfo.exchange && client.clientInfo.tariff) {
            return <Redirect to={`/${this.context.selectedRegion.alias}/services`}/>
        }
        if(isEnableCashBack) clientTabs.push({title: 'Кэшбэк'});

        let pageLink = `https://${regions.host}${location.pathname.toLowerCase()}`.replace(/(\/$)/g, "");

        //получаем нужный нам SEO {}
        let routeSeo = this.props.routes.list.find(x => x.sys_name === 'servicesRoute');
        const currentMetaTags = routeSeo && routeSeo.seoRegions.find(i => i.region_id === this.context.selectedRegion.id);

        const cashbackFaq = this.props.faq.list.filter(el => el.category.name === 'Кэшбэк');

        let cashbackPackage;
        let cashbackService2028;
        let cashbackServiceAvailable2028;
        if(!this.props.client.isGuest) {
            cashbackPackage = this.props.packageRemains && this.props.packageRemains.find(el => el.service.id === 2109) ? this.props.packageRemains.find(el => el.service.id === 2109) : false;
            cashbackService2028 = this.props.services.onIds && this.props.services.onIds.indexOf(2028) !== -1 ? true : false;
            cashbackServiceAvailable2028 = this.props.client.availableServices && this.props.client.availableServices.indexOf(2028) !== -1 ? true : false;

        }
        return (
            <main className="page__main-holder" role="main" key={client.isGuest ? "services" : "services-authorized"}>
                <Helmet
                    title={currentMetaTags ? currentMetaTags.meta_title : ""}
                    meta={[
                        {
                            "name": "description",
                            "content": currentMetaTags && currentMetaTags.meta_description ? currentMetaTags.meta_description : ""
                        },
                        {"name": "keywords", "content": currentMetaTags && currentMetaTags.meta_keywords ? currentMetaTags.meta_keywords : ""}
                    ]}>
                    <link rel="canonical" href={pageLink}/>
                </Helmet>

                <div className="page__container">
                    <h1 className="page__title">Услуги</h1>
                    <div className="page__services-list">
                        <div className="b-internet-ipad__tabs-header">
                            <TabsSwitcher items={clientTabs} onClickFunc={this.onToggleTab} activeTab={activeTab}/>
                        </div>
                        <div className="b-services-list b-services-list_adapt_default">
                            {/* подключенные услуги ДЛЯ АВТОРИЗОВАННОГО */}
                            {!client.isGuest && activeTab === 0 && client.services ? <div className="b-services-list__services-group">
                                <div className="b-services-group b-services-group_adapt_default">
                                    <div className="b-services-group__wrapper">
                                        {client.services && client.services.map((service) => <RateServiceListItem item={service} key={service.id} clearAfter={true}/>)}
                                    </div>
                                </div>
                            </div> : null}
                            {!client.isGuest && activeTab === 0 ? <div className="b-services-list__services-group">
                                <InfoBlock type={'advert_service_block'}
                                           data={infoBlocks}
                                           section={'advert_service_block'}
                                           additionalProps={{
                                               exchangeId: client.settings && client.settings.exchange_service_id,
                                               showModal: this.showModal,
                                               canExchange,
                                               myServices: client.services ? client.services.map((item) => item.id_billing) : null
                                           }}
                                />
                            </div> : null}
                            {((!client.isGuest && activeTab !== 2 && activeTab !== 3) || (client.isGuest && activeTab !== 1)) ? <InfoBlock type={'big-carousel'} data={infoBlocks.filter((item) => !client.isGuest && activeTab === 0 ? item.has_tariffs : !item.has_tariffs)} section={'service_page_big_carousel'} /> : null}

                            {(client.isGuest && activeTab === 0) || (!client.isGuest && activeTab === 1) ? <div>
                                <RatesServicesFilter
                                    cats={this.state.categoriesFilter}
                                    slug={slug}
                                    toggleCat={this.toggleCat}
                                />
                                {/* Все услуги */}
                                <RatesServiceCatList servicesByCats={this.state.servicesByCats} slug={slug} />
                                {slug === ""
                                    ?   <Link className="b-services-list__archive-link" to={"/" + selectedRegion.alias + "/services/archive"}>Архивные услуги</Link>
                                    :   ''
                                }
                            </div> : null}
                            {!client.isGuest && activeTab === 2 && ((canExchange && obmen) || deferred)
                                ?   <ServicesServices services={canExchange && obmen ? [obmen, deferred] : [deferred]} />
                                :   null
                            }
                            {
                                (this.state.isEnableCashBack && ((!client.isGuest && activeTab === 3) || (client.isGuest && activeTab === 1))) && (
                                    <ServicesCashback
                                        cashback={this.props.client.cashback}
                                        isGuest={client.isGuest}
                                        cashbackFaq={cashbackFaq}
                                        packageRemains={cashbackPackage}
                                        onLogin={() => {this.setState({modal: 'AuthorizationModal'})}}
                                        cashbackService2028={cashbackService2028}
                                        cashbackServiceAvailable2028={cashbackServiceAvailable2028}
                                        changeTariffLink={mainUrl + this.context.selectedRegion.alias + '/tariffs?utm_source=svoitariff'}
                                        activateCashback={this.activateCashback}
                                        isLoadedForAuth={!this.props.client.isGuest && this.props.services.onIds && this.props.client.availableServices}
                                        showModal={(modalName) => {this.setState({modal: modalName})}}
                                        callLogin={callLogin}
                                        toggleServiceIsLoading={this.props.client.toggleServiceIsLoading}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
                <LKModals modal={this.state.modal} close={this.showModal(null)}/>
                {this.renderModal()}
            </main>
        )
    }
}

export default connect(
    (state, ownProps) => ({
        infoBlocks: state.infoBlocks,
        services: state.services,
        client: state.client,
        exchange: state.client.clientInfo.exchange,
        regions: state.regions,
        faq: state.faq,
        packageRemains: state.client.clientInfo.packageRemains,
        qrText: state.settings.qr_code_description,
        formsSettings: state.client.formsSettings,
        successMessageTitle: state.client.successMessageTitle,
        toggleServiceMessage: state.client.toggleServiceMessage,
        toggleServiceError: state.client.toggleServiceError,
        settings: state.settings,
        routes: state.routes,
    }),
    (dispatch) => {
        return {
            getInfoBlocks : (regionId, group) => dispatch(infoBlocksList(regionId, group)),
            getInfoBlocksWithTariffs : (regionId, group, tariff) => dispatch(infoBlocksList(regionId, group, tariff)),
            getServices : (regionId, categoryRankField) => dispatch(servicesList(regionId, categoryRankField)),
            getMyServices: () => {dispatch(services())},
            getExchangeMins: (regionId) => dispatch(exchangeMins(regionId)),
            exchangeMinsSend: (data) => dispatch(exchangeMinsSend(data)),
            serviceCashback: () => dispatch(serviceCashback()),
            getFaq : (regionId, categoryId, categoryRankField) => dispatch(faqList(regionId, categoryId, categoryRankField)),
            login: (data, rememberMe) => dispatch(auth(data, rememberMe)),
            recover: (data) => dispatch(recovery(data)),
            toggleService: (data, service, on) => dispatch(toggleServices(data, service, on)),
            clear: () => dispatch(clearState()),
            callLogin: () => dispatch(callLogin()),
            getRoamingSettingsIndex: () => dispatch(settingsListIndex("serviceTabsCashback")),
        }
    }
)(RatesServices);

/**
 * func - method must be called on page preload
 * args - arguments for 'func' method
 * args.id - id/slug etc. - unique object's field
 * args.regionId - must be true, if it need
 * object - path to check object on page preload
 * checkField - unique object field for check, must be null for arrays
 * main - bool - main object on current page. fetchBefore (url, id) - id/slug etc for this object
 * withRegions - this flag add regionId as first argument
 * @type {*[]}
 */
export const RatesServicesRequests = [
    {
        func: 'getServices',
        args: ['rank_filter'],
        object: 'services.servicesLists',
        checkField: null,
        main: true,
        withRegion: true,
        objectReceived: false
    },
    {
        func: 'getInfoBlocks',
        args: ['services'],
        object: 'infoBlocks.list',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false,
        alias: [
            {type: "service_page_big_carousel"}
        ]
    }
];

export const RatesServicesLkRequests = [
    {
        func: 'getMyServices',
        args: [],
        object: 'client.services',
        checkField: null,
        main: false,
        withRegion: false,
        objectReceived: false
    },
    {
        func: 'getExchange',
        args: [],
        object: 'client.clientInfo.exchange',
        checkField: null,
        main: false,
        withRegion: true,
        objectReceived: false
    },
];